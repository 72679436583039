export const appKey = 'app-esa-utep-visat-sdg';

export const gaId = window?.runtimeConfig?.googleAnalyticsKey;
//@gisatcz/ptr-state @gisatcz/ptr-utils @gisatcz/ptr-core @gisatcz/ptr-router dotenv history analytics @analytics/google-analytics react-helmet use-analytics @gisatcz/ptr-components

export const levels = ['countries', 'regions'];

export const auSelectionKey = 'auSelection';

export const defaultCaseKey = 'datasetCompare';
export const dataCompareCaseKey = 'datasetCompare';

export const defaultLayertemplateKey = ['wsfEvo', 'ghsl'];

export const defaultAttributeSetKey = 'totAreaFirstYear';

export const defaultFeatureKey = '65_1032';
// export const defaultFeatureKey = '65';

export const MAX_MAPS_IN_MAP_SET = 2;

export const leftMapKey = 'leftMapKey';
export const rightMapKey = 'rightMapKey';

export const mapsComponentKey = 'Maps';
export const mapSetMode = 'mapSet';
export const mapCompareMode = 'compare';

export const bottomPanelKey = 'bottomPanel';

export const selectionColors = ['#00e8e8', '#ff00ff', '#c4ecec', '#ecc4ec'];
export const urbanExtentColors = {
	MIN: '#d53939',
	NONMIN: '#b6b6b6',
	MAX: '#d53939',
	NONMAX: '#b6b6b6',
};

export const defaultLinks = {
	visat: 'https://visat-dev.gisat.cz/',
	unhab: 'https://esautepunhabitat-dev.gisat.cz/',
	sdg: 'https://visat-sdg-dev.gisat.cz/',
	xcube: 'https://visat-dev.gisat.cz/',
};
