import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const activeDataSetKey = Select.sdg.getActiveAttributeSetKey(state);

	const activeAttributeSet = Select.sdg.attributeSets.getByKey(
		state,
		activeDataSetKey
	);

	const absAttribute = Select.sdg.attributes.getByKey(
		state,
		activeAttributeSet.data.absAttribute
	);
	const relAttribute = Select.sdg.attributes.getByKey(
		state,
		activeAttributeSet.data.relAttribute
	);
	const yearAttribute = Select.sdg.attributes.getByKey(
		state,
		activeAttributeSet.data.yearAttribute
	);

	const absAttributeValue = Number.parseFloat(
		ownProps[absAttribute.data.columnName]
	);
	const relAttributeValue = Number.parseFloat(
		ownProps[relAttribute.data.columnName]
	);
	return {
		activeCountry: Select.sdg.features.getActiveCountry(
			state,
			ownProps.featureKey
		),
		activeRegion: Select.sdg.features.getActiveRegion(
			state,
			ownProps.featureKey
		),
		activeIndicator: Select.sdg.getActiveIndicator(state),
		activeAttributeSet: activeAttributeSet,
		absAttributeValue,
		relAttributeValue,
		absAttributeUnit: absAttribute.data.unit,
		relAttributeUnit: relAttribute.data.unit,
		yearAttributeValue: ownProps[yearAttribute?.data?.columnName],
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
