import {commonSelectors} from '@gisatcz/ptr-state';

const getSubstate = state => state.sdg.attributes;
const getAllAsObject = state => state.sdg.attributes.byKey;
const getByKey = commonSelectors.getByKey(getSubstate);

export default {
	getAllAsObject,
	getByKey,
};
