import selectors from './selectors';
import ActionTypes from '../../../constants/ActionTypes';

const setLayerProperty = (mapKey, layerTemplateKey, property, value) => {
	return (dispatch, getState) => {
		const layer = selectors.getLayerByMapKeyLayerTemplateKey(
			getState(),
			mapKey,
			layerTemplateKey
		);
		if (layer) {
			dispatch(actionSetLayerProperty(mapKey, layer?.key, property, value));
		}
	};
};

// actions
function actionSetLayerProperty(mapKey, layerKey, property, value) {
	return {
		type: ActionTypes.SDG.LAYERS.SET_LAYER_PROPERTY,
		mapKey,
		layerKey,
		property,
		value,
	};
}

export default {
	setLayerProperty,
};
