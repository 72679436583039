import PropTypes from 'prop-types';
import './style.scss';
import {createElement} from 'react';

const Attribute = ({Component, metadata, value, setIsLoading, isLoading}) => {
	if (setIsLoading) {
		if (value !== undefined) {
			setIsLoading(false);
		} else {
			setIsLoading(true);
		}
	}

	return isLoading
		? null
		: createElement(Component, {
				value,
				unit: metadata?.data?.unit,
				isChange: metadata?.data?.change,
		  });
};

Attribute.propTypes = {
	Component: PropTypes.func,
	metadata: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	setIsLoading: PropTypes.func,
	isLoading: PropTypes.bool,
};

export default Attribute;
