import PropTypes from 'prop-types';
import {createElement, forwardRef} from 'react';
import helpers from '../helpers';
import './style.scss';

/**
 * @param ChartComponent {React.FunctionComponent}
 * @param chartComponentSettings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param data {Array} following nivo line chart input data format (https://nivo.rocks/line/)
 * @param metadata {Object}
 * @param metadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param [Tooltip] {React.FunctionComponent}
 * @param startDate {string} ISO 8601 date
 * @param endDate {string} ISO 8601 date
 * @param onHover {function} Callback on hover chart node.
 * @returns {JSX.Element|null}
 */
// eslint-disable-next-line react/display-name
const TimeRangeMainChart = forwardRef(
	(
		{
			ChartComponent,
			chartComponentSettings,
			data,
			metadata,
			startDate,
			endDate,
			onHover,
		},
		ref
	) => {
		const dataByTimeRange = helpers.getDataByTimeRange(
			data,
			startDate,
			endDate,
			metadata?.extendLines
		);

		const minValues =
			dataByTimeRange && metadata?.settings?.enableArea
				? dataByTimeRange.map(value => {
						value?.data?.length > 0
							? value?.data?.reduce((a, b) => {
									if (a.y < b.y) {
										return a;
									} else {
										return b;
									}
							  }).y
							: null;
				  })
				: null;

		return (
			<div className="ptr-TimeRangeMainChart" ref={ref}>
				{createElement(ChartComponent, {
					data: dataByTimeRange,
					metadata: metadata,
					onHover,
					customSettings: {
						...(chartComponentSettings ? {...chartComponentSettings} : {}),
						axisBottom: {
							format: helpers.getDateFormatBasedOnTimeRangeForAxisX(
								startDate,
								endDate
							),
							tickValues: 6,
						},
						areaBaselineValue:
							!metadata.settings.areaBaselineValue &&
							metadata.settings.areaBaselineValue !== 0 &&
							metadata.settings.enableArea &&
							minValues.length > 0
								? Math.min(...minValues)
								: undefined,
						xScale: {
							...metadata?.settings?.xScale,
							min: startDate,
							max: endDate,
						},
					},
				})}
			</div>
		);
	}
);

TimeRangeMainChart.propTypes = {
	ChartComponent: PropTypes.func,
	chartComponentSettings: PropTypes.object,
	data: PropTypes.array,
	metadata: PropTypes.object,
	Tooltip: PropTypes.func,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onHover: PropTypes.func,
};

export default TimeRangeMainChart;
