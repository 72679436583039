import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import {appKey, gaId} from '../constants/app';

/* Initialize analytics & load plugins */
const analytics = Analytics({
	app: appKey,
	plugins: [
		googleAnalytics({
			//disable tracking while development
			measurementIds: [gaId],
			customDimensions: {
				// userGroups: 'dimension1',
			},
		}),
	],
});

/* Track a page view */
analytics.page();

export default analytics;
