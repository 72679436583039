import PropTypes from 'prop-types';
import OpacitySlider from '../../../atoms/OpacitySlider';
import LayerLabelTool from '../../../atoms/LayerLabelTool';
import './style.scss';

const LayerOpacityControl = ({opacity, onChange}) => {
	return (
		<LayerLabelTool title="Opacity" key="opacity">
			<OpacitySlider
				value={opacity >= 0 ? opacity * 100 : 100}
				onChange={onChange}
			/>
		</LayerLabelTool>
	);
};

LayerOpacityControl.propTypes = {
	opacity: PropTypes.number,
	onChange: PropTypes.func,
};

export default LayerOpacityControl;
