// import ActionTypes from '../../../constants/ActionTypes';

const INITIAL_STATE = {
	byKey: {
		lcTotal_abs: {
			key: 'lcTotal_abs',
			data: {
				unit: 'sqkm',
				columnName: 'LCTOT',
			},
		},
		lcTotal_rel: {
			key: 'lcTotal_rel',
			data: {
				unit: '%',
				change: true,
				columnName: 'LCPTOT',
			},
		},
		lcAverage_abs: {
			key: 'lcAverage_abs',
			data: {
				unit: 'sqkm',
				columnName: 'LCAVG',
			},
		},
		lcAverage_rel: {
			key: 'lcAverage_rel',
			data: {
				unit: '%',
				change: true,
				columnName: 'LCPAVG',
			},
		},
		lcYearlyIncreaseMin_abs: {
			key: 'lcYearlyIncreaseMin_abs',
			data: {
				unit: 'sqkm',
				columnName: 'LCMIN',
			},
		},
		lcYearlyIncreaseMin_rel: {
			key: 'lcYearlyIncreaseMin_rel',
			data: {
				unit: '%',
				change: true,
				columnName: 'LCPMIN',
			},
		},
		lcYearlyIncreaseMin_year: {
			key: 'lcYearlyIncreaseMin_year',
			data: {
				columnName: 'LCMINY',
			},
		},
		lcYearlyIncreaseMax_abs: {
			key: 'lcYearlyIncreaseMax_abs',
			data: {
				unit: 'sqkm',
				columnName: 'LCMAX',
			},
		},
		lcYearlyIncreaseMax_rel: {
			key: 'lcYearlyIncreaseMax_rel',
			data: {
				unit: '%',
				change: true,
				columnName: 'LCPMAX',
			},
		},
		lcYearlyIncreaseMax_year: {
			key: 'lcYearlyIncreaseMin_year',
			data: {
				columnName: 'LCMAXY',
			},
		},
		totAreaFirstYear_abs: {
			key: 'totAreaFirstYear_abs',
			data: {
				unit: 'sqkm',
				columnName: 'MIN',
			},
		},
		totAreaFirstYear_rel: {
			key: 'totAreaFirstYear_rel',
			data: {
				unit: '%',
				columnName: 'MINP',
			},
		},
		totAreaLastYear_abs: {
			key: 'totAreaLastYear_abs',
			data: {
				unit: 'sqkm',
				columnName: 'MAX',
			},
		},
		totAreaLastYear_rel: {
			key: 'totAreaLastYear_rel',
			data: {
				unit: '%',
				columnName: 'MAXP',
			},
		},
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default:
			return state;
	}
};
