export default [
	{
		key: 'areaCompare',
		data: {
			nameDisplay: 'Area compare',
		},
	},
	{
		key: 'datasetCompare',
		data: {
			nameDisplay: 'Dataset compare',
		},
	},
];
