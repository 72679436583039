/**
 * DEFAULT CONFIG VALUES FOR ALL APP INSTANCES
 *
 * Default values only.
 * Do not use this file for development, per-instance config, etc.
 */

export default {
	statisticsDataRepository:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaUtepVisatSdg/data/statistics',
};
