// import ActionTypes from '../../../constants/ActionTypes';
import styles from '../../../data/metadata/styles';

const INITIAL_STATE = {
	byKey: styles,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default:
			return state;
	}
};
