import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useEffect} from 'react';
import FeatureSelector from '../../FeatureSelector';
import DatasetLabel from '../labels/DatasetLabel';
import FeatureLabel from '../labels/FeatureLabel';
import ChoroplethLabel from '../labels/ChoroplethLabel';
import Indicators from '../../Indicators';
import {rightMapKey} from '../../../constants/app';

import scssVar from '../../../styles/_variables.scss';

import './style.scss';

const MapLabels = ({children}) => {
	return <div className="visatSdg-MapLabels">{children}</div>;
};

MapLabels.propTypes = {
	children: PropTypes.node,
};

const IndicatorsContainer = ({children, isBottomPanelOpen}) => {
	return (
		<div
			className="visatSdg-IndicatorsContainer"
			style={{bottom: isBottomPanelOpen ? scssVar.bottomPanelHeight : 0}}
		>
			{children}
		</div>
	);
};

IndicatorsContainer.propTypes = {
	children: PropTypes.node,
	isBottomPanelOpen: PropTypes.bool,
};

const CustomWrapper = ({
	children,
	showAreaLabel,
	isBottomPanelOpen,
	mapKey,
	activeFeatureKey,
	activeDataset,
	onFeatureChange,
	hasLeftControls,
}) => {
	useEffect(() => {
		console.log('xxx', activeFeatureKey, activeDataset);
		onFeatureChange(activeFeatureKey, activeDataset);
	}, [activeFeatureKey, activeDataset]);

	const classes = classnames('ptr-map-wrapper visatSdg-MapWrapper', {
		'is-right': mapKey === rightMapKey,
		'has-left-controls': hasLeftControls,
	});

	return (
		<div className={classes}>
			<MapLabels>
				{showAreaLabel ? (
					<FeatureSelector Component={FeatureLabel} mapKey={mapKey} />
				) : null}
				<ChoroplethLabel featureKey={activeFeatureKey} mapKey={mapKey} />
				<DatasetLabel mapKey={mapKey} />
			</MapLabels>
			<IndicatorsContainer isBottomPanelOpen={isBottomPanelOpen}>
				<Indicators featureKey={activeFeatureKey} mapKey={mapKey} />
			</IndicatorsContainer>
			{children}
		</div>
	);
};

CustomWrapper.propTypes = {
	activeFeatureKey: PropTypes.string,
	activeDataset: PropTypes.string,
	mapKey: PropTypes.string,
	showAreaLabel: PropTypes.bool,
	isBottomPanelOpen: PropTypes.bool,
	children: PropTypes.element,
	onFeatureChange: PropTypes.func,
	hasLeftControls: PropTypes.bool,
};

export default CustomWrapper;
