import PropTypes from 'prop-types';
import Select from '../../atoms/Select';
import './style.scss';
import {Button} from '@gisatcz/ptr-atoms';
import {useState} from 'react';
import classnames from 'classnames';

const FormItem = ({label, children}) => {
	return (
		<div className="visatSdg-FormItem">
			<label>{label}</label>
			{children}
		</div>
	);
};

FormItem.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
};

const FeatureSelectForm = ({
	countries,
	activeCountry,
	activeRegion,
	onActiveFeatureChange,
	closeWindow,
}) => {
	const [selectedCountry, setSelectedCountry] = useState(
		activeCountry
			? {
					value: activeCountry?.key,
					label: activeCountry?.data?.name,
			  }
			: null
	);

	const [selectedRegion, setSelectedRegion] = useState(
		activeRegion
			? {
					value: activeRegion?.key,
					label: activeRegion?.data?.name,
			  }
			: null
	);

	// countries
	const countriesAsArray = Object.values(countries);

	const selectedCountryData = countriesAsArray?.find(
		country => country?.key === selectedCountry?.value
	);

	const countryOptions = countriesAsArray?.map(country => {
		return {value: country.key, label: country.data.name};
	});

	// regions
	const regionsAsArray =
		selectedCountryData?.data?.regionsByFeatureKey &&
		Object.values(selectedCountryData?.data?.regionsByFeatureKey);

	const regionOptions = regionsAsArray?.map(region => {
		return {value: region.key, label: region.data.name};
	});

	const setActiveFeature = () => {
		selectedCountry ? onActiveFeatureChange(selectedCountry?.value) : null;
		selectedRegion ? onActiveFeatureChange(selectedRegion?.value) : null;
		closeWindow();
	};

	return (
		<div className="visatSdg-FeatureSelectForm">
			<h2>Select area</h2>
			<div className="visatSdg-FeatureSelectForm-content">
				<FormItem label="Countries">
					<Select
						small
						options={countryOptions}
						value={selectedCountry}
						onChange={option => {
							setSelectedCountry(option);
							setSelectedRegion(null);
						}}
						menuPosition={'fixed'}
					/>
				</FormItem>
				<FormItem label="Regions">
					<Select
						small
						options={regionOptions}
						value={selectedRegion}
						onChange={option => {
							setSelectedRegion(option);
						}}
						menuPosition={'fixed'}
						isClearable
					/>
				</FormItem>
				<div className="visatSdg-FeatureSelectForm-controlButtons">
					<Button
						className={classnames('visatSdg-FeatureSelectForm-saveBtn', {
							'has-changes':
								selectedCountry?.value !== activeCountry?.key ||
								selectedRegion?.value !== activeRegion?.key,
						})}
						onClick={setActiveFeature}
						primary
					>
						{activeRegion && !selectedRegion
							? 'Save & switch to countries'
							: !activeRegion && selectedRegion
							? 'Save & switch to regions'
							: 'Save'}
					</Button>
					<Button onClick={() => closeWindow()} ghost>
						Cancel
					</Button>
				</div>
			</div>
		</div>
	);
};

FeatureSelectForm.propTypes = {
	featureKey: PropTypes.string,
	countries: PropTypes.object,
	activeCountry: PropTypes.object,
	activeRegion: PropTypes.object,
	onActiveFeatureChange: PropTypes.func,
	closeWindow: PropTypes.func,
};

export default FeatureSelectForm;
