import PropTypes from 'prop-types';
import classnames from 'classnames';
import AttributeSets from './AttributeSets';
import {IconTool, Tooltip} from '@gisatcz/ptr-atoms';
import './style.scss';
import {useEffect} from 'react';

const Indicator = ({
	name,
	description,
	sdgAttributeSets,
	dataSourceKeys,
	featureKey,
	datasetKey,
	setIndicatorIsOpen,
	open,
	active,
}) => {
	useEffect(() => {
		setIndicatorIsOpen(true);
	}, []);

	const classes = classnames('visatSdg-Indicator', {'is-active': active});

	return (
		<div className={classes}>
			<div className="visatSdg-Indicator-header">
				<div className="visatSdg-Indicator-title">
					<div className="visatSdg-Indicator-name">{name}</div>
					<div className="visatSdg-Indicator-description">{description}</div>
				</div>
				<div className="visatSdg-Indicator-tools">
					<IconTool
						icon={open ? 'ri-minimise' : 'ri-plus'}
						medium
						onClick={() => {
							setIndicatorIsOpen(!open);
						}}
						className="visatSdg-Indicator-toggle"
						tooltip={{
							text: open ? 'Close' : 'Open',
							position: 'right',
							component: Tooltip,
						}}
					/>
				</div>
			</div>
			{open ? (
				<AttributeSets
					sets={sdgAttributeSets}
					dataSourceKeys={dataSourceKeys}
					featureKey={featureKey}
					datasetKey={datasetKey}
				/>
			) : null}
		</div>
	);
};

Indicator.propTypes = {
	active: PropTypes.bool,
	featureKey: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	sdgAttributeSets: PropTypes.array,
	dataSourceKeys: PropTypes.object,
	datasetKey: PropTypes.string,
	open: PropTypes.bool,
	setIndicatorIsOpen: PropTypes.func,
};

export default Indicator;
