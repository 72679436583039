import PropTypes from 'prop-types';
import {IconTool} from '@gisatcz/ptr-atoms';
import html2canvas from 'html2canvas';
import {useRef, useState, useEffect} from 'react';
import classnames from 'classnames';
import './style.scss';

const useOnClickOutside = (ref, handler) => {
	useEffect(() => {
		const listener = event => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			handler(event);
		};
		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler]);
};

const ChartWrapper = ({
	children,
	indicatorName,
	title,
	progressChartData,
	summaryChartData,
}) => {
	const [windowIsOpen, setWindowIsOpen] = useState(false);
	const chartRef = useRef();
	const menu = useRef();

	const handleClickOutside = () => {
		if (windowIsOpen) {
			setWindowIsOpen(false);
		}
	};

	useOnClickOutside(menu, handleClickOutside);

	const data = progressChartData ? progressChartData : summaryChartData;

	const downloadChart = async () => {
		const element = chartRef.current;

		const canvas = await html2canvas(element, {
			height: element.querySelector('.ptr-TimeRangeSelector')
				? element.scrollHeight -
				  element.querySelector('.ptr-TimeRangeSelector').scrollHeight
				: null,
		});

		const data = canvas.toDataURL('image/jpg');
		const link = document.createElement('a');

		link.href = data;

		if (title) {
			if (typeof title === 'object') {
				link.download = title?.props?.children?.[0];
			} else {
				link.download = title;
			}
		} else {
			link.download = 'WorldWater chart';
		}

		link.click();
	};

	const downloadData = async () => {
		var dataLink =
			'data:text/json;charset=utf-8,' +
			encodeURIComponent(JSON.stringify(data));

		const link = document.createElement('a');
		link.href = dataLink;

		if (title) {
			link.download = title + '.json';
		} else {
			link.download = 'WorldWater chart.json';
		}

		link.click();
	};

	return (
		<div className="visatSdg-ChartWrapper" ref={chartRef}>
			<div className="visatSdg-ChartWrapper-header">
				<h3 className="visatSdg-ChartWrapper-title">
					{indicatorName}: <em>{title}</em>
				</h3>
				<div
					className="visatSdg-ChartWrapper-tools"
					data-html2canvas-ignore={true}
					ref={menu}
				>
					<IconTool
						icon="ri-download"
						onClick={() => setWindowIsOpen(!windowIsOpen)}
						small
						className={classnames('visatSdg-ChartWrapper-tools-btn', {
							'is-active': windowIsOpen,
						})}
					/>
					<div
						className={classnames('visatSdg-ChartWrapper-tools-window', {
							'is-open': windowIsOpen,
						})}
					>
						<div className="visatSdg-ChartWrapper-tools-windowConnect" />
						<div className="visatSdg-ChartWrapper-tools-windowContent">
							<span
								className="visatSdg-ChartWrapper-tools-windowContent-item"
								onClick={downloadData}
							>
								Download chart data (JSON)
							</span>
							<span
								className="visatSdg-ChartWrapper-tools-windowContent-item"
								onClick={downloadChart}
							>
								Download chart as image (PNG)
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="visatSdg-ChartWrapper-content">{children}</div>
		</div>
	);
};

ChartWrapper.propTypes = {
	children: PropTypes.node,
	indicatorName: PropTypes.string,
	title: PropTypes.string,
	progressChartData: PropTypes.array,
	summaryChartData: PropTypes.array,
};

export default ChartWrapper;
