import PropTypes from 'prop-types';
import DonutChart from '../../atoms/DonutChart';

import './style.scss';
import {urbanExtentColors} from '../../../constants/app';

const SummaryChart = ({data}) => {
	return (
		<div className="visatSdg-SummaryChart">
			<div className="visatSdg-SummaryChart-charts">
				{data?.map(feature => (
					<div key={feature.key} className="visatSdg-SummaryChart-feature">
						<h3>{feature.name}</h3>
						<div className="visatSdg-SummaryChart-feature-body">
							{feature.data.map(chart => (
								<div key={chart.name} className="visatSdg-SummaryChart-chart">
									<h4>{chart.name}</h4>
									<DonutChart
										data={chart.data}
										margin={{top: 0, right: 10, bottom: 10, left: 10}}
										withoutLegend
										units="%"
										format=" >-.1%"
										showCenteredMetric
										centeredMetricValue={`${(
											chart.data?.[0].value * 100
										).toFixed(1)} %`}
									/>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
			<div className="visatSdg-SummaryChart-legend">
				<div className="visatSdg-SummaryChart-legend-item">
					<div
						className="visatSdg-SummaryChart-legend-color"
						style={{backgroundColor: urbanExtentColors['MIN']}}
					/>
					<span>Urbanised area</span>
				</div>
				<div className="visatSdg-SummaryChart-legend-item">
					<div
						className="visatSdg-SummaryChart-legend-color"
						style={{backgroundColor: urbanExtentColors['NONMIN']}}
					/>
					<span>Non-urbanised area</span>
				</div>
			</div>
		</div>
	);
};

SummaryChart.propTypes = {
	data: PropTypes.array,
};

export default SummaryChart;
