import PropTypes from 'prop-types';

const AbsoluteAttribute = ({value, unit}) => {
	return (
		<div className="visatSdg-Attribute-absolute">
			<div>{(value && value.toFixed(1)) || '-'}</div>
			<span>{unit || ''}</span>
		</div>
	);
};

AbsoluteAttribute.propTypes = {
	value: PropTypes.number,
	unit: PropTypes.string,
};

export default AbsoluteAttribute;
