// import ActionTypes from '../../../constants/ActionTypes';

const INITIAL_STATE = {
	byKey: {
		urbAreaEvo: {
			key: 'urbAreaEvo',
			data: {
				name: 'Urbanization area evolution',
				description: 'Land consumption (LC)',
				sdgAttributeSets: [
					'lcTotal',
					'lcAverage',
					'lcYearlyIncreaseMin',
					'lcYearlyIncreaseMax',
				],
				dataSourceKeys: {
					rel: 'LCP',
					abs: 'LC',
				},
				chartComponentKeys: [],
			},
		},
		urbArea: {
			key: 'urbArea',
			data: {
				name: 'Urban area',
				description: 'Total area of the urban extent share',
				sdgAttributeSets: ['totAreaFirstYear', 'totAreaLastYear'],
				dataSourceKeys: {
					rel: 'SEP',
					abs: 'SE',
				},
				chartComponentKeys: [],
			},
		},
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default:
			return state;
	}
};
