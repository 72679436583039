import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';
import Action from '../../../state/Action';
import Presentation from './presentation';
import {mapsComponentKey} from '../../../constants/app';

const mapStateToProps = state => {
	return {
		mapMode: Select.components.get(state, mapsComponentKey, 'mode'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setMapMode: mode => {
			dispatch(Action.components.set(mapsComponentKey, 'mode', mode));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
