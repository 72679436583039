import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		attributeSet: Select.sdg.getActiveAttributeSet(state),
		indicator: Select.sdg.getActiveIndicator(state),
		style: Select.sdg.getStyleForActiveAttributeSet(state, ownProps.featureKey),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
