import PropTypes from 'prop-types';
import classnames from 'classnames';
import {DeckGlMap, PresentationMap} from '@gisatcz/ptr-maps';
import {leftMapKey, rightMapKey} from '../../constants/app';
import MapComponentsGroup from './MapComponentsGroup';
import MapWrapper from './MapWrapper';
import ZoomControls from './ZoomControls';
import Scale from './Scale';
import BackgroundLayersControl from './BackgroundLayersControl';
import MapAttribution from './MapAttribution';
import maps from '../../data/maps/maps';

const UnsyncedMaps = ({
	isBottomPanelOpen,
	backgroundLayer,
	initLeftMapView,
	initRightMapView,
	leftMapLayers,
	rightMapLayers,
	Tooltip,
	onViewChange,
	onLayerClick,
}) => {
	const mapSetKey = maps.mapSet.key;
	const leftMapToolsClasses = classnames('visatSdg-MapTools', {
		'is-open': isBottomPanelOpen,
		'is-left-map': true,
	});

	const rightMapToolsClasses = classnames('visatSdg-MapTools', {
		'is-open': isBottomPanelOpen,
	});

	return (
		<div className="visatSdg-UnsyncedMaps">
			<PresentationMap
				wrapper={MapWrapper}
				wrapperProps={{
					isBottomPanelOpen,
					hasLeftControls: true,
				}}
				mapComponent={DeckGlMap}
				mapKey={leftMapKey}
				backgroundLayer={backgroundLayer}
				layers={leftMapLayers}
				view={initLeftMapView}
				Tooltip={Tooltip}
				onViewChange={onViewChange}
				onLayerClick={onLayerClick}
			>
				<MapComponentsGroup
					className={leftMapToolsClasses}
					key={'MapComponentsGroup_1'}
				>
					<MapComponentsGroup
						className="visatSdg-MapSetControls"
						key={'MapComponentsGroup_2'}
					>
						<ZoomControls key={'ZoomControls'} />
					</MapComponentsGroup>
					<MapComponentsGroup
						className="visatSdg-MapInfoElements"
						key={'MapComponentsGroup_3'}
					>
						<MapComponentsGroup
							className="visatSdg-AttributionScaleContainer"
							key={'MapComponentsGroup_4'}
						>
							<MapAttribution mapSetKey={mapSetKey} key={'MapAttribution'} />
							<Scale key={'Scale'} />
						</MapComponentsGroup>
					</MapComponentsGroup>
				</MapComponentsGroup>
			</PresentationMap>
			<PresentationMap
				wrapper={MapWrapper}
				wrapperProps={{
					isBottomPanelOpen,
				}}
				mapComponent={DeckGlMap}
				mapKey={rightMapKey}
				backgroundLayer={backgroundLayer}
				layers={rightMapLayers}
				view={initRightMapView}
				Tooltip={Tooltip}
				onLayerClick={onLayerClick}
			>
				<MapComponentsGroup
					className={rightMapToolsClasses}
					key={'MapComponentsGroup_1'}
				>
					<MapComponentsGroup
						className="visatSdg-MapSetControls"
						key={'MapComponentsGroup_2'}
					>
						<BackgroundLayersControl
							mapSetKey={mapSetKey}
							key={'BackgroundLayersControl'}
						/>
						<ZoomControls key={'ZoomControls'} />
					</MapComponentsGroup>
					<MapComponentsGroup
						className="visatSdg-MapInfoElements"
						key={'MapComponentsGroup_3'}
					>
						<MapComponentsGroup
							className="visatSdg-AttributionScaleContainer"
							key={'MapComponentsGroup_4'}
						>
							<MapAttribution mapSetKey={mapSetKey} key={'MapAttribution'} />
							<Scale key={'Scale'} />
						</MapComponentsGroup>
					</MapComponentsGroup>
				</MapComponentsGroup>
			</PresentationMap>
		</div>
	);
};

UnsyncedMaps.propTypes = {
	isBottomPanelOpen: PropTypes.bool,
	backgroundLayer: PropTypes.object,
	initLeftMapView: PropTypes.object,
	initRightMapView: PropTypes.object,
	leftMapLayers: PropTypes.array,
	rightMapLayers: PropTypes.array,
	Tooltip: PropTypes.func,
	onViewChange: PropTypes.func,
	onLayerClick: PropTypes.func,
};

export default UnsyncedMaps;
