import PropTypes from 'prop-types';
import {createElement} from 'react';
import './style.scss';

/**
 * @param ChartComponent {React.FunctionComponent}
 * @param chartComponentSettings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param data {Array} following nivo line chart input data format (https://nivo.rocks/line/)
 * @param metadata {Object}
 * @param metadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @returns {JSX.Element|null}
 */
const TimeRangeOverviewChart = ({
	ChartComponent,
	chartComponentSettings,
	data,
	metadata,
}) => {
	return (
		<div className="ptr-TimeRangeOverviewChart">
			{createElement(ChartComponent, {
				data,
				metadata,
				customSettings: chartComponentSettings,
			})}
		</div>
	);
};

TimeRangeOverviewChart.propTypes = {
	ChartComponent: PropTypes.func,
	chartComponentSettings: PropTypes.object,
	data: PropTypes.array,
	metadata: PropTypes.object,
};

export default TimeRangeOverviewChart;
