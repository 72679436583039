import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		model: Select.sdg.attributeSets.getByKey(state, ownProps.attributeSetKey),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onAttributeSetSelect: () => {
			dispatch(Action.sdg.switchToAttributeSet(ownProps.attributeSetKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
