import PropTypes from 'prop-types';
import './style.scss';

const MapLegendStripe = ({classes, values}) => {
	if (classes) {
		return (
			<div className="visatSdg-MapLegendStripe">
				{classes.map(cls => (
					<div key={cls.fill} style={{background: cls.fill}} />
				))}
			</div>
		);
	} else if (values) {
		return (
			<div className="visatSdg-MapLegendStripe">
				{Object.values(values).map(value => (
					<div key={value.fill} style={{background: value.fill}} />
				))}
			</div>
		);
	} else {
		return null;
	}
};

MapLegendStripe.propTypes = {
	classes: PropTypes.array,
	values: PropTypes.object,
};

export default MapLegendStripe;
