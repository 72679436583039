import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../state/Action';
import Select from '../../state/Select';
import maps from '../../data/maps/maps';
import {
	rightMapKey,
	leftMapKey,
	mapsComponentKey,
	bottomPanelKey,
} from '../../constants/app';

const mapSetKey = maps.mapSet.key;

const mapStateToProps = state => {
	const leftMapStatisticLayer = Select.sdg.getLeftMapStatisticLayer(
		state,
		leftMapKey
	);
	const rightMapStatisticLayer = Select.sdg.getRightMapStatisticLayer(
		state,
		rightMapKey
	);

	const rightSelectedFeatureKey = Select.sdg.getActiveLayerFeatureKey(
		state,
		rightMapKey
	);
	const leftSelectedFeatureKey = Select.sdg.getActiveLayerFeatureKey(
		state,
		leftMapKey
	);

	const mapsMode = Select.components.get(state, mapsComponentKey, 'mode');
	const isBottomPanelOpen = Select.components.get(
		state,
		bottomPanelKey,
		'open'
	);

	const backgroundLayer = Select.maps.getBackgroundLayerStateByMapKey(
		state,
		leftMapKey
	);
	const initMapSetView = Select.maps.getMapSetView(state, mapSetKey);

	const mapInitViews = Select.sdg.getMapInitViews(state, mapSetKey);

	const initLeftMapView = mapInitViews[0];
	const initRightMapView = mapInitViews[1];

	const isDatasetCompareActive = Select.sdg.isDatasetCompareActive(state);

	const leftMapRasterLayer = Select.sdg.getRasterLayerByMapKey(
		state,
		leftMapKey
	);
	const rightMapRasterLayer = Select.sdg.getRasterLayerByMapKey(
		state,
		rightMapKey
	);
	return {
		leftMapStatisticLayer,
		rightMapStatisticLayer,
		leftSelectedFeatureKey,
		rightSelectedFeatureKey,
		leftMapRasterLayer,
		rightMapRasterLayer,
		mapsMode,
		isBottomPanelOpen,
		backgroundLayer,
		initMapSetView,
		isDatasetCompareActive,
		initLeftMapView,
		initRightMapView,
	};
};

const getFeatureKeyFromLayerClickEvent = feature => {
	if (Object.hasOwn(feature.properties, 'ADM1_CODE')) {
		return `${feature.properties.ADM0_CODE}_${feature.properties.ADM1_CODE}`;
	} else {
		return `${feature.properties.ADM0_CODE}`;
	}
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			//set map extent from features
			dispatch(Action.sdg.setMapExtentFromSelectedFeatures());
		},
		onLayerClick: (mapKey, layerKey, selectedFeatures, event, feature) => {
			const featureKey = getFeatureKeyFromLayerClickEvent(feature);

			dispatch(
				Action.selections.setActiveSelectionFeatureKeysFilterKeys(
					selectedFeatures
				)
			);

			dispatch(Action.sdg.switchToFeature(featureKey, mapKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
