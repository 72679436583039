import {createSelector} from 'reselect';
import {forIn as _forIn} from 'lodash';

const getAllAsObject = state => state.sdg.layers.byMapKey;

const getAllAsObjectByMapKey = createSelector(
	[getAllAsObject, (state, mapKey) => mapKey],
	(layersByMapKey, mapKey) => {
		return layersByMapKey?.[mapKey] || null;
	}
);

const getStatisticsLayerByMapKey = createSelector(
	[getAllAsObjectByMapKey],
	layersForMap => {
		return layersForMap?.statistics || null;
	}
);

const getLayerByMapKeyLayerTemplateKey = createSelector(
	[
		getAllAsObjectByMapKey,
		(state, mapKey, layerTemplateKey) => layerTemplateKey,
	],
	(layersByMapKey, layerTemplateKey) => {
		return getLayerByLayerTemplateKey(layersByMapKey, layerTemplateKey);
	}
);

const getLayerOpacity = createSelector(
	[getLayerByMapKeyLayerTemplateKey],
	layer => {
		return layer?.data?.opacity || 0;
	}
);

const isLayerHidden = createSelector(
	[getLayerByMapKeyLayerTemplateKey],
	layer => {
		return layer?.data?.hidden || null;
	}
);

// helpers
const getLayerByLayerTemplateKey = (layers, layerTemplateKey) => {
	let selectedLayer = null;
	if (layers && layerTemplateKey) {
		_forIn(layers, layer => {
			if (layer?.data?.layerTemplateKey === layerTemplateKey) {
				selectedLayer = layer;
			}
		});
	}
	return selectedLayer;
};

export default {
	getAllAsObjectByMapKey,
	getStatisticsLayerByMapKey,
	getLayerByMapKeyLayerTemplateKey,
	getLayerOpacity,

	isLayerHidden,

	// helpers
	getLayerByLayerTemplateKey,
};
