import {Action as CommonAction} from '@gisatcz/ptr-state';
import {map} from '@gisatcz/ptr-utils';
import routerActions from '../router/actions';
import features from './features/actions';
import indicators from './indicators/actions';
import attributes from './attributes/actions';
import attributeSets from './attributeSets/actions';
import statistics from './statistics/actions';
import styles from './styles/actions';
import layers from './layers/actions';
import maps from '../../data/maps/maps';

import sdgSelectors from './selectors';
import {mapsComponentKey} from '../../constants/app';

const mapSetKey = maps.mapSet.key;

const switchToAttributeSet = atributeSetKey => {
	return dispatch => {
		// TODO - clear state like selection .etc

		// TODO - page from current page? from selector
		const page = 'home';
		dispatch(routerActions.updateAppUrl(page, {attributeSet: atributeSetKey}));
	};
};

const switchToCase = caseKey => {
	return dispatch => {
		// TODO - clear state like selection .etc

		// TODO - set only one active attribute if switch to the area compare
		// TODO - set only one active feature if switch to the area compare

		// TODO - page from current page? from selector
		const page = 'home';
		dispatch(routerActions.updateAppUrl(page, {case: caseKey}));
	};
};

const switchToLayerTemplate = (layerTemplatesKeys, mapKey) => {
	return (dispatch, getState) => {
		const state = getState();
		const layerTemplateUrlParams = sdgSelectors.getLayerTemplateParams(state);
		const mapKeyOrderIndex = sdgSelectors.getMapKeyOrderIndex(state, mapKey);

		const dataCompareActive = sdgSelectors.isDatasetCompareActive(state);
		if (!dataCompareActive) {
			layerTemplateUrlParams[0] = layerTemplatesKeys;
		} else {
			layerTemplateUrlParams[mapKeyOrderIndex] = layerTemplatesKeys;
		}

		// TODO - page from current page? from selector
		const page = 'home';
		dispatch(
			routerActions.updateAppUrl(page, {layerTemplate: layerTemplateUrlParams})
		);
	};
};

const setMapExtentFromSelectedFeatures = () => {
	return (dispatch, getState) => {
		const state = getState();
		const activeFeatures = sdgSelectors.getActiveFeatureKeys(state);
		const dataCompareActive = sdgSelectors.isDatasetCompareActive(state);
		if (dataCompareActive) {
			const activeFeature = sdgSelectors.features.getActive(
				state,
				activeFeatures[0]
			);
			const featureExtent = activeFeature?.data?.extent;
			const view = featureExtent
				? map.view.getViewFromBoundingBox(featureExtent, true)
				: {};

			dispatch(CommonAction.maps.updateSetView(mapSetKey, view));

			//set map set view for initialization
			const mapSet = {...maps.mapSet, data: {...maps.mapSet.data, view}};
			dispatch(CommonAction.maps.addMapSet(mapSet));

			const leftMap = {
				...maps.maps[0],
				data: {...maps.maps[0].data, view},
			};

			const rightMap = {
				...maps.maps[1],
				data: {...maps.maps[1].data, view},
			};

			dispatch(CommonAction.maps.addMap(leftMap));
			dispatch(CommonAction.maps.addMap(rightMap));
		} else {
			const leftActiveFeature = sdgSelectors.features.getActive(
				state,
				activeFeatures[0]
			);
			const rightActiveFeature = sdgSelectors.features.getActive(
				state,
				activeFeatures[1]
			);

			const leftFeatureExtent = leftActiveFeature?.data?.extent;
			const leftView = leftFeatureExtent
				? map.view.getViewFromBoundingBox(leftFeatureExtent, true)
				: {};

			const rightFeatureExtent = rightActiveFeature?.data?.extent;
			const rightView = rightFeatureExtent
				? map.view.getViewFromBoundingBox(rightFeatureExtent, true)
				: {};

			const leftMap = {
				...maps.maps[0],
				data: {...maps.maps[0].data, view: leftView},
			};
			const rightMap = {
				...maps.maps[1],
				data: {...maps.maps[1].data, view: rightView},
			};

			const mapSet = {
				...maps.mapSet,
				data: {...maps.mapSet.data, view: leftView},
			};
			dispatch(CommonAction.maps.addMapSet(mapSet));

			dispatch(CommonAction.maps.addMap(leftMap));
			dispatch(CommonAction.maps.addMap(rightMap));
		}
	};
};
const switchToFeature = (featureKey, mapKey) => {
	return (dispatch, getState) => {
		const state = getState();
		const featureUrlParams = sdgSelectors.getFeatureUrlParams(state);
		const mapKeyOrderIndex = sdgSelectors.getMapKeyOrderIndex(state, mapKey);

		const dataCompareActive = sdgSelectors.isDatasetCompareActive(state);
		if (dataCompareActive) {
			featureUrlParams[0] = featureKey;
			featureUrlParams[1] = featureKey;
		} else {
			featureUrlParams[mapKeyOrderIndex] = featureKey;
		}

		// TODO - page from current page? from selector
		const page = 'home';
		dispatch(routerActions.updateAppUrl(page, {feature: featureUrlParams}));
		if (dataCompareActive) {
			setTimeout(() => {
				dispatch(setMapExtentFromSelectedFeatures());
			}, 100);
		}
	};
};

const switchToMapMode = mode => {
	return dispatch => {
		dispatch(CommonAction.components.set(mapsComponentKey, 'mode', mode));
	};
};

export default {
	switchToCase,
	switchToAttributeSet,
	switchToLayerTemplate,
	switchToFeature,
	switchToMapMode,
	setMapExtentFromSelectedFeatures,

	features,
	indicators,
	attributes,
	attributeSets,
	layers,
	styles,
	statistics,
};
