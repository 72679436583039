// import PropTypes from 'prop-types';
import {useState} from 'react';
import {IconTool, Tooltip} from '@gisatcz/ptr-atoms';
import ModalWindow from '../../atoms/ModalWindow';
import './style.scss';

const Share = () => {
	const [isWindowOpen, setWindowOpen] = useState();
	const currentUrl = window.location.href;

	const handleCopyToClipboard = url => {
		navigator.clipboard
			.writeText(url)
			.then(() => {
				console.log('URL copied:', url);
			})
			.catch(error => {
				console.error('Failed to copy URL to clipboard:', error);
			});
	};

	const handleShareLinkedIn = () => {
		const url = `https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`;
		window.open(url, '_blank');
	};
	const handleShareFacebook = () => {
		const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
			currentUrl
		)}`;
		window.open(url, '_blank');
	};
	const handleShareTwitter = () => {
		const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
			currentUrl
		)}`;
		window.open(url, '_blank');
	};

	return (
		<>
			<IconTool
				// className=""
				// disabled
				icon="ri-share"
				onClick={() => setWindowOpen(true)}
				tooltip={{
					text: 'Share',
					id: 'Share',
					position: 'bottom',
					component: Tooltip,
				}}
			/>
			<ModalWindow
				isOpen={isWindowOpen}
				onClose={() => setWindowOpen(false)}
				className="visatSdg-ShareWindow"
			>
				<h2 className="visatSdg-ShareWindow-title">Share</h2>
				<div className="visatSdg-ShareWindow-content">
					<h3>Copy URL</h3>
					<div className="visatSdg-ShareWindow-url">
						<div>
							<input type="url" id="share-url" value={currentUrl} readOnly />
							<a href="#" onClick={() => handleCopyToClipboard(currentUrl)}>
								Copy URL
							</a>
						</div>
						<p>
							Anyone with this link can see the map with the currently opened
							datasets and the viewpoint
						</p>
					</div>

					<div className="visatSdg-ShareWindow-social">
						<h3>Share via social media</h3>
						<div className="visatSdg-ShareWindow-social-icons">
							<a href="#" onClick={handleShareLinkedIn}>
								<IconTool
									icon="ri-linkedin"
									tooltip={{
										id: 'LinkedIn',
										component: Tooltip,
										position: 'bottom',
										text: 'LinkedIn',
									}}
								/>
							</a>
							<a href="#" onClick={handleShareFacebook}>
								<IconTool
									icon="ri-facebook"
									tooltip={{
										id: 'Facebook',
										component: Tooltip,
										position: 'bottom',
										text: 'Facebook',
									}}
								/>
							</a>
							<a href="#" onClick={handleShareTwitter}>
								<IconTool
									icon="ri-twitter"
									tooltip={{
										id: 'Twitter',
										component: Tooltip,
										position: 'bottom',
										text: 'Twitter',
									}}
								/>
							</a>
						</div>
					</div>
				</div>
			</ModalWindow>
		</>
	);
};

Share.propTypes = {};

export default Share;
