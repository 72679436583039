export default {
	ghsl: {
		type: 'wms',
		opacity: 0.3,
		options: {
			url: 'https://ptr.gisat.cz/mapproxy/utep3VisatGhs/service',
			params: {
				layers: 'layer_GHS_BUILT_LDSMT_GLOBE_R2018A_3857_30_V2_0',
			},
		},
	},
	wsfEvo: {
		type: 'wms',
		opacity: 0.3,
		options: {
			url: 'https://ptr.gisat.cz/mapproxy/esaUtepVisat/service',
			params: {
				layers: 'layer_esaUtepVisat_wsfevolution_v11_index',
			},
		},
	},
};
