import {commonSelectors} from '@gisatcz/ptr-state';

const getSubstate = state => state.sdg.indicators;
const getAllAsObject = state => state.sdg.indicators.byKey;
const getAll = commonSelectors.getAll(getSubstate);
const getByKey = commonSelectors.getByKey(getSubstate);

export default {
	getAllAsObject,
	getAll,
	getByKey,
};
