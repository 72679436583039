import PropTypes from 'prop-types';
import './style.scss';

const TimeRangeChartLegendItem = ({data, color}) => {
	const iconStyle = {
		background: color,
	};

	if (data?.data?.length) {
		const {id, name, legendName} = data;
		const idFixed = id.replace(`${color}_`, '');
		let label = legendName;
		if (!legendName) {
			label = name && name !== idFixed ? `${name} (${id})` : idFixed;
		}

		return (
			<div className="ptr-TimeRangeChartLegendItem" title={label}>
				<div className="ptr-TimeRangeChartLegendItem-icon" style={iconStyle} />
				<div className="ptr-TimeRangeChartLegendItem-label">{label}</div>
			</div>
		);
	} else {
		return null;
	}
};

TimeRangeChartLegendItem.propTypes = {
	color: PropTypes.string,
	data: PropTypes.object,
};

/**
 * @param data {Array} Input data following nivo line chart input data format (https://nivo.rocks/line/)
 * @param metadata {Object} Metadata of main chart
 * @returns {JSX.Element|null}
 */
const TimeRangeChartLegend = ({data, metadata}) => {
	const colors = metadata?.settings?.colors;
	if (data) {
		return (
			<div className="ptr-TimeRangeChartLegend">
				{data.map((item, index) => (
					<TimeRangeChartLegendItem
						key={index}
						data={item}
						color={item?.color || colors?.[index]}
					/>
				))}
			</div>
		);
	} else {
		return null;
	}
};

TimeRangeChartLegend.propTypes = {
	data: PropTypes.array,
	metadata: PropTypes.object,
};

export default TimeRangeChartLegend;
