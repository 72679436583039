import PropTypes from 'prop-types';
import {cloneElement, useEffect, useRef, useState} from 'react';
import NivoChartHover from './NivoChartHover';
import {HoverHandler, getTootlipPosition} from '@gisatcz/ptr-core';

const TOOLTIP_PADDING = 8;

const getHorizontalTooltipStyle = (
	wrapperRef,
	wrapperRefQuery,
	tooltipPosition
) => {
	if (!wrapperRef.current) {
		return () => {
			return () => {
				return () => {};
			};
		};
	}

	const referencePoint = tooltipPosition ? tooltipPosition : 'center';

	return () => {
		return () => {
			const windowScrollTop = window.document.documentElement.scrollTop;
			const windowScrollLeft = window.document.documentElement.scrollLeft;
			const windowHeight = window.document.documentElement.clientHeight;
			const windowWidth = window.document.documentElement.clientWidth;
			const windowBBox = [
				windowScrollTop,
				windowScrollLeft + windowWidth,
				windowScrollTop + windowHeight,
				windowScrollLeft,
			];

			// return (position,origPosX,origPosY,width,height,hoveredElemen) => {
			return (origPosX, origPosY, width, height) => {
				const lineChartElm = wrapperRef.current.querySelector(
					wrapperRefQuery ? wrapperRefQuery : 'svg>g'
				);

				const wrapperElm = lineChartElm;
				const yAxis = wrapperRef.current
					.querySelector('svg>g>g:nth-of-type(4)')
					.getBoundingClientRect();
				const wrapperLeft =
					wrapperElm?.getBoundingClientRect().left + yAxis.width;
				const wrapperTop = wrapperElm?.getBoundingClientRect().top;

				const wrapperTopDiff =
					wrapperRef.current.offsetParent.offsetParent.getBoundingClientRect()
						.top - wrapperRef.current.getBoundingClientRect().top;

				const position = getTootlipPosition(
					referencePoint,
					['right', 'left'],
					windowBBox,
					TOOLTIP_PADDING
				)(origPosX + wrapperLeft, origPosY + wrapperTop, width, height);

				return {
					top: position.top - wrapperTop - wrapperTopDiff,
					left: position.left,
				};
			};
		};
	};
};
const NivoChartHoverWrapper = ({
	children,
	getHoverContent,
	wrapperRefQuery,
	tooltipPosition,
}) => {
	const wrapperRef = useRef();
	const [getHorizontalTooltipStyleFunction, setHorizontalTooltipStyleFunction] =
		useState();

	useEffect(() => {
		setHorizontalTooltipStyleFunction(
			getHorizontalTooltipStyle(wrapperRef, wrapperRefQuery, tooltipPosition)
		);
	}, [wrapperRef]);

	return (
		<HoverHandler getStyle={getHorizontalTooltipStyleFunction}>
			<NivoChartHover getHoverContent={getHoverContent}>
				{cloneElement(children, {ref: wrapperRef})}
			</NivoChartHover>
		</HoverHandler>
	);
};

NivoChartHoverWrapper.propTypes = {
	getHoverContent: PropTypes.func,
	children: PropTypes.node,
	wrapperRefQuery: PropTypes.string,
	tooltipPosition: PropTypes.string,
};

export default NivoChartHoverWrapper;
