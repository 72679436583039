import SimpleLayersControl from './SimpleLayersControl';
import './style.scss';

const BackgroundLayersControl = props => {
	return (
		<div className={'ptr-BackgroundLayersControl'}>
			<SimpleLayersControl {...props} />
		</div>
	);
};

export default BackgroundLayersControl;
