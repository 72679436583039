import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		hidden: Select.sdg.layers.isLayerHidden(
			state,
			ownProps.mapKey,
			ownProps.layerTemplateKey
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: hidden => {
			dispatch(
				Action.sdg.layers.setLayerProperty(
					ownProps.mapKey,
					ownProps.layerTemplateKey,
					'hidden',
					hidden
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
