import maps from '../../data/maps/maps';
import {DeckGlMap, MapSet, MapSetPresentationMap} from '@gisatcz/ptr-maps';
import MapWrapper from './MapWrapper';
import {leftMapKey, rightMapKey} from '../../constants/app';
import MapComponentsGroup from './MapComponentsGroup';
import CompareMapsControl from './CompareMapsControl';
import BackgroundLayersControl from './BackgroundLayersControl';
import ZoomControls from './ZoomControls';
import MapAttribution from './MapAttribution';
import Scale from './Scale';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const SyncedMapSet = ({
	isBottomPanelOpen,
	backgroundLayer,
	mapSetView,
	leftMapLayers,
	rightMapLayers,
	Tooltip,
	onLayerClick,
}) => {
	const mapSetToolsClasses = classnames('visatSdg-MapSetTools', {
		'is-open': isBottomPanelOpen,
	});
	const mapSetKey = maps.mapSet.key;

	return (
		<MapSet
			key={mapSetKey}
			data={maps.mapSet.data}
			view={mapSetView}
			maps={maps.mapSet.maps}
			sync={maps.mapSet.sync}
			activeMapKey={maps.mapSet.activeMapKey}
			backgroundLayer={backgroundLayer}
			mapComponent={DeckGlMap}
			wrapper={MapWrapper}
			wrapperProps={{isBottomPanelOpen}}
			onLayerClick={onLayerClick}
		>
			<MapSetPresentationMap
				key={leftMapKey}
				wrapper={MapWrapper}
				mapKey={leftMapKey}
				layers={leftMapLayers}
				Tooltip={Tooltip}
			/>
			<MapSetPresentationMap
				key={rightMapKey}
				wrapper={MapWrapper}
				mapKey={rightMapKey}
				layers={rightMapLayers}
				Tooltip={Tooltip}
			/>
			<MapComponentsGroup
				className={mapSetToolsClasses}
				key={'MapComponentsGroup_1'}
			>
				<MapComponentsGroup
					className="visatSdg-MapSetControls"
					key={'MapComponentsGroup_2'}
				>
					<CompareMapsControl
						mapSetKey={mapSetKey}
						key={'CompareMapsControl'}
					/>
					<BackgroundLayersControl
						mapSetKey={mapSetKey}
						key={'BackgroundLayersControl'}
					/>
					<ZoomControls key={'ZoomControls'} />
				</MapComponentsGroup>
				<MapComponentsGroup
					className="visatSdg-MapInfoElements"
					key={'MapComponentsGroup_3'}
				>
					<MapComponentsGroup
						className="visatSdg-AttributionScaleContainer"
						key={'MapComponentsGroup_4'}
					>
						<MapAttribution mapSetKey={mapSetKey} key={'MapAttribution'} />
						<Scale key={'Scale'} />
					</MapComponentsGroup>
				</MapComponentsGroup>
			</MapComponentsGroup>
		</MapSet>
	);
};

SyncedMapSet.propTypes = {
	isBottomPanelOpen: PropTypes.bool,
	backgroundLayer: PropTypes.object,
	mapSetView: PropTypes.object,
	leftMapLayers: PropTypes.array,
	rightMapLayers: PropTypes.array,
	Tooltip: PropTypes.func,
	onLayerClick: PropTypes.func,
};

export default SyncedMapSet;
