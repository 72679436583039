import PropTypes from 'prop-types';
import {Children} from 'react';
import './style.scss';

const HeaderLink = ({url, children}) => {
	return (
		<a className="visatSdg-HeaderLink" href={url}>
			{children}
		</a>
	);
};

HeaderLink.propTypes = {
	url: PropTypes.string,
	children: PropTypes.node,
};

export const Logo = ({url}) => {
	return (
		<HeaderLink url={url}>
			<div className="visatSdg-Logo">visat</div>
		</HeaderLink>
	);
};

Logo.propTypes = {
	url: PropTypes.string,
};

const Divider = () => {
	return <span className="visatSdg-Header-divider">/</span>;
};

export const HeaderNav = ({children}) => {
	return (
		<div className="visatSdg-HeaderNav">
			{Children.map(children, (child, i) => {
				return (
					<>
						{child}
						{i < children.length - 1 ? <Divider /> : null}
					</>
				);
			})}
		</div>
	);
};

HeaderNav.propTypes = {
	children: PropTypes.node,
};

export const HeaderTools = ({children}) => {
	return <div className="visatSdg-HeaderTools">{children}</div>;
};

HeaderTools.propTypes = {
	children: PropTypes.node,
};

const Header = ({children}) => {
	return <div className="visatSdg-Header">{children}</div>;
};

Header.propTypes = {
	children: PropTypes.node,
};

export default Header;
