import PropTypes from 'prop-types';
import Label from '../Label';
import './style.scss';

const FeatureLabel = ({activeCountry, activeRegion, onMenuOpen, mapKey}) => {
	const countryName = activeCountry?.data?.name || activeCountry;
	const regionName = activeRegion?.data?.name || activeRegion;
	const title = regionName || countryName;
	const subtitle = regionName ? countryName : null;

	return (
		<Label
			className="visatSdg-FeatureLabel"
			onClick={onMenuOpen}
			icon="map-pin"
			title={title}
			subtitle={subtitle}
			rightIcon="ri-chevron-down"
			selectionMark={true}
			mapKey={mapKey}
		/>
	);
};

FeatureLabel.propTypes = {
	featureKey: PropTypes.string,
	activeCountry: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	activeRegion: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onMenuOpen: PropTypes.func,
	mapKey: PropTypes.string,
};

export default FeatureLabel;
