import MapComponent from '../../Maps';
import AppHeader from '../../AppHeader';
import BottomPanel from '../../BottomPanel';

import './style.scss';

const HomePage = () => {
	return (
		<div className="visatSdg-HomePage">
			<AppHeader />
			<MapComponent />
			<BottomPanel />
		</div>
	);
};

export default HomePage;
