const orangeRange = ['#fee5d9', '#fcae91', '#fb6a4a', '#de2d26', '#a50f15'];
const baseOutlinesStyle = {
	outlineColor: '#868684',
	// outlineWidth: 4,
	// outlineOpacity: 0.3,
};

export default {
	MINP_countries: {
		key: 'MINP_countries',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'MINP',
				attributeClasses: [
					{
						interval: [0.05, 2],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [2, 4],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [4, 6],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [6, 8],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [8, 30],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	MINP_regions: {
		key: 'MINP_regions',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'MINP',
				attributeClasses: [
					{
						interval: [0.05, 2],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [2, 4],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [4, 6],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [6, 8],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [8, 100],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	MAXP_countries: {
		key: 'MAXP_countries',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'MAXP',
				attributeClasses: [
					{
						interval: [0.1, 2],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [2, 4],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [4, 6],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [6, 8],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [8, 35],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	MAXP_regions: {
		key: 'MAXP_regions',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'MAXP',
				attributeClasses: [
					{
						interval: [0.05, 2],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [2, 4],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [4, 6],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [6, 8],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [8, 100],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	LCPTOT_countries: {
		key: 'LCPTOT_countries',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'LCPTOT',
				attributeClasses: [
					{
						interval: [0, 40],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [40, 60],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [60, 80],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [80, 100],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [100, 250],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	LCPTOT_regions: {
		key: 'LCPTOT_regions',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'LCPTOT',
				attributeClasses: [
					{
						interval: [0, 40],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [40, 70],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [70, 100],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [100, 200],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [200, 2000],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	LCPMAX_countries: {
		key: 'LCPMAX_countries',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'LCPMAX',
				attributeClasses: [
					{
						interval: [0, 2],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [2, 2.5],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [2.5, 3],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [3, 3.5],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [3.5, 30],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	LCPMAX_regions: {
		key: 'LCPMAX_regions',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'LCPMAX',
				attributeClasses: [
					{
						interval: [0, 2],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [2, 3],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [3, 4],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [4, 5],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [5, 30],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	LCPMIN_countries: {
		key: 'LCPMIN_countries',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'LCPMIN',
				attributeClasses: [
					{
						interval: [0, 0.6],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [0.6, 0.9],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [0.9, 1.2],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [1.2, 1.5],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [1.5, 3],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	LCPMIN_regions: {
		key: 'LCPMIN_regions',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'LCPMIN',
				attributeClasses: [
					{
						interval: [0, 0.6],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [0.6, 0.9],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [0.9, 1.2],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [1.2, 1.5],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [1.5, 3],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	LCPAVG_countries: {
		key: 'LCPAVG_countries',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'LCPAVG',
				attributeClasses: [
					{
						interval: [0.5, 1.5],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [1.5, 2],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [2, 2.5],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [2.5, 3],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [3, 10],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	LCPAVG_regions: {
		key: 'LCPAVG_regions',
		data: [
			{
				fill: '#cccccc',
				name: 'No data',
				...baseOutlinesStyle,
			},
			{
				attributeKey: 'LCPAVG',
				attributeClasses: [
					{
						interval: [0, 1.5],
						intervalBounds: [true, false],
						fill: orangeRange[0],
					},
					{
						interval: [1.5, 2],
						intervalBounds: [true, false],
						fill: orangeRange[1],
					},
					{
						interval: [2, 2.5],
						intervalBounds: [true, false],
						fill: orangeRange[2],
					},
					{
						interval: [2.5, 3],
						intervalBounds: [true, false],
						fill: orangeRange[3],
					},
					{
						interval: [3, 15],
						intervalBounds: [true, true],
						fill: orangeRange[4],
					},
				],
			},
		],
	},
	wsfEvolution: {
		key: 'wsfEvolution',
		data: [
			{},
			{
				attributeKey: 'year',
				attributeValues: {
					1985: {
						fill: 'rgb(213, 0, 0)',
					},
					1986: {
						fill: 'rgb(221, 34, 0)',
					},
					1987: {
						fill: 'rgb(229, 69, 0)',
					},
					1988: {
						fill: 'rgb(238, 103, 0)',
					},
					1989: {
						fill: 'rgb(246, 138, 0)',
					},
					1990: {
						fill: 'rgb(255, 173, 0)',
					},
					1991: {
						fill: 'rgb(236, 189, 0)',
					},
					1992: {
						fill: 'rgb(217, 205, 0)',
					},
					1993: {
						fill: 'rgb(198, 222, 0)',
					},
					1994: {
						fill: 'rgb(179, 238, 0)',
					},
					1995: {
						fill: 'rgb(161, 255, 0)',
					},
					1996: {
						fill: 'rgb(128, 242, 0)',
					},
					1997: {
						fill: 'rgb(96, 229, 0)',
					},
					1998: {
						fill: 'rgb(64, 216, 0)',
					},
					1999: {
						fill: 'rgb(32, 203, 0)',
					},
					2000: {
						fill: 'rgb(0, 190, 0)',
					},
					2001: {
						fill: 'rgb(0, 185, 35)',
					},
					2002: {
						fill: 'rgb(0, 181, 70)',
					},
					2003: {
						fill: 'rgb(0, 176, 105)',
					},
					2004: {
						fill: 'rgb(0, 172, 140)',
					},
					2005: {
						fill: 'rgb(0, 168, 175)',
					},
					2006: {
						fill: 'rgb(0, 138, 184)',
					},
					2007: {
						fill: 'rgb(0, 108, 193)',
					},
					2008: {
						fill: 'rgb(0, 78, 202)',
					},
					2009: {
						fill: 'rgb(0, 48, 211)',
					},
					2010: {
						fill: 'rgb(0, 18, 221)',
					},
					2011: {
						fill: 'rgb(25, 14, 205)',
					},
					2012: {
						fill: 'rgb(50, 10, 189)',
					},
					2013: {
						fill: 'rgb(75, 7, 173)',
					},
					2014: {
						fill: 'rgb(100, 3, 157)',
					},
					2015: {
						fill: 'rgb(126, 0, 142)',
					},
				},
			},
		],
	},
	ghsl: {
		key: 'ghsl',
		data: [
			{},
			{
				attributeKey: 'year',
				attributeValues: {
					1975: {
						fill: '#E60001',
					},
					1990: {
						fill: '#FFAA01',
					},
					2000: {
						fill: '#FFFF00',
					},
					2014: {
						fill: '#FFFFFF',
					},
				},
			},
		],
	},
};
