import PropTypes from 'prop-types';
import ChartWrapper from './ChartWrapper';
import ProgressChart from './ProgressChart';
import SummaryChart from './SummaryChart';
import './style.scss';

const UrbanAreaCharts = ({indicatorName, indicatorKey}) => {
	return (
		<>
			<ChartWrapper
				indicatorName={indicatorName}
				chartKey="urbanAreaProgress"
				indicatorKey={indicatorKey}
				title="Total area of the urban extent - progress"
			>
				<ProgressChart
					chartKey="urbanAreaProgress"
					indicatorKey={indicatorKey}
				/>
			</ChartWrapper>
			<ChartWrapper
				indicatorName={indicatorName}
				chartKey="urbanAreaSummary"
				indicatorKey={indicatorKey}
				title="Total area of the urban extent - summary"
			>
				<SummaryChart chartKey="urbanAreaSummary" indicatorKey={indicatorKey} />
			</ChartWrapper>
		</>
	);
};

UrbanAreaCharts.propTypes = {
	indicatorName: PropTypes.string,
	indicatorKey: PropTypes.string,
};

const UrbanAreaEvolutionCharts = ({indicatorName, indicatorKey}) => {
	return (
		<>
			<ChartWrapper
				indicatorName={indicatorName}
				chartKey="urbanAreaEvolutionProgress"
				indicatorKey={indicatorKey}
				title="Land consumption (LC) progress"
			>
				<ProgressChart
					chartKey="urbanAreaEvolutionProgress"
					indicatorKey={indicatorKey}
				/>
			</ChartWrapper>
		</>
	);
};

UrbanAreaEvolutionCharts.propTypes = {
	indicatorName: PropTypes.string,
	indicatorKey: PropTypes.string,
};

const getCharts = indicator => {
	const indicatorKey = indicator?.key;
	const indicatorName = indicator?.data?.name;
	switch (indicatorKey) {
		case 'urbArea':
			return (
				<UrbanAreaCharts
					indicatorName={indicatorName}
					indicatorKey={indicatorKey}
				/>
			);
		case 'urbAreaEvo':
			return (
				<UrbanAreaEvolutionCharts
					indicatorName={indicatorName}
					indicatorKey={indicatorKey}
				/>
			);
		default:
			return null;
	}
};

const Charts = ({indicator}) => {
	return <div className="visatSdg-Charts">{getCharts(indicator)}</div>;
};

Charts.propTypes = {
	indicator: PropTypes.object,
};

export default Charts;
