import PropTypes from 'prop-types';
import Label from '../Label';
import MapLegendStripe from '../../../atoms/MapLegendStripe';
import LayerVisibilityControl from '../../tools/LayerVisibilityControl';
import LayerOpacityControl from '../../tools/LayerOpacityControl';
import './style.scss';
import Legend from '../../tools/Legend';

const DatasetLabel = ({
	layerTemplate,
	style,
	isDatasetCompareActive,
	mapKey,
}) => {
	return (
		<Label
			className="visatSdg-DatasetLabel"
			icon="ri-database"
			legendStripe={
				<MapLegendStripe values={style?.data?.[1]?.attributeValues} />
			}
			title={layerTemplate?.data?.nameDisplay}
			subtitle={layerTemplate?.data?.description}
			mapKey={mapKey}
			selectionMark={isDatasetCompareActive}
			rightIcon="ri-chevron-down"
		>
			<LayerOpacityControl
				layerTemplateKey={layerTemplate?.key}
				mapKey={mapKey}
			/>
			<LayerVisibilityControl
				layerTemplateKey={layerTemplate?.key}
				mapKey={mapKey}
			/>
			<Legend expandable={false} style={style?.data?.[1]} />
		</Label>
	);
};

DatasetLabel.propTypes = {
	layerTemplate: PropTypes.object,
	style: PropTypes.object,
	isDatasetCompareActive: PropTypes.bool,
	mapKey: PropTypes.string,
};

export default DatasetLabel;
