// import ActionTypes from '../../../constants/ActionTypes';

const INITIAL_STATE = {
	byKey: {
		lcTotal: {
			key: 'lcTotal',
			data: {
				name: 'Total increase',
				relAttribute: 'lcTotal_rel',
				absAttribute: 'lcTotal_abs',
				styleKeys: {
					countries: 'LCPTOT_countries',
					regions: 'LCPTOT_regions',
				},
			},
		},
		lcAverage: {
			key: 'lcAverage',
			data: {
				name: 'Average yearly increase',
				relAttribute: 'lcAverage_rel',
				absAttribute: 'lcAverage_abs',
				styleKeys: {
					countries: 'LCPAVG_countries',
					regions: 'LCPAVG_regions',
				},
			},
		},
		lcYearlyIncreaseMin: {
			key: 'lcYearlyIncreaseMin',
			data: {
				name: 'Min increase',
				relAttribute: 'lcYearlyIncreaseMin_rel',
				absAttribute: 'lcYearlyIncreaseMin_abs',
				yearAttribute: 'lcYearlyIncreaseMin_year',
				styleKeys: {
					countries: 'LCPMIN_countries',
					regions: 'LCPMIN_regions',
				},
			},
		},
		lcYearlyIncreaseMax: {
			key: 'lcYearlyIncreaseMax',
			data: {
				name: 'Max increase',
				relAttribute: 'lcYearlyIncreaseMax_rel',
				absAttribute: 'lcYearlyIncreaseMax_abs',
				yearAttribute: 'lcYearlyIncreaseMax_year',
				styleKeys: {
					countries: 'LCPMAX_countries',
					regions: 'LCPMAX_regions',
				},
			},
		},
		totAreaFirstYear: {
			key: 'totAreaFirstYear',
			data: {
				name: 'Minimum',
				relAttribute: 'totAreaFirstYear_rel',
				absAttribute: 'totAreaFirstYear_abs',
				styleKeys: {
					countries: 'MINP_countries',
					regions: 'MINP_regions',
				},
			},
		},
		totAreaLastYear: {
			key: 'totAreaLastYear',
			data: {
				name: 'Maximum',
				relAttribute: 'totAreaLastYear_rel',
				absAttribute: 'totAreaLastYear_abs',
				styleKeys: {
					countries: 'MAXP_countries',
					regions: 'MAXP_regions',
				},
			},
		},
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default:
			return state;
	}
};
