import {auSelectionKey} from '../../constants/app';

export default {
	key: auSelectionKey,
	data: {
		featureKeysFilter: {
			keys: [],
		},
		style: {},
	},
};
