import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		opacity: Select.sdg.layers.getLayerOpacity(
			state,
			ownProps.mapKey,
			ownProps.layerTemplateKey
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: opacity => {
			dispatch(
				Action.sdg.layers.setLayerProperty(
					ownProps.mapKey,
					ownProps.layerTemplateKey,
					'opacity',
					opacity / 100
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
