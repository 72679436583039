import {ResponsivePie} from '@nivo/pie';
import PropTypes from 'prop-types';
import './style.scss';

const CenteredMetric = ({dataWithArc, centerX, centerY, style, value}) => {
	return (
		<text
			x={centerX}
			y={centerY}
			textAnchor="middle"
			dominantBaseline="central"
			style={style}
		>
			{value ? value : dataWithArc?.[0]?.formattedValue}
		</text>
	);
};

CenteredMetric.propTypes = {
	dataWithArc: PropTypes.array,
	centerX: PropTypes.number,
	centerY: PropTypes.number,
	style: PropTypes.object,
	value: PropTypes.string,
};

const getRowLegend = legendItemWidth => [
	{
		anchor: 'bottom',
		direction: 'row',
		justify: false,
		translateX: 0,
		translateY: 30,
		itemsSpacing: 15,
		itemWidth: legendItemWidth || 110,
		itemHeight: 15,
		itemTextColor: 'var(--base55)',
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 15,
		symbolShape: 'circle',
	},
];

const getColumnLegend = (legendItemWidth, legendTranslateY) => [
	{
		anchor: 'bottom-left',
		direction: 'column',
		justify: false,
		translateX: 0,
		translateY: legendTranslateY || 50,
		itemsSpacing: 15,
		itemWidth: legendItemWidth || 100,
		itemHeight: 3,
		itemTextColor: 'var(--base55)',
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 15,
		symbolShape: 'circle',
	},
];

const DonutChart = ({
	data,
	margin,
	legendItemWidth,
	legendTranslateY,
	columnLegend,
	withoutLegend,
	format,
	showCenteredMetric,
	centeredMetricValue,
}) => {
	return (
		<ResponsivePie
			data={data}
			fontSize={30}
			colors={{datum: 'data.color'}}
			valueFormat={format || '>-.0f'}
			margin={margin || {top: 15, right: 40, bottom: 40, left: 40}}
			innerRadius={0.5}
			padAngle={2}
			arcLabel={false}
			activeOuterRadiusOffset={6}
			animate={false}
			tooltip={data => {
				return (
					<div className="ptr-popup">
						<div className="visatSdg-nivo-tooltip">
							<div className="visatSdg-nivo-tooltip-content">
								<h4>{data.datum.data.label}</h4>
								<div className="visatSdg-nivo-tooltip-item-alternative">
									<span className="visatSdg-nivo-tooltip-value">
										{data.datum.data.originalValue?.toFixed(1)}
									</span>
									<span className="visatSdg-nivo-tooltip-unit">sqkm</span>
								</div>
							</div>
						</div>
					</div>
				);
			}}
			legends={
				!withoutLegend &&
				(columnLegend
					? getColumnLegend(legendItemWidth, legendTranslateY)
					: getRowLegend(legendItemWidth))
			}
			layers={
				showCenteredMetric || centeredMetricValue
					? [
							'arcs',
							'legends',
							'arcLabels',
							props => [
								<CenteredMetric
									key={'label'}
									style={{
										fontSize: '15px',
										fontWeight: 700,
										fontFamily: 'Roboto, sansSerif',
										fill: 'var(--base70)',
									}}
									value={centeredMetricValue}
									{...props}
								/>,
							],
					  ]
					: undefined
			}
			// tooltip={ChartTooltip}
			theme={{
				fontSize: 13,
			}}
			motionConfig="stiff"
		/>
	);
};

DonutChart.propTypes = {
	data: PropTypes.array,
	margin: PropTypes.object,
	legendItemWidth: PropTypes.number,
	legendTranslateY: PropTypes.number,
	columnLegend: PropTypes.bool,
	withoutLegend: PropTypes.bool,
	format: PropTypes.string,
	centeredMetricValue: PropTypes.string,
	showCenteredMetric: PropTypes.bool,
	units: PropTypes.string,
	valueSum: PropTypes.number,
};

export default DonutChart;
