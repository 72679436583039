import PropTypes from 'prop-types';
import HeaderSelect from '../../atoms/HeaderSelect';
import './style.scss';

const FeatureHeaderSelect = ({activeCountry, activeRegion, onMenuOpen}) => {
	const countryName = activeCountry?.data?.name || activeCountry;
	const regionName = activeRegion?.data?.name || activeRegion;
	const name = regionName ? `${regionName} (${countryName})` : `${countryName}`;

	const options = [
		{
			value: name,
			label: name,
		},
	];

	const value = options[0];

	return (
		<HeaderSelect
			icon="map-pin"
			options={options}
			value={value}
			hideMenu
			highlighted
			onChange={() => {}}
			onMenuOpen={onMenuOpen}
		/>
	);
};

FeatureHeaderSelect.propTypes = {
	featureKey: PropTypes.string,
	activeCountry: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	activeRegion: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onMenuOpen: PropTypes.func,
};

export default FeatureHeaderSelect;
