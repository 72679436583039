import PropTypes from 'prop-types';
import HeaderSelect from '../../atoms/HeaderSelect';
import './style.scss';

const CaseSelect = ({cases, activeCaseKey, onActiveCaseChange}) => {
	const options = cases?.map(appCase => {
		return {value: appCase.key, label: appCase.data.nameDisplay};
	});
	const value = options?.find(option => option.value === activeCaseKey);

	const onActiveOptionChange = appCase => {
		onActiveCaseChange(appCase.value);
	};

	return (
		<HeaderSelect
			icon="ri-use-case"
			options={options}
			value={value}
			menuPosition="fixed"
			menuWidth="15rem"
			highlighted
			onChange={onActiveOptionChange}
		/>
	);
};

CaseSelect.propTypes = {
	cases: PropTypes.array,
	activeCaseKey: PropTypes.string,
	onActiveCaseChange: PropTypes.func,
};

export default CaseSelect;
