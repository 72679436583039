import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		metadata: Select.sdg.attributes.getByKey(state, ownProps.attributeKey),
		value: Select.sdg.getAttributeValueByFeatureKey(
			state,
			ownProps.attributeKey,
			ownProps.dataSourceKey,
			ownProps.datasetKey,
			ownProps.featureKey
		),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
