import {Select as CommonSelect} from '@gisatcz/ptr-state';
import router from './router/selectors';
import sdg from './sdg/selectors'; // eslint-disable-line
import {defaultLinks} from '../constants/app';

const getLinks = () => {
	return window?.runtimeConfig?.links || defaultLinks;
};

export default {
	getLinks,
	...CommonSelect,
	router,
	sdg: sdg,
};
