import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../state/Action';
import Select from '../../state/Select';
import {bottomPanelKey} from '../../constants/app';

const mapStateToProps = state => {
	return {
		isOpen: Select.components.get(state, bottomPanelKey, 'open'),
		isExpanded: Select.components.get(state, bottomPanelKey, 'expand'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setOpen: value => {
			dispatch(Action.components.set(bottomPanelKey, 'open', value));
		},
		setExpand: value => {
			dispatch(Action.components.set(bottomPanelKey, 'expand', value));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
