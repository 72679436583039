import {createSelector} from 'reselect';
import {createCachedSelector} from 're-reselect';
import routerSelectors from '../router/selectors';
import features from './features/selectors';
import indicators from './indicators/selectors';
import attributes from './attributes/selectors';
import attributeSets from './attributeSets/selectors';
import layers from './layers/selectors';
import styles from './styles/selectors';
import statistics from './statistics/selectors';
import {auSelectionKey} from '../../constants/app';

import {Select} from '@gisatcz/ptr-state';
import {
	defaultAttributeSetKey,
	defaultCaseKey,
	dataCompareCaseKey,
	defaultLayertemplateKey,
	defaultFeatureKey,
	rightMapKey,
	leftMapKey,
	levels,
	selectionColors,
	urbanExtentColors,
} from '../../constants/app';

// App specific selectors

const getActiveAttributeSetKey = createSelector(
	[routerSelectors.getCurrent],
	currentRouter => {
		const attributeSetKey =
			currentRouter?.params?.parsedQueryString?.attributeSet;

		return attributeSetKey || defaultAttributeSetKey;
	}
);

const getActiveAttributeSet = createSelector(
	[getActiveAttributeSetKey, attributeSets.getAllAsObject],
	(attributeSetKey, attributeSets) => {
		return attributeSets?.[attributeSetKey];
	}
);

const getStyleForActiveAttributeSet = createSelector(
	[
		getActiveAttributeSet,
		styles.getAllAsObject,
		(state, featureKey) => getLevelByFeatureKey(featureKey),
	],
	(attributeSet, styles, level) => {
		if (attributeSet && styles && level) {
			const styleKey = attributeSet?.data?.styleKeys?.[level];
			return (styleKey && styles[styleKey]) || null;
		} else {
			return null;
		}
	}
);

const getActiveIndicatorKey = createSelector(
	[getActiveAttributeSetKey, indicators.getAll],
	(attributeSetKey, indicators) => {
		let activeIndicatorKey = null;
		indicators?.forEach(indicator => {
			const attributeSets = indicator?.data?.sdgAttributeSets;
			if (attributeSets.findIndex(item => item === attributeSetKey) !== -1) {
				activeIndicatorKey = indicator?.key;
			}
		});
		return activeIndicatorKey;
	}
);

const getActiveIndicator = createSelector(
	[getActiveIndicatorKey, indicators.getAllAsObject],
	(indicatorKey, indicators) => {
		return indicators?.[indicatorKey];
	}
);

const getActiveCaseKey = createSelector(
	[routerSelectors.getCurrent],
	currentRouter => {
		const caseKey = currentRouter?.params?.parsedQueryString?.case;

		return caseKey || defaultCaseKey;
	}
);

const isDatasetCompareActive = createSelector(
	[getActiveCaseKey],
	activeCaseKey => {
		return activeCaseKey === dataCompareCaseKey;
	}
);

const getMapKeyOrderIndex = createSelector(
	[Select.maps.getAllMapsInUse, (state, mapKey) => mapKey],
	(maps, mapKey) => {
		//TODO is this solution enaugh
		return maps.indexOf(mapKey);
	}
);

const getActiveLayerTemplateKey = createSelector(
	[routerSelectors.getCurrent, isDatasetCompareActive, getMapKeyOrderIndex],
	(currentRouter, dataCompareActive, mapKeyOrderIndex) => {
		const layerTemplates =
			currentRouter?.params?.parsedQueryString?.layerTemplate;
		const splitedLayerTemplatesKeys = layerTemplates?.split(',') || [];
		if (dataCompareActive) {
			//TODO figure if given mapKey is for first or second featureKey
			return (
				splitedLayerTemplatesKeys?.[mapKeyOrderIndex] ||
				defaultLayertemplateKey[mapKeyOrderIndex]
			);
		} else {
			//TODO - return first layerTemplate
			return splitedLayerTemplatesKeys?.[0] || defaultLayertemplateKey[0];
		}
	}
);

const getActiveLayerTemplate = createSelector(
	[getActiveLayerTemplateKey, Select.layerTemplates.getAllAsObject],
	(layerTemplateKey, layerTemplates) => {
		return layerTemplates?.[layerTemplateKey];
	}
);

const getStyleForActiveLayerTemplate = createSelector(
	[getActiveLayerTemplate, styles.getAllAsObject],
	(layerTemplateKey, styles) => {
		if (layerTemplateKey && styles) {
			const styleKey = layerTemplateKey?.data?.styleKey;
			return (styleKey && styles[styleKey]) || null;
		} else {
			return null;
		}
	}
);

const getActiveLayerTemplateKeys = createSelector(
	[routerSelectors.getCurrent, isDatasetCompareActive],
	(currentRouter, dataCompareActive) => {
		const layerTemplates =
			currentRouter?.params?.parsedQueryString?.layerTemplate;
		const splitedLayerTemplatesKeys = layerTemplates?.split(',') || [];

		if (dataCompareActive) {
			return splitedLayerTemplatesKeys.length > 0
				? splitedLayerTemplatesKeys
				: defaultLayertemplateKey;
		} else {
			return splitedLayerTemplatesKeys.length > 0
				? splitedLayerTemplatesKeys
				: [defaultLayertemplateKey[0]];
		}
	}
);

const getLayerTemplateParams = createSelector(
	[routerSelectors.getCurrent],
	currentRouter => {
		const layerTemplates =
			currentRouter?.params?.parsedQueryString?.layerTemplate;
		const splitedLayerTemplatesKeys = layerTemplates?.split(',') || [];
		if (splitedLayerTemplatesKeys && splitedLayerTemplatesKeys.length === 2) {
			return splitedLayerTemplatesKeys;
		} else if (
			splitedLayerTemplatesKeys &&
			splitedLayerTemplatesKeys.length === 1
		) {
			return [...splitedLayerTemplatesKeys, defaultLayertemplateKey[0]];
		} else {
			return defaultLayertemplateKey;
		}
	}
);

const getFeatureUrlParams = createSelector(
	[routerSelectors.getCurrent],
	currentRouter => {
		const featureKey = currentRouter?.params?.parsedQueryString?.feature;
		const splitedFeatureKey = featureKey?.split(',') || [];
		if (splitedFeatureKey && splitedFeatureKey.length === 2) {
			return splitedFeatureKey;
		} else if (splitedFeatureKey && splitedFeatureKey.length === 1) {
			return [...splitedFeatureKey, defaultFeatureKey];
		} else {
			return [defaultFeatureKey, defaultFeatureKey];
		}
	}
);

const getActiveFeatureKey = createSelector(
	[routerSelectors.getCurrent, isDatasetCompareActive, getMapKeyOrderIndex],
	(currentRouter, dataCompareActive, mapKeyOrderIndex) => {
		const featureKey = currentRouter?.params?.parsedQueryString?.feature;
		const splitedFeatureKey = featureKey?.split(',') || [];

		// If dataCompareActive === false, then depends on mapKey. Each map can have different feature inside
		if (dataCompareActive === false) {
			//TODO figure if given mapKey is for first or second featureKey
			return splitedFeatureKey?.[mapKeyOrderIndex] || defaultFeatureKey;
		} else {
			//TODO - return first attribute
			return splitedFeatureKey?.[0] || defaultFeatureKey;
		}
	}
);

const getActiveLayerFeatureKey = createSelector(
	[getActiveFeatureKey],
	activeFeatureKey => {
		const splited = activeFeatureKey.split('_');
		if (splited.length > 1) {
			return Number.parseInt(splited[1]);
		} else {
			return Number.parseInt(splited[0]);
		}
	}
);

const getActiveFeatureKeys = createSelector(
	[routerSelectors.getCurrent],
	currentRouter => {
		const featureKey = currentRouter?.params?.parsedQueryString?.feature;
		const splitedFeatureKey = featureKey?.split(',') || [];
		return splitedFeatureKey.length > 0
			? splitedFeatureKey
			: [defaultFeatureKey, defaultFeatureKey];
	}
);

const getLevelByFeatureKey = featureKey => {
	if (featureKey.split) {
		if (featureKey.split('_')[1]) {
			return levels[1];
		} else {
			return levels[0];
		}
	}
};

const getActiveAreaLevels = createSelector(
	[getActiveFeatureKeys],
	featureKeys => {
		return featureKeys.map(getLevelByFeatureKey);
	}
);

const getRasterLayerByMapKey = createSelector(
	[getActiveLayerTemplate, layers.getAllAsObjectByMapKey],
	(activeLayerTemplate, layersByMapKey) => {
		const activeLayerTemplateKey = activeLayerTemplate?.key;

		const layer = layers.getLayerByLayerTemplateKey(
			layersByMapKey,
			activeLayerTemplateKey
		);

		if (layer) {
			return {
				...layer,
				...layer.data,
				visible: !layer.data.hidden,
			};
		} else {
			return null;
		}
	}
);

const getLeftMapStyle = createSelector(
	[styles.getAllAsObject, getActiveAttributeSet, getActiveAreaLevels],
	(styles, activeAttributeSet, activeLevels) => {
		const styleKey = activeAttributeSet?.data?.styleKeys?.[activeLevels[0]];
		const style = styles?.[styleKey];
		return style || {};
	}
);

const getRightMapStyle = createSelector(
	[styles.getAllAsObject, getActiveAttributeSet, getActiveAreaLevels],
	(styles, activeAttributeSet, activeLevels) => {
		const styleKey = activeAttributeSet?.data?.styleKeys?.[activeLevels[1]];
		const style = styles?.[styleKey];
		return style || {};
	}
);

const getRightMapStatisticLayer = createSelector(
	[
		getActiveAreaLevels,
		getActiveLayerTemplate,
		layers.getStatisticsLayerByMapKey,
		isDatasetCompareActive,
		getRightMapStyle,
	],
	(
		activeAreaLevels,
		activeLayerTemplate,
		layerMetadata,
		isDatasetCompareActive,
		rightMapStyle
	) => {
		const level = activeAreaLevels[isDatasetCompareActive ? 0 : 1];
		const activeLayerTemplateKey = activeLayerTemplate?.key;
		const url = `https://gisat-gis.eu-central-1.linodeobjects.com/esaUtepVisatSdg/data/layers/${activeLayerTemplateKey}/${level}/{z}/{x}/{y}.pbf`;

		let opacity = 1;
		let visible = true;
		if (layerMetadata) {
			opacity = layerMetadata?.data?.opacity;
			visible = !layerMetadata?.data?.hidden;
		}

		return visible
			? {
					key: `rightMapStatisticLayer${rightMapStyle.key}_${activeLayerTemplateKey}`,
					type: 'mvt',
					opacity,
					options: {
						selected: {
							[auSelectionKey]: {},
						},
						pickable: true,
						selectable: true,
						minZoom: 0,
						maxZoom: 8,
						fidColumnName: level === 'countries' ? 'ADM0_CODE' : 'ADM1_CODE',
						url,
						style: {
							rules: [
								{
									styles: [...rightMapStyle.data],
								},
							],
						},
					},
			  }
			: null;
	}
);

const getLeftMapStatisticLayer = createSelector(
	[
		getActiveAreaLevels,
		getActiveLayerTemplate,
		layers.getStatisticsLayerByMapKey,
		getLeftMapStyle,
	],
	(activeAreaLevels, activeLayerTemplate, layerMetadata, leftMapStyle) => {
		const level = activeAreaLevels[0];
		const activeLayerTemplateKey = activeLayerTemplate?.key;
		const url = `https://gisat-gis.eu-central-1.linodeobjects.com/esaUtepVisatSdg/data/layers/${activeLayerTemplateKey}/${level}/{z}/{x}/{y}.pbf`;

		let opacity = 1;
		let visible = true;
		if (layerMetadata) {
			opacity = layerMetadata?.data?.opacity;
			visible = !layerMetadata?.data?.hidden;
		}

		return visible
			? {
					key: `leftMapStatisticLayer${leftMapStyle.key}_${activeLayerTemplateKey}`,
					type: 'mvt',
					opacity,
					options: {
						selected: {
							[auSelectionKey]: {},
						},
						pickable: true,
						selectable: true,
						minZoom: 0,
						maxZoom: 8,
						fidColumnName: level === 'countries' ? 'ADM0_CODE' : 'ADM1_CODE',
						url,
						style: {
							rules: [
								{
									styles: [...leftMapStyle.data],
								},
							],
						},
					},
			  }
			: null;
	}
);

const getMapInitViews = createSelector([Select.maps.getMapSetMaps], maps => {
	if (maps) {
		const leftMap = maps.find(m => m.key === leftMapKey);
		const rightMap = maps.find(m => m.key === rightMapKey);

		const initLeftMapView = leftMap?.data?.view;
		const initRightMapView = rightMap?.data?.view;

		// const commonBoxRange = Math.max(
		// 	initRightMapView?.boxRange,
		// 	initLeftMapView?.boxRange
		// );
		//
		// if (commonBoxRange) {
		// 	initRightMapView.boxRange = commonBoxRange;
		// 	initLeftMapView.boxRange = commonBoxRange;
		// }
		return [initLeftMapView, initRightMapView];
	} else {
		return [];
	}
});

const getAttributeValueByFeatureKey = createCachedSelector(
	[
		attributes.getByKey,
		statistics.getAllAsObject,
		(state, attributeKey, dataSourceKey) => dataSourceKey,
		(state, attributeKey, dataSourceKey, datasetKey) => datasetKey,
		(state, attributeKey, dataSourceKey, datasetKey, featureKey) => featureKey,
	],
	(metadata, stats, dataSourceKey, datasetKey, featureKey) => {
		if (metadata) {
			const column = metadata?.data?.columnName;
			if (column) {
				return stats?.[featureKey]?.[datasetKey]?.data?.[dataSourceKey]
					?.statistics?.[column]?.value;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
)(
	(state, attributeKey, dataSourceKey, datasetKey, featureKey) =>
		`${attributeKey}_${dataSourceKey}_${datasetKey}_${featureKey}`
);

const getProgressChartData = createSelector(
	[
		indicators.getByKey,
		statistics.getAllAsObject,
		Select.layerTemplates.getAllAsObject,
		(state, indicatorKey, chartKey) =>
			Select.components.get(state, chartKey, 'type'),
		(state, indicatorKey, chartKey) =>
			Select.components.get(state, chartKey, 'showParentAreas'),
		state => getActiveLayerTemplateKey(state, leftMapKey),
		state => getActiveLayerTemplateKey(state, rightMapKey),
		(state, indicatorKey, chartKey, leftMapFeatureKey) => leftMapFeatureKey,
		(state, indicatorKey, chartKey, leftMapFeatureKey) =>
			features.getActiveCountry(state, leftMapFeatureKey),
		(state, indicatorKey, chartKey, leftMapFeatureKey) =>
			features.getActiveRegion(state, leftMapFeatureKey),
		(state, indicatorKey, chartKey, leftMapFeatureKey, rightMapFeatureKey) =>
			rightMapFeatureKey,
		(state, indicatorKey, chartKey, leftMapFeatureKey, rightMapFeatureKey) =>
			features.getActiveCountry(state, rightMapFeatureKey),
		(state, indicatorKey, chartKey, leftMapFeatureKey, rightMapFeatureKey) =>
			features.getActiveRegion(state, rightMapFeatureKey),
	],
	(
		indicator,
		statistics,
		datasets,
		type,
		showParentAreas,
		leftDatasetKey,
		rightDatasetKey,
		leftFeatureKey,
		leftMapCountry,
		leftMapRegion,
		rightFeatureKey,
		rightMapCountry,
		rightMapRegion
	) => {
		if (
			indicator &&
			statistics &&
			type &&
			leftDatasetKey &&
			leftFeatureKey &&
			rightDatasetKey &&
			rightFeatureKey
		) {
			const dataSource = indicator.data?.dataSourceKeys?.[type];
			const leftData =
				statistics[leftFeatureKey]?.[leftDatasetKey]?.data?.[dataSource]?.chart;
			const rightData =
				statistics[rightFeatureKey]?.[rightDatasetKey]?.data?.[dataSource]
					?.chart;
			const leftDataset = datasets?.[leftDatasetKey];
			const rightDataset = datasets?.[rightDatasetKey];
			const leftCountryName = leftMapCountry?.data?.name || leftMapCountry;
			const leftRegionName = leftMapRegion?.data?.name || leftMapRegion;
			const rightCountryName = rightMapCountry?.data?.name || rightMapCountry;
			const rightRegionName = rightMapRegion?.data?.name || rightMapRegion;
			const leftFeatureName = leftRegionName
				? `${leftRegionName} (${leftCountryName})`
				: `${leftCountryName}`;
			const rightFeatureName = rightRegionName
				? `${rightRegionName} (${rightCountryName})`
				: `${rightCountryName}`;

			const data = [];

			// Add data for selected units
			if (leftData) {
				data.push({
					id:
						leftDatasetKey === rightDatasetKey
							? `${selectionColors[0]}_${leftFeatureName}`
							: `${selectionColors[0]}_${leftDataset?.data?.nameDisplay}`,
					data: leftData,
					color: selectionColors[0],
				});
			}
			if (rightData) {
				data.push({
					id:
						leftDatasetKey === rightDatasetKey
							? `${selectionColors[1]}_${rightFeatureName}`
							: `${selectionColors[1]}_${rightDataset?.data?.nameDisplay}`,
					data: rightData,
					color: selectionColors[1],
				});
			}

			// Add data for parent units
			if (showParentAreas) {
				if (leftMapRegion) {
					const leftParentName = leftMapCountry?.data?.name;
					const leftParentData =
						statistics[leftMapCountry?.key]?.[leftDatasetKey]?.data?.[
							dataSource
						]?.chart;
					if (leftParentData) {
						data.push({
							id:
								leftDatasetKey === rightDatasetKey
									? leftParentName
									: `${leftDataset?.data?.nameDisplay} (${leftParentName})`,
							data: leftParentData,
							color: selectionColors[2],
						});
					}
				}
				if (rightMapRegion) {
					const rightParentName = rightMapCountry?.data?.name;
					const rightParentData =
						statistics[rightMapCountry?.key]?.[rightDatasetKey]?.data?.[
							dataSource
						]?.chart;
					if (rightParentData) {
						data.push({
							id:
								leftDatasetKey === rightDatasetKey
									? rightParentName
									: `${rightDataset?.data?.nameDisplay} (${rightParentName})`,
							data: rightParentData,
							color: selectionColors[3],
						});
					}
				}
			}

			return data;
		} else {
			return null;
		}
	}
);

const getSummaryChartData = createSelector(
	[
		indicators.getByKey,
		statistics.getAllAsObject,
		Select.layerTemplates.getAllAsObject,
		state => getActiveLayerTemplateKey(state, leftMapKey),
		state => getActiveLayerTemplateKey(state, rightMapKey),
		(state, indicatorKey, chartKey, leftMapFeatureKey) => leftMapFeatureKey,
		(state, indicatorKey, chartKey, leftMapFeatureKey) =>
			features.getActiveCountry(state, leftMapFeatureKey),
		(state, indicatorKey, chartKey, leftMapFeatureKey) =>
			features.getActiveRegion(state, leftMapFeatureKey),
		(state, indicatorKey, chartKey, leftMapFeatureKey, rightMapFeatureKey) =>
			rightMapFeatureKey,
		(state, indicatorKey, chartKey, leftMapFeatureKey, rightMapFeatureKey) =>
			features.getActiveCountry(state, rightMapFeatureKey),
		(state, indicatorKey, chartKey, leftMapFeatureKey, rightMapFeatureKey) =>
			features.getActiveRegion(state, rightMapFeatureKey),
	],
	(
		indicator,
		statistics,
		datasets,
		leftDatasetKey,
		rightDatasetKey,
		leftFeatureKey,
		leftMapCountry,
		leftMapRegion,
		rightFeatureKey,
		rightMapCountry,
		rightMapRegion
	) => {
		if (
			indicator &&
			statistics &&
			leftDatasetKey &&
			leftFeatureKey &&
			rightDatasetKey &&
			rightFeatureKey
		) {
			const dataSource = indicator.data?.dataSourceKeys?.['abs'];
			const leftData =
				statistics[leftFeatureKey]?.[leftDatasetKey]?.data?.[dataSource]
					?.statistics;
			const rightData =
				statistics[rightFeatureKey]?.[rightDatasetKey]?.data?.[dataSource]
					?.statistics;
			const leftDataset = datasets?.[leftDatasetKey];
			const rightDataset = datasets?.[rightDatasetKey];
			const leftCountryName = leftMapCountry?.data?.name || leftMapCountry;
			const leftRegionName = leftMapRegion?.data?.name || leftMapRegion;
			const rightCountryName = rightMapCountry?.data?.name || rightMapCountry;
			const rightRegionName = rightMapRegion?.data?.name || rightMapRegion;
			const leftFeatureName = leftRegionName
				? `${leftRegionName} (${leftCountryName})`
				: `${leftCountryName}`;
			const rightFeatureName = rightRegionName
				? `${rightRegionName} (${rightCountryName})`
				: `${rightCountryName}`;

			if (leftData && rightData) {
				const leftMinTotal = leftData?.MIN?.value + leftData?.NONMIN?.value;
				const leftMaxTotal = leftData?.MAX?.value + leftData?.NONMAX?.value;
				const rightMinTotal = rightData?.MIN?.value + rightData?.NONMIN?.value;
				const rightMaxTotal = rightData?.MAX?.value + rightData?.NONMAX?.value;

				return [
					{
						name:
							leftDatasetKey === rightDatasetKey
								? leftFeatureName
								: leftDataset?.data?.nameDisplay,
						key: `${leftFeatureKey}_0`,
						data: [
							{
								name: 'Minimum',
								data: [
									{
										id: 'MIN',
										label: 'Urbanised area',
										originalValue: leftData?.MIN?.value,
										value: leftData?.MIN?.value / leftMinTotal,
										color: urbanExtentColors['MIN'],
									},
									{
										id: 'NONMIN',
										label: 'Non-urbanised area',
										value: leftData?.NONMIN?.value / leftMinTotal,
										originalValue: leftData?.NONMIN?.value,
										color: urbanExtentColors['NONMIN'],
									},
								],
							},
							{
								name: 'Maximum',
								data: [
									{
										id: 'MAX',
										label: 'Urbanised area',
										originalValue: leftData?.MAX?.value,
										value: leftData?.MAX?.value / leftMaxTotal,
										color: urbanExtentColors['MAX'],
									},
									{
										id: 'NONMAX',
										label: 'Non-urbanised area',
										originalValue: leftData?.NONMAX?.value,
										value: leftData?.NONMAX?.value / leftMaxTotal,
										color: urbanExtentColors['NONMAX'],
									},
								],
							},
						],
					},
					{
						name:
							leftDatasetKey === rightDatasetKey
								? rightFeatureName
								: rightDataset?.data?.nameDisplay,
						key: `${rightFeatureKey}_1`,
						data: [
							{
								name: 'Minimum',
								data: [
									{
										id: 'MIN',
										label: 'Urbanised area',
										originalValue: rightData?.MIN?.value,
										value: rightData?.MIN?.value / rightMinTotal,
										color: urbanExtentColors['MIN'],
									},
									{
										id: 'NONMIN',
										label: 'Non-urbanised area',
										originalValue: rightData?.NONMIN?.value,
										value: rightData?.NONMIN?.value / rightMinTotal,
										color: urbanExtentColors['NONMIN'],
									},
								],
							},
							{
								name: 'Maximum',
								data: [
									{
										id: 'MAX',
										label: 'Urbanised area',
										value: rightData?.MAX?.value / rightMaxTotal,
										originalValue: rightData?.MAX?.value,
										color: urbanExtentColors['MAX'],
									},
									{
										id: 'NONMAX',
										label: 'Non-urbanised area',
										value: rightData?.NONMAX?.value / rightMaxTotal,
										originalValue: rightData?.NONMAX?.value,
										color: urbanExtentColors['NONMAX'],
									},
								],
							},
						],
					},
				];
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

export default {
	getActiveAttributeSetKey,
	getActiveAttributeSet,
	getStyleForActiveAttributeSet,
	getActiveIndicatorKey,
	getActiveIndicator,
	getActiveCaseKey,
	getActiveLayerTemplateKeys,
	getActiveLayerTemplateKey,
	getActiveLayerTemplate,
	getStyleForActiveLayerTemplate,
	getMapKeyOrderIndex,
	getLayerTemplateParams,
	getActiveFeatureKey,
	getActiveLayerFeatureKey,
	getFeatureUrlParams,
	getActiveFeatureKeys,
	getLeftMapStatisticLayer,
	getLeftMapStyle,
	getRightMapStyle,
	getRightMapStatisticLayer,
	getRasterLayerByMapKey,
	getMapInitViews,
	getAttributeValueByFeatureKey,
	getProgressChartData,
	getSummaryChartData,

	isDatasetCompareActive,

	features,
	indicators,
	attributes,
	attributeSets,
	layers,
	styles,
	statistics,
};
