import PropTypes from 'prop-types';
import AbsoluteAttribute from '../../../Indicators/Indicator/AttributeSets/AttributeSet/Attribute/AbsoluteAttribute';
import RelativeAttribute from '../../../Indicators/Indicator/AttributeSets/AttributeSet/Attribute/RelativeAttribute';
import './style.scss';

const StatisticLayerTooltip = ({
	activeCountry,
	activeRegion,
	activeIndicator,
	activeAttributeSet,
	relAttributeValue,
	absAttributeValue,
	yearAttributeValue,
	absAttributeUnit,
	relAttributeUnit,
}) => {
	return (
		<>
			<div className="visatSdg-MapTooltip">
				<div className="visatSdg-MapTooltip-title">
					{activeRegion ? activeRegion?.data?.name : activeCountry?.data?.name}
				</div>

				{activeCountry && activeRegion ? (
					<div className="visatSdg-MapTooltip-subtitle">
						{activeCountry?.data?.name}
					</div>
				) : null}

				<div className="visatSdg-MapTooltip-attributeSet">
					<span>{activeIndicator?.data?.name}: </span>
					{activeAttributeSet?.data?.name}
				</div>
				<div className="visatSdg-MapTooltip-attributeSetValues">
					{yearAttributeValue ? (
						<div className="visatSdg-MapTooltip-year">
							{yearAttributeValue}:
						</div>
					) : null}
					<AbsoluteAttribute
						value={absAttributeValue}
						unit={absAttributeUnit}
					/>
					<RelativeAttribute
						value={relAttributeValue}
						unit={relAttributeUnit}
					/>
				</div>
			</div>
		</>
	);
};

StatisticLayerTooltip.propTypes = {
	activeCountry: PropTypes.object,
	activeRegion: PropTypes.object,
	activeIndicator: PropTypes.object,
	activeAttributeSet: PropTypes.object,
	relAttributeValue: PropTypes.number,
	absAttributeValue: PropTypes.number,
	yearAttributeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	absAttributeUnit: PropTypes.string,
	relAttributeUnit: PropTypes.string,
};

export default StatisticLayerTooltip;
