const countriesByFeatureKey = {
	3: {
		key: 3,
		data: {
			name: 'Albania',
			extent: [19.26, 39.64, 21.06, 42.66],
			regionsByFeatureKey: {
				'3_305': {
					key: '3_305',
					data: {
						name: 'Berat',
						extent: [19.73, 40.49, 20.16, 40.88],
					},
				},
				'3_306': {
					key: '3_306',
					data: {
						name: 'Bulqize',
						extent: [20.11, 41.32, 20.56, 41.62],
					},
				},
				'3_307': {
					key: '3_307',
					data: {
						name: 'Delvine',
						extent: [19.89, 39.86, 20.24, 40.12],
					},
				},
				'3_308': {
					key: '3_308',
					data: {
						name: 'Devoll',
						extent: [19.7, 40.43, 21.06, 40.76],
					},
				},
				'3_309': {
					key: '3_309',
					data: {
						name: 'Diber',
						extent: [20.11, 41.56, 20.57, 41.91],
					},
				},
				'3_310': {
					key: '3_310',
					data: {
						name: 'Durres',
						extent: [19.39, 41.25, 19.64, 41.59],
					},
				},
				'3_311': {
					key: '3_311',
					data: {
						name: 'Elbasan',
						extent: [19.8, 40.87, 20.34, 41.28],
					},
				},
				'3_312': {
					key: '3_312',
					data: {
						name: 'Fier',
						extent: [19.3, 40.56, 19.81, 40.89],
					},
				},
				'3_313': {
					key: '3_313',
					data: {
						name: 'Gjirokaster',
						extent: [19.87, 39.78, 20.45, 40.26],
					},
				},
				'3_314': {
					key: '3_314',
					data: {
						name: 'Gramsh',
						extent: [20.01, 40.72, 20.47, 40.99],
					},
				},
				'3_315': {
					key: '3_315',
					data: {
						name: 'Has',
						extent: [20.21, 42.08, 20.54, 42.31],
					},
				},
				'3_316': {
					key: '3_316',
					data: {
						name: 'Kavaje',
						extent: [19.44, 41.01, 19.75, 41.27],
					},
				},
				'3_317': {
					key: '3_317',
					data: {
						name: 'Kolonje',
						extent: [20.44, 40.08, 20.79, 40.51],
					},
				},
				'3_318': {
					key: '3_318',
					data: {
						name: 'Korce',
						extent: [20.29, 40.43, 20.98, 40.94],
					},
				},
				'3_319': {
					key: '3_319',
					data: {
						name: 'Kruje',
						extent: [19.58, 41.41, 19.91, 41.59],
					},
				},
				'3_320': {
					key: '3_320',
					data: {
						name: 'Kucove',
						extent: [19.82, 40.78, 19.97, 40.88],
					},
				},
				'3_321': {
					key: '3_321',
					data: {
						name: 'Kukes',
						extent: [20.13, 41.82, 20.63, 42.18],
					},
				},
				'3_322': {
					key: '3_322',
					data: {
						name: 'Kurbin',
						extent: [19.56, 41.55, 19.84, 41.71],
					},
				},
				'3_323': {
					key: '3_323',
					data: {
						name: 'Lezhe',
						extent: [19.44, 41.64, 19.88, 41.95],
					},
				},
				'3_324': {
					key: '3_324',
					data: {
						name: 'Librazhd',
						extent: [20.12, 40.93, 20.6, 41.4],
					},
				},
				'3_325': {
					key: '3_325',
					data: {
						name: 'Lushnje',
						extent: [19.39, 40.77, 19.88, 41.07],
					},
				},
				'3_326': {
					key: '3_326',
					data: {
						name: 'Malesi E Madhe',
						extent: [19.28, 42.1, 19.79, 42.66],
					},
				},
				'3_327': {
					key: '3_327',
					data: {
						name: 'Mallakaster',
						extent: [19.63, 40.42, 21.02, 40.68],
					},
				},
				'3_328': {
					key: '3_328',
					data: {
						name: 'Mat',
						extent: [19.8, 41.39, 20.21, 41.77],
					},
				},
				'3_329': {
					key: '3_329',
					data: {
						name: 'Mirdite',
						extent: [19.72, 41.68, 20.27, 42.03],
					},
				},
				'3_330': {
					key: '3_330',
					data: {
						name: 'Peqin',
						extent: [19.67, 41.0, 19.9, 41.17],
					},
				},
				'3_331': {
					key: '3_331',
					data: {
						name: 'Permet',
						extent: [20.05, 40.06, 20.61, 40.5],
					},
				},
				'3_332': {
					key: '3_332',
					data: {
						name: 'Pogradec',
						extent: [20.38, 40.79, 20.84, 41.09],
					},
				},
				'3_333': {
					key: '3_333',
					data: {
						name: 'Puke',
						extent: [19.73, 41.88, 20.24, 42.27],
					},
				},
				'3_334': {
					key: '3_334',
					data: {
						name: 'Sarande',
						extent: [19.84, 39.64, 20.32, 40.16],
					},
				},
				'3_335': {
					key: '3_335',
					data: {
						name: 'Shkoder',
						extent: [19.34, 41.84, 19.9, 42.49],
					},
				},
				'3_336': {
					key: '3_336',
					data: {
						name: 'Skrapar',
						extent: [20.05, 40.35, 20.44, 40.75],
					},
				},
				'3_337': {
					key: '3_337',
					data: {
						name: 'Tepelene',
						extent: [19.75, 40.19, 20.15, 40.53],
					},
				},
				'3_338': {
					key: '3_338',
					data: {
						name: 'Tirane',
						extent: [19.58, 41.12, 20.23, 41.51],
					},
				},
				'3_339': {
					key: '3_339',
					data: {
						name: 'Tropoje',
						extent: [19.79, 42.2, 20.36, 42.56],
					},
				},
				'3_340': {
					key: '3_340',
					data: {
						name: 'Vlore',
						extent: [19.26, 40.04, 19.89, 40.67],
					},
				},
			},
		},
	},
	7: {
		key: 7,
		data: {
			name: 'Andorra',
			extent: [1.42, 42.44, 1.78, 42.66],
			regionsByFeatureKey: {
				'7_391': {
					key: '7_391',
					data: {
						name: 'Andorra La Vella',
						extent: [1.46, 42.48, 1.57, 42.53],
					},
				},
				'7_392': {
					key: '7_392',
					data: {
						name: 'Canillo',
						extent: [1.57, 42.55, 1.78, 42.64],
					},
				},
				'7_393': {
					key: '7_393',
					data: {
						name: 'Encamp',
						extent: [1.55, 42.49, 1.74, 42.58],
					},
				},
				'7_394': {
					key: '7_394',
					data: {
						name: 'Escaldes-engordany',
						extent: [1.52, 42.45, 1.67, 42.53],
					},
				},
				'7_395': {
					key: '7_395',
					data: {
						name: 'La Massana',
						extent: [1.42, 42.51, 1.57, 42.61],
					},
				},
				'7_396': {
					key: '7_396',
					data: {
						name: 'Ordino',
						extent: [1.46, 42.55, 1.61, 42.66],
					},
				},
				'7_397': {
					key: '7_397',
					data: {
						name: 'Sant Julia De Loria',
						extent: [1.43, 42.44, 1.57, 42.51],
					},
				},
			},
		},
	},
	13: {
		key: 13,
		data: {
			name: 'Armenia',
			extent: [43.45, 38.84, 46.63, 41.3],
			regionsByFeatureKey: {
				'13_453': {
					key: '13_453',
					data: {
						name: 'Aragatsotn',
						extent: [43.59, 40.19, 44.53, 40.76],
					},
				},
				'13_454': {
					key: '13_454',
					data: {
						name: 'Ararat',
						extent: [44.31, 39.71, 45.11, 40.17],
					},
				},
				'13_455': {
					key: '13_455',
					data: {
						name: 'Armavir',
						extent: [43.65, 40.02, 44.4, 40.29],
					},
				},
				'13_456': {
					key: '13_456',
					data: {
						name: 'Gergharkunik',
						extent: [44.78, 39.86, 45.98, 40.74],
					},
				},
				'13_457': {
					key: '13_457',
					data: {
						name: 'Kotayk',
						extent: [44.39, 40.07, 45.02, 40.71],
					},
				},
				'13_458': {
					key: '13_458',
					data: {
						name: 'Lori',
						extent: [43.94, 40.66, 44.93, 41.24],
					},
				},
				'13_460': {
					key: '13_460',
					data: {
						name: 'Shirak',
						extent: [43.45, 40.41, 44.18, 41.17],
					},
				},
				'13_461': {
					key: '13_461',
					data: {
						name: 'Syunik',
						extent: [45.7, 38.84, 46.63, 39.86],
					},
				},
				'13_462': {
					key: '13_462',
					data: {
						name: 'Tavush',
						extent: [44.73, 40.64, 45.6, 41.3],
					},
				},
				'13_463': {
					key: '13_463',
					data: {
						name: 'Vayots Dzor',
						extent: [45.07, 39.5, 45.82, 40.01],
					},
				},
				'13_464': {
					key: '13_464',
					data: {
						name: 'Yerevan',
						extent: [44.39, 40.07, 44.61, 40.24],
					},
				},
			},
		},
	},
	18: {
		key: 18,
		data: {
			name: 'Austria',
			extent: [9.54, 46.38, 17.16, 49.02],
			regionsByFeatureKey: {
				'18_478': {
					key: '18_478',
					data: {
						name: 'Burgenland',
						extent: [16.0, 46.83, 17.16, 48.18],
					},
				},
				'18_479': {
					key: '18_479',
					data: {
						name: 'Karnten',
						extent: [12.65, 46.38, 15.06, 47.13],
					},
				},
				'18_480': {
					key: '18_480',
					data: {
						name: 'Niederosterreich',
						extent: [14.45, 47.42, 16.99, 49.02],
					},
				},
				'18_481': {
					key: '18_481',
					data: {
						name: 'Oberosterreich',
						extent: [12.75, 47.46, 14.99, 48.77],
					},
				},
				'18_482': {
					key: '18_482',
					data: {
						name: 'Salzburg',
						extent: [12.07, 46.94, 14.0, 48.04],
					},
				},
				'18_483': {
					key: '18_483',
					data: {
						name: 'Steiermark',
						extent: [13.56, 46.61, 16.17, 47.83],
					},
				},
				'18_484': {
					key: '18_484',
					data: {
						name: 'Tirol',
						extent: [10.1, 46.66, 12.97, 47.74],
					},
				},
				'18_485': {
					key: '18_485',
					data: {
						name: 'Vorarlberg',
						extent: [9.54, 46.85, 10.23, 47.59],
					},
				},
				'18_486': {
					key: '18_486',
					data: {
						name: 'Wien',
						extent: [16.18, 48.12, 16.58, 48.32],
					},
				},
			},
		},
	},
	19: {
		key: 19,
		data: {
			name: 'Azerbaijan',
			extent: [44.77, 38.26, 51.68, 42.71],
			regionsByFeatureKey: {
				'19_147297': {
					key: '19_147297',
					data: {
						name: 'Absheron',
						extent: [48.73, 39.7, 50.42, 41.01],
					},
				},
				'19_147298': {
					key: '19_147298',
					data: {
						name: 'Aran',
						extent: [46.74, 38.95, 49.44, 40.99],
					},
				},
				'19_147299': {
					key: '19_147299',
					data: {
						name: 'Daghlig Shirvan',
						extent: [47.73, 40.29, 49.24, 41.09],
					},
				},
				'19_147300': {
					key: '19_147300',
					data: {
						name: 'Ganja-Gazakh',
						extent: [44.98, 40.28, 46.98, 41.47],
					},
				},
				'19_147301': {
					key: '19_147301',
					data: {
						name: 'Guba-Khachmaz',
						extent: [47.83, 40.91, 49.34, 41.88],
					},
				},
				'19_147302': {
					key: '19_147302',
					data: {
						name: 'Kalbajar-Lachin',
						extent: [45.61, 38.88, 46.87, 40.33],
					},
				},
				'19_147303': {
					key: '19_147303',
					data: {
						name: 'Lankaran',
						extent: [48.02, 38.39, 49.19, 39.46],
					},
				},
				'19_147304': {
					key: '19_147304',
					data: {
						name: 'Nakhchivan',
						extent: [44.77, 38.84, 46.15, 39.82],
					},
				},
				'19_147305': {
					key: '19_147305',
					data: {
						name: 'Shaki-Zaqatala',
						extent: [46.25, 40.67, 48.04, 41.91],
					},
				},
				'19_147306': {
					key: '19_147306',
					data: {
						name: 'Yukhari Garabakh',
						extent: [46.43, 39.14, 47.64, 40.51],
					},
				},
				'19_147307': {
					key: '19_147307',
					data: {
						name: 'Administrative unit not available',
						extent: [48.53, 38.26, 51.68, 42.71],
					},
				},
			},
		},
	},
	26: {
		key: 26,
		data: {
			name: 'Belarus',
			extent: [23.18, 51.26, 32.77, 56.17],
			regionsByFeatureKey: {
				'26_593': {
					key: '26_593',
					data: {
						name: 'Brest',
						extent: [23.18, 51.5, 27.6, 53.4],
					},
				},
				'26_594': {
					key: '26_594',
					data: {
						name: 'Gomel',
						extent: [27.27, 51.26, 31.8, 53.36],
					},
				},
				'26_595': {
					key: '26_595',
					data: {
						name: 'Grodno',
						extent: [23.51, 52.74, 26.7, 54.98],
					},
				},
				'26_596': {
					key: '26_596',
					data: {
						name: 'Minsk',
						extent: [26.07, 52.36, 29.48, 55.0],
					},
				},
				'26_597': {
					key: '26_597',
					data: {
						name: 'Minsk City',
						extent: [27.5, 53.85, 27.7, 53.96],
					},
				},
				'26_598': {
					key: '26_598',
					data: {
						name: 'Mogilev',
						extent: [28.08, 52.68, 32.77, 54.47],
					},
				},
				'26_599': {
					key: '26_599',
					data: {
						name: 'Vitebsk',
						extent: [26.15, 54.22, 31.22, 56.17],
					},
				},
			},
		},
	},
	27: {
		key: 27,
		data: {
			name: 'Belgium',
			extent: [2.55, 49.49, 6.4, 51.51],
			regionsByFeatureKey: {
				'27_600': {
					key: '27_600',
					data: {
						name: 'Region de Bruxelles-Capitale / Brussels Hoofdstedelijk Gewes',
						extent: [4.24, 50.76, 4.48, 50.91],
					},
				},
				'27_601': {
					key: '27_601',
					data: {
						name: 'Vlaams Gewest',
						extent: [2.55, 50.69, 5.85, 51.51],
					},
				},
				'27_602': {
					key: '27_602',
					data: {
						name: 'Region wallonne',
						extent: [2.84, 49.49, 6.4, 50.85],
					},
				},
			},
		},
	},
	34: {
		key: 34,
		data: {
			name: 'Bosnia and Herzegovina',
			extent: [15.74, 42.56, 19.62, 45.28],
			regionsByFeatureKey: {
				'34_652': {
					key: '34_652',
					data: {
						name: 'Federacija Bosne I Hercegovine',
						extent: [15.74, 42.61, 19.04, 45.23],
					},
				},
				'34_653': {
					key: '34_653',
					data: {
						name: 'Republika Srpska',
						extent: [16.2, 42.56, 19.62, 45.28],
					},
				},
			},
		},
	},
	41: {
		key: 41,
		data: {
			name: 'Bulgaria',
			extent: [22.36, 41.24, 28.61, 44.22],
			regionsByFeatureKey: {
				'41_702': {
					key: '41_702',
					data: {
						name: 'Blagoevgrad',
						extent: [22.86, 41.32, 24.12, 42.18],
					},
				},
				'41_703': {
					key: '41_703',
					data: {
						name: 'Burgas',
						extent: [26.58, 41.91, 28.04, 42.99],
					},
				},
				'41_704': {
					key: '41_704',
					data: {
						name: 'Dobrich',
						extent: [27.23, 43.31, 28.61, 44.01],
					},
				},
				'41_705': {
					key: '41_705',
					data: {
						name: 'Gabrovo',
						extent: [24.87, 42.72, 25.68, 43.22],
					},
				},
				'41_706': {
					key: '41_706',
					data: {
						name: 'Haskovo',
						extent: [25.27, 41.32, 26.59, 42.23],
					},
				},
				'41_707': {
					key: '41_707',
					data: {
						name: 'Jambol',
						extent: [26.18, 41.94, 27.05, 42.72],
					},
				},
				'41_708': {
					key: '41_708',
					data: {
						name: 'Kardzhali',
						extent: [25.01, 41.24, 25.96, 41.89],
					},
				},
				'41_709': {
					key: '41_709',
					data: {
						name: 'Kustendil',
						extent: [22.36, 42.02, 23.53, 42.64],
					},
				},
				'41_710': {
					key: '41_710',
					data: {
						name: 'Lovech',
						extent: [23.98, 42.7, 25.17, 43.39],
					},
				},
				'41_711': {
					key: '41_711',
					data: {
						name: 'Montana',
						extent: [22.75, 43.11, 23.64, 43.86],
					},
				},
				'41_712': {
					key: '41_712',
					data: {
						name: 'Pazardzhik',
						extent: [23.77, 41.67, 24.53, 42.62],
					},
				},
				'41_713': {
					key: '41_713',
					data: {
						name: 'Pernik',
						extent: [22.44, 42.36, 23.3, 42.9],
					},
				},
				'41_714': {
					key: '41_714',
					data: {
						name: 'Pleven',
						extent: [23.97, 43.21, 25.27, 43.77],
					},
				},
				'41_715': {
					key: '41_715',
					data: {
						name: 'Plovdiv',
						extent: [24.35, 41.67, 25.37, 42.79],
					},
				},
				'41_716': {
					key: '41_716',
					data: {
						name: 'Razgrad',
						extent: [26.06, 43.27, 27.04, 43.95],
					},
				},
				'41_717': {
					key: '41_717',
					data: {
						name: 'Ruse',
						extent: [25.47, 43.32, 26.57, 44.04],
					},
				},
				'41_718': {
					key: '41_718',
					data: {
						name: 'Shumen',
						extent: [26.54, 42.91, 27.45, 43.74],
					},
				},
				'41_719': {
					key: '41_719',
					data: {
						name: 'Silistra',
						extent: [26.34, 43.68, 27.7, 44.15],
					},
				},
				'41_720': {
					key: '41_720',
					data: {
						name: 'Sliven',
						extent: [25.84, 42.24, 26.72, 43.05],
					},
				},
				'41_721': {
					key: '41_721',
					data: {
						name: 'Smoljan',
						extent: [24.07, 41.33, 25.2, 41.94],
					},
				},
				'41_722': {
					key: '41_722',
					data: {
						name: 'Sofia',
						extent: [22.76, 42.09, 24.49, 43.21],
					},
				},
				'41_723': {
					key: '41_723',
					data: {
						name: 'Sofia-city',
						extent: [23.06, 42.42, 23.66, 42.89],
					},
				},
				'41_724': {
					key: '41_724',
					data: {
						name: 'Stara Zagora',
						extent: [25.02, 42.01, 26.2, 42.78],
					},
				},
				'41_725': {
					key: '41_725',
					data: {
						name: 'Targovishte',
						extent: [25.96, 42.95, 26.79, 43.57],
					},
				},
				'41_726': {
					key: '41_726',
					data: {
						name: 'Varna',
						extent: [27.1, 42.83, 28.06, 43.61],
					},
				},
				'41_727': {
					key: '41_727',
					data: {
						name: 'Veliko Tarnovo',
						extent: [25.1, 42.75, 26.2, 43.7],
					},
				},
				'41_728': {
					key: '41_728',
					data: {
						name: 'Vidin',
						extent: [22.36, 43.39, 23.06, 44.22],
					},
				},
				'41_729': {
					key: '41_729',
					data: {
						name: 'Vratca',
						extent: [23.34, 42.99, 24.32, 43.81],
					},
				},
			},
		},
	},
	62: {
		key: 62,
		data: {
			name: 'Croatia',
			extent: [13.49, 42.39, 19.45, 46.54],
			regionsByFeatureKey: {
				'62_988': {
					key: '62_988',
					data: {
						name: 'Bjelovar-bilogora',
						extent: [16.5, 45.47, 17.5, 46.09],
					},
				},
				'62_989': {
					key: '62_989',
					data: {
						name: 'Dubrovnik-neretva',
						extent: [16.25, 42.39, 18.53, 43.18],
					},
				},
				'62_990': {
					key: '62_990',
					data: {
						name: 'Grad Zagreb',
						extent: [15.77, 45.62, 16.23, 45.97],
					},
				},
				'62_991': {
					key: '62_991',
					data: {
						name: 'Istra',
						extent: [13.49, 44.77, 14.23, 45.53],
					},
				},
				'62_992': {
					key: '62_992',
					data: {
						name: 'Karlovac',
						extent: [14.96, 44.88, 15.92, 45.76],
					},
				},
				'62_993': {
					key: '62_993',
					data: {
						name: 'Koprivnica-krizevci',
						extent: [16.34, 45.88, 17.32, 46.35],
					},
				},
				'62_994': {
					key: '62_994',
					data: {
						name: 'Krapina-zagorje',
						extent: [15.6, 45.91, 16.26, 46.25],
					},
				},
				'62_995': {
					key: '62_995',
					data: {
						name: 'Lika-senj',
						extent: [14.84, 44.27, 16.12, 45.12],
					},
				},
				'62_996': {
					key: '62_996',
					data: {
						name: 'Medimurje',
						extent: [16.28, 46.29, 16.87, 46.54],
					},
				},
				'62_997': {
					key: '62_997',
					data: {
						name: 'Osijek-baranja',
						extent: [17.9, 45.19, 19.1, 45.92],
					},
				},
				'62_998': {
					key: '62_998',
					data: {
						name: 'Pozega-slavonija',
						extent: [16.94, 45.19, 18.12, 45.58],
					},
				},
				'62_999': {
					key: '62_999',
					data: {
						name: 'Primorje-gorski Kota',
						extent: [14.11, 44.44, 15.23, 45.66],
					},
				},
				'62_1000': {
					key: '62_1000',
					data: {
						name: 'Sibenik',
						extent: [15.22, 43.49, 16.57, 44.22],
					},
				},
				'62_1001': {
					key: '62_1001',
					data: {
						name: 'Sisak-moslavina',
						extent: [15.81, 45.0, 17.19, 45.67],
					},
				},
				'62_1002': {
					key: '62_1002',
					data: {
						name: 'Slavonski Brod-posav',
						extent: [17.07, 45.04, 18.55, 45.39],
					},
				},
				'62_1003': {
					key: '62_1003',
					data: {
						name: 'Split-dalmatija',
						extent: [15.45, 42.96, 17.46, 43.96],
					},
				},
				'62_1004': {
					key: '62_1004',
					data: {
						name: 'Varazdin',
						extent: [15.88, 46.01, 16.77, 46.4],
					},
				},
				'62_1005': {
					key: '62_1005',
					data: {
						name: 'Virovitica-podravina',
						extent: [17.12, 45.48, 18.05, 45.99],
					},
				},
				'62_1006': {
					key: '62_1006',
					data: {
						name: 'Vukovar-srijem',
						extent: [18.5, 44.85, 19.45, 45.49],
					},
				},
				'62_1007': {
					key: '62_1007',
					data: {
						name: 'Zadar-knin',
						extent: [14.58, 43.83, 16.22, 44.7],
					},
				},
				'62_1008': {
					key: '62_1008',
					data: {
						name: 'Zagreb',
						extent: [15.34, 45.42, 16.7, 46.07],
					},
				},
			},
		},
	},
	64: {
		key: 64,
		data: {
			name: 'Cyprus',
			extent: [32.27, 34.56, 34.61, 35.71],
			regionsByFeatureKey: {
				'64_75401': {
					key: '64_75401',
					data: {
						name: 'Girne (Kyrenia)',
						extent: [32.92, 35.23, 33.67, 35.41],
					},
				},
				'64_75403': {
					key: '64_75403',
					data: {
						name: 'Famagusta',
						extent: [33.54, 34.96, 34.61, 35.71],
					},
				},
				'64_75404': {
					key: '64_75404',
					data: {
						name: 'Larnaca',
						extent: [33.13, 34.71, 33.72, 35.11],
					},
				},
				'64_75405': {
					key: '64_75405',
					data: {
						name: 'Limassol',
						extent: [32.63, 34.63, 33.27, 34.96],
					},
				},
				'64_75406': {
					key: '64_75406',
					data: {
						name: 'Nicosia',
						extent: [32.55, 34.88, 33.59, 35.29],
					},
				},
				'64_75407': {
					key: '64_75407',
					data: {
						name: 'Paphos',
						extent: [32.27, 34.66, 32.79, 35.17],
					},
				},
				'64_75408': {
					key: '64_75408',
					data: {
						name: 'Sovereign Base Areas of Akrotiri and Dhekelia (U.K.)',
						extent: [32.75, 34.56, 33.92, 35.12],
					},
				},
			},
		},
	},
	65: {
		key: 65,
		data: {
			name: 'Czech Republic',
			extent: [12.1, 48.54, 18.86, 51.06],
			regionsByFeatureKey: {
				'65_1030': {
					key: '65_1030',
					data: {
						name: 'Jihocesky',
						extent: [13.54, 48.54, 15.6, 49.63],
					},
				},
				'65_1031': {
					key: '65_1031',
					data: {
						name: 'Jihomoravsky',
						extent: [15.25, 48.62, 18.11, 49.74],
					},
				},
				'65_1032': {
					key: '65_1032',
					data: {
						name: 'Praha',
						extent: [14.12, 49.78, 14.7, 50.23],
					},
				},
				'65_1033': {
					key: '65_1033',
					data: {
						name: 'Severocesky',
						extent: [12.94, 50.08, 15.39, 51.06],
					},
				},
				'65_1034': {
					key: '65_1034',
					data: {
						name: 'Severomoravsky',
						extent: [16.71, 49.13, 18.86, 50.45],
					},
				},
				'65_1035': {
					key: '65_1035',
					data: {
						name: 'Stredocesky',
						extent: [13.4, 49.5, 15.54, 50.62],
					},
				},
				'65_1036': {
					key: '65_1036',
					data: {
						name: 'Vychodocesky',
						extent: [15.07, 49.47, 16.87, 50.82],
					},
				},
				'65_1037': {
					key: '65_1037',
					data: {
						name: 'Zapadocesky',
						extent: [12.1, 48.94, 13.83, 50.46],
					},
				},
			},
		},
	},
	69: {
		key: 69,
		data: {
			name: 'Denmark',
			extent: [8.08, 54.56, 15.19, 57.75],
			regionsByFeatureKey: {
				'69_1077': {
					key: '69_1077',
					data: {
						name: 'Aarhus',
						extent: [9.31, 55.77, 11.66, 56.74],
					},
				},
				'69_1078': {
					key: '69_1078',
					data: {
						name: 'Bornholm',
						extent: [14.68, 54.98, 15.19, 55.32],
					},
				},
				'69_1079': {
					key: '69_1079',
					data: {
						name: 'Frederiksberg',
						extent: [12.5, 55.66, 12.56, 55.69],
					},
				},
				'69_1081': {
					key: '69_1081',
					data: {
						name: 'Fyn',
						extent: [9.68, 54.72, 10.96, 55.65],
					},
				},
				'69_1083': {
					key: '69_1083',
					data: {
						name: 'Nordjylland',
						extent: [9.03, 56.55, 11.21, 57.75],
					},
				},
				'69_1084': {
					key: '69_1084',
					data: {
						name: 'Ribe',
						extent: [8.08, 55.22, 9.3, 55.89],
					},
				},
				'69_1085': {
					key: '69_1085',
					data: {
						name: 'Ringkoebing',
						extent: [8.11, 55.77, 9.42, 56.7],
					},
				},
				'69_1087': {
					key: '69_1087',
					data: {
						name: 'Soenderjylland',
						extent: [8.47, 54.8, 10.07, 55.45],
					},
				},
				'69_1088': {
					key: '69_1088',
					data: {
						name: 'Storstroem',
						extent: [10.96, 54.56, 12.56, 55.4],
					},
				},
				'69_1090': {
					key: '69_1090',
					data: {
						name: 'Vejle',
						extent: [9.05, 55.37, 10.33, 56.07],
					},
				},
				'69_1091': {
					key: '69_1091',
					data: {
						name: 'Vestsjaelland',
						extent: [10.88, 55.14, 12.07, 56.0],
					},
				},
				'69_1092': {
					key: '69_1092',
					data: {
						name: 'Viborg',
						extent: [8.23, 56.2, 9.86, 57.15],
					},
				},
				'69_40856': {
					key: '69_40856',
					data: {
						name: 'Frederiksborg',
						extent: [11.71, 55.72, 12.64, 56.19],
					},
				},
				'69_40857': {
					key: '69_40857',
					data: {
						name: 'Koebenhavn',
						extent: [12.15, 55.56, 12.79, 55.86],
					},
				},
				'69_40858': {
					key: '69_40858',
					data: {
						name: 'Roskilde',
						extent: [11.78, 55.33, 12.36, 55.78],
					},
				},
			},
		},
	},
	78: {
		key: 78,
		data: {
			name: 'Estonia',
			extent: [21.76, 57.52, 28.21, 59.7],
			regionsByFeatureKey: {
				'78_1212': {
					key: '78_1212',
					data: {
						name: 'Harju maakond',
						extent: [23.73, 58.99, 25.96, 59.7],
					},
				},
				'78_1213': {
					key: '78_1213',
					data: {
						name: 'Hiiu maakond',
						extent: [22.04, 58.69, 23.19, 59.09],
					},
				},
				'78_1214': {
					key: '78_1214',
					data: {
						name: 'Ida-Viru maakond',
						extent: [26.7, 58.87, 28.21, 59.5],
					},
				},
				'78_1215': {
					key: '78_1215',
					data: {
						name: 'J\u00e4rva maakond',
						extent: [25.2, 58.61, 26.17, 59.27],
					},
				},
				'78_1216': {
					key: '78_1216',
					data: {
						name: 'J\u00f5geva maakond',
						extent: [25.75, 58.47, 27.16, 58.97],
					},
				},
				'78_1217': {
					key: '78_1217',
					data: {
						name: 'L\u00e4\u00e4ne-Viru maakond',
						extent: [25.54, 58.88, 26.95, 59.64],
					},
				},
				'78_1218': {
					key: '78_1218',
					data: {
						name: 'L\u00e4\u00e4ne maakond',
						extent: [23.08, 58.53, 24.19, 59.3],
					},
				},
				'78_1219': {
					key: '78_1219',
					data: {
						name: 'P\u00e4rnu maakond',
						extent: [23.61, 57.87, 25.31, 58.76],
					},
				},
				'78_1220': {
					key: '78_1220',
					data: {
						name: 'P\u00f5lva maakond',
						extent: [26.57, 57.82, 27.83, 58.27],
					},
				},
				'78_1221': {
					key: '78_1221',
					data: {
						name: 'Rapla maakond',
						extent: [24.07, 58.66, 25.33, 59.23],
					},
				},
				'78_1222': {
					key: '78_1222',
					data: {
						name: 'Saare maakond',
						extent: [21.76, 57.78, 23.45, 58.68],
					},
				},
				'78_1223': {
					key: '78_1223',
					data: {
						name: 'Tartu maakond',
						extent: [26.07, 58.11, 27.53, 58.68],
					},
				},
				'78_1224': {
					key: '78_1224',
					data: {
						name: 'Valga maakond',
						extent: [25.58, 57.57, 26.62, 58.18],
					},
				},
				'78_1225': {
					key: '78_1225',
					data: {
						name: 'Viljandi maakond',
						extent: [24.97, 57.95, 26.19, 58.69],
					},
				},
				'78_1226': {
					key: '78_1226',
					data: {
						name: 'V\u00f5ru maakond',
						extent: [26.37, 57.52, 27.56, 57.96],
					},
				},
				'78_149727': {
					key: '78_149727',
					data: {
						name: 'Administrative unit not available',
						extent: [26.95, 57.96, 27.73, 59.01],
					},
				},
			},
		},
	},
	84: {
		key: 84,
		data: {
			name: 'Finland',
			extent: [19.32, 59.77, 31.58, 70.1],
			regionsByFeatureKey: {
				'84_1242': {
					key: '84_1242',
					data: {
						name: 'Aaland',
						extent: [19.32, 59.91, 21.12, 60.66],
					},
				},
				'84_1243': {
					key: '84_1243',
					data: {
						name: 'Eastern Finland',
						extent: [26.05, 61.15, 31.58, 64.04],
					},
				},
				'84_1244': {
					key: '84_1244',
					data: {
						name: 'Lapland',
						extent: [20.56, 65.55, 30.03, 70.1],
					},
				},
				'84_1245': {
					key: '84_1245',
					data: {
						name: 'Oulu',
						extent: [23.61, 63.43, 30.55, 66.49],
					},
				},
				'84_1246': {
					key: '84_1246',
					data: {
						name: 'Southern Finland',
						extent: [22.82, 59.77, 30.15, 61.86],
					},
				},
				'84_1247': {
					key: '84_1247',
					data: {
						name: 'Western Finland',
						extent: [20.66, 59.8, 26.78, 64.19],
					},
				},
			},
		},
	},
	85: {
		key: 85,
		data: {
			name: 'France',
			extent: [-5.14, 41.34, 9.56, 51.09],
			regionsByFeatureKey: {
				'85_1248': {
					key: '85_1248',
					data: {
						name: 'Alsace',
						extent: [6.84, 47.42, 8.24, 49.08],
					},
				},
				'85_1249': {
					key: '85_1249',
					data: {
						name: 'Aquitaine',
						extent: [-1.79, 42.79, 1.44, 45.71],
					},
				},
				'85_1250': {
					key: '85_1250',
					data: {
						name: 'Auvergne',
						extent: [2.06, 44.62, 4.48, 46.81],
					},
				},
				'85_1251': {
					key: '85_1251',
					data: {
						name: 'Basse-Normandie',
						extent: [-1.95, 48.18, 0.97, 49.73],
					},
				},
				'85_1252': {
					key: '85_1252',
					data: {
						name: 'Bourgogne',
						extent: [2.84, 46.16, 5.51, 48.4],
					},
				},
				'85_1253': {
					key: '85_1253',
					data: {
						name: 'Bretagne',
						extent: [-5.14, 47.28, -1.02, 48.89],
					},
				},
				'85_1254': {
					key: '85_1254',
					data: {
						name: 'Centre',
						extent: [0.05, 46.35, 3.12, 48.94],
					},
				},
				'85_1255': {
					key: '85_1255',
					data: {
						name: 'Champagne-Ardenne',
						extent: [3.38, 47.58, 5.89, 50.17],
					},
				},
				'85_1256': {
					key: '85_1256',
					data: {
						name: 'Corse',
						extent: [8.54, 41.34, 9.56, 43.02],
					},
				},
				'85_1257': {
					key: '85_1257',
					data: {
						name: 'Franche-Comte',
						extent: [5.25, 46.27, 7.14, 48.03],
					},
				},
				'85_1258': {
					key: '85_1258',
					data: {
						name: 'Haute-Normandie',
						extent: [0.07, 48.67, 1.8, 50.07],
					},
				},
				'85_1259': {
					key: '85_1259',
					data: {
						name: 'Ile-de-France',
						extent: [1.44, 48.12, 3.55, 49.24],
					},
				},
				'85_1260': {
					key: '85_1260',
					data: {
						name: 'Languedoc-Rousillon',
						extent: [1.69, 42.34, 4.84, 44.98],
					},
				},
				'85_1262': {
					key: '85_1262',
					data: {
						name: 'Limousin',
						extent: [0.62, 44.92, 2.6, 46.46],
					},
				},
				'85_1263': {
					key: '85_1263',
					data: {
						name: 'Lorraine',
						extent: [4.89, 47.82, 7.64, 49.62],
					},
				},
				'85_1264': {
					key: '85_1264',
					data: {
						name: 'Midi-Pyrenees',
						extent: [-0.33, 42.57, 3.45, 45.05],
					},
				},
				'85_1265': {
					key: '85_1265',
					data: {
						name: 'Nord-Pas-de-Calais',
						extent: [1.56, 49.97, 4.23, 51.09],
					},
				},
				'85_1266': {
					key: '85_1266',
					data: {
						name: 'Pays-de-la-Loire',
						extent: [-2.55, 46.27, 0.91, 48.57],
					},
				},
				'85_1267': {
					key: '85_1267',
					data: {
						name: 'Picardie',
						extent: [1.38, 48.84, 4.26, 50.37],
					},
				},
				'85_1268': {
					key: '85_1268',
					data: {
						name: 'Poitou-Charentes',
						extent: [-1.56, 45.09, 1.21, 47.17],
					},
				},
				'85_1269': {
					key: '85_1269',
					data: {
						name: "Provence-Alpes-Cote-d'Azur",
						extent: [4.23, 42.99, 7.71, 45.13],
					},
				},
				'85_1270': {
					key: '85_1270',
					data: {
						name: 'Rhone-Alpes',
						extent: [3.69, 44.12, 7.18, 46.52],
					},
				},
			},
		},
	},
	241: {
		key: 241,
		data: {
			name: 'North Macedonia',
			extent: [20.45, 40.85, 23.03, 42.37],
			regionsByFeatureKey: {
				'241_2927': {
					key: '241_2927',
					data: {
						name: 'Berovo',
						extent: [22.64, 41.52, 23.03, 41.88],
					},
				},
				'241_2928': {
					key: '241_2928',
					data: {
						name: 'Bitola',
						extent: [21.07, 40.86, 21.91, 41.28],
					},
				},
				'241_2929': {
					key: '241_2929',
					data: {
						name: 'Brod',
						extent: [21.04, 41.42, 21.39, 41.86],
					},
				},
				'241_2930': {
					key: '241_2930',
					data: {
						name: 'Debar',
						extent: [20.45, 41.41, 20.68, 41.58],
					},
				},
				'241_2931': {
					key: '241_2931',
					data: {
						name: 'Delcevo',
						extent: [22.61, 41.8, 22.92, 42.07],
					},
				},
				'241_2932': {
					key: '241_2932',
					data: {
						name: 'Demir Hisar',
						extent: [20.91, 41.12, 21.29, 41.44],
					},
				},
				'241_2933': {
					key: '241_2933',
					data: {
						name: 'Gevgelija',
						extent: [22.21, 41.12, 22.77, 41.39],
					},
				},
				'241_2934': {
					key: '241_2934',
					data: {
						name: 'Gostivar',
						extent: [20.51, 41.44, 21.1, 41.94],
					},
				},
				'241_2935': {
					key: '241_2935',
					data: {
						name: 'Kavadarci',
						extent: [21.78, 41.11, 22.31, 41.57],
					},
				},
				'241_2936': {
					key: '241_2936',
					data: {
						name: 'Kicevo',
						extent: [20.68, 41.39, 21.14, 41.67],
					},
				},
				'241_2937': {
					key: '241_2937',
					data: {
						name: 'Kocani',
						extent: [22.21, 41.8, 22.66, 42.16],
					},
				},
				'241_2938': {
					key: '241_2938',
					data: {
						name: 'Kratovo',
						extent: [21.93, 42.01, 22.39, 42.19],
					},
				},
				'241_2939': {
					key: '241_2939',
					data: {
						name: 'Kriva Palanka',
						extent: [22.04, 42.11, 22.51, 42.37],
					},
				},
				'241_2940': {
					key: '241_2940',
					data: {
						name: 'Krusevo',
						extent: [21.13, 41.26, 21.34, 41.48],
					},
				},
				'241_2941': {
					key: '241_2941',
					data: {
						name: 'Kumanovo',
						extent: [21.46, 41.96, 22.07, 42.34],
					},
				},
				'241_2942': {
					key: '241_2942',
					data: {
						name: 'Negotino',
						extent: [21.99, 41.29, 22.36, 41.67],
					},
				},
				'241_2943': {
					key: '241_2943',
					data: {
						name: 'Ohrid',
						extent: [20.67, 40.9, 21.04, 41.45],
					},
				},
				'241_2944': {
					key: '241_2944',
					data: {
						name: 'Prilep',
						extent: [21.26, 41.08, 21.94, 41.6],
					},
				},
				'241_2945': {
					key: '241_2945',
					data: {
						name: 'Probistip',
						extent: [22.03, 41.86, 22.37, 42.07],
					},
				},
				'241_2946': {
					key: '241_2946',
					data: {
						name: 'Radovic',
						extent: [22.28, 41.43, 22.66, 41.79],
					},
				},
				'241_2947': {
					key: '241_2947',
					data: {
						name: 'Resen',
						extent: [20.83, 40.85, 21.25, 41.22],
					},
				},
				'241_2948': {
					key: '241_2948',
					data: {
						name: 'Skopje',
						extent: [21.16, 41.71, 21.83, 42.28],
					},
				},
				'241_2949': {
					key: '241_2949',
					data: {
						name: 'Stip',
						extent: [21.95, 41.55, 22.47, 41.89],
					},
				},
				'241_2950': {
					key: '241_2950',
					data: {
						name: 'Struga',
						extent: [20.49, 41.08, 20.77, 41.44],
					},
				},
				'241_2951': {
					key: '241_2951',
					data: {
						name: 'Strumica',
						extent: [22.46, 41.32, 22.98, 41.65],
					},
				},
				'241_2952': {
					key: '241_2952',
					data: {
						name: 'Sveti Nikole',
						extent: [21.81, 41.66, 22.12, 42.02],
					},
				},
				'241_2953': {
					key: '241_2953',
					data: {
						name: 'Tetovo',
						extent: [20.75, 41.8, 21.25, 42.21],
					},
				},
				'241_2954': {
					key: '241_2954',
					data: {
						name: 'Valandovo',
						extent: [22.34, 41.22, 22.78, 41.46],
					},
				},
				'241_2955': {
					key: '241_2955',
					data: {
						name: 'Veles',
						extent: [21.37, 41.44, 22.05, 41.92],
					},
				},
				'241_2956': {
					key: '241_2956',
					data: {
						name: 'Vinica',
						extent: [22.45, 41.74, 22.74, 42.0],
					},
				},
			},
		},
	},
	92: {
		key: 92,
		data: {
			name: 'Georgia',
			extent: [40.01, 41.05, 46.72, 43.58],
			regionsByFeatureKey: {
				'92_1296': {
					key: '92_1296',
					data: {
						name: 'Abkhazia Aut. Rep.',
						extent: [40.01, 42.41, 42.15, 43.58],
					},
				},
				'92_1297': {
					key: '92_1297',
					data: {
						name: 'Adjara Aut. Rep.',
						extent: [41.55, 41.43, 42.61, 41.9],
					},
				},
				'92_1298': {
					key: '92_1298',
					data: {
						name: 'Guria',
						extent: [41.7, 41.79, 42.67, 42.17],
					},
				},
				'92_1299': {
					key: '92_1299',
					data: {
						name: 'Imereti',
						extent: [42.22, 41.81, 43.8, 42.56],
					},
				},
				'92_1300': {
					key: '92_1300',
					data: {
						name: 'Kakheti',
						extent: [45.03, 41.05, 46.72, 42.56],
					},
				},
				'92_1301': {
					key: '92_1301',
					data: {
						name: 'Kvemo Kartli',
						extent: [43.67, 41.16, 45.32, 41.87],
					},
				},
				'92_1302': {
					key: '92_1302',
					data: {
						name: 'Mtskheta-Mtianeti',
						extent: [44.23, 41.69, 45.3, 42.75],
					},
				},
				'92_1303': {
					key: '92_1303',
					data: {
						name: 'Racha-Lechkhumi and Kvemo (lower) Svaneti',
						extent: [42.36, 42.36, 43.93, 42.95],
					},
				},
				'92_1304': {
					key: '92_1304',
					data: {
						name: 'Samergelo and Zemo (upper) Svaneti',
						extent: [41.54, 42.09, 43.17, 43.25],
					},
				},
				'92_1305': {
					key: '92_1305',
					data: {
						name: 'Samtskhe-Javakheti',
						extent: [42.49, 41.11, 43.97, 41.95],
					},
				},
				'92_1306': {
					key: '92_1306',
					data: {
						name: 'Shida Kartli',
						extent: [43.43, 41.73, 44.57, 42.65],
					},
				},
				'92_1307': {
					key: '92_1307',
					data: {
						name: 'Tbilisi',
						extent: [44.67, 41.64, 45.0, 41.84],
					},
				},
			},
		},
	},
	93: {
		key: 93,
		data: {
			name: 'Germany',
			extent: [5.87, 47.28, 15.04, 55.06],
			regionsByFeatureKey: {
				'93_1308': {
					key: '93_1308',
					data: {
						name: 'Baden-Wuerttemberg',
						extent: [7.51, 47.54, 10.48, 49.79],
					},
				},
				'93_1309': {
					key: '93_1309',
					data: {
						name: 'Bayern',
						extent: [8.98, 47.28, 13.84, 50.57],
					},
				},
				'93_1310': {
					key: '93_1310',
					data: {
						name: 'Berlin',
						extent: [13.09, 52.35, 13.73, 52.67],
					},
				},
				'93_1311': {
					key: '93_1311',
					data: {
						name: 'Brandenburg',
						extent: [11.5, 51.36, 14.76, 53.43],
					},
				},
				'93_1312': {
					key: '93_1312',
					data: {
						name: 'Bremen',
						extent: [8.5, 53.0, 8.97, 53.6],
					},
				},
				'93_1313': {
					key: '93_1313',
					data: {
						name: 'Hamburg',
						extent: [9.72, 53.39, 10.3, 53.73],
					},
				},
				'93_1314': {
					key: '93_1314',
					data: {
						name: 'Hessen',
						extent: [7.77, 49.4, 10.24, 51.65],
					},
				},
				'93_1315': {
					key: '93_1315',
					data: {
						name: 'Mecklenburg-Vorpommern',
						extent: [10.6, 53.03, 14.41, 54.68],
					},
				},
				'93_1316': {
					key: '93_1316',
					data: {
						name: 'Niedersachsen',
						extent: [6.66, 51.29, 11.59, 53.97],
					},
				},
				'93_1317': {
					key: '93_1317',
					data: {
						name: 'Nordrhein-Westfalen',
						extent: [5.87, 50.32, 9.45, 52.53],
					},
				},
				'93_1318': {
					key: '93_1318',
					data: {
						name: 'Rheinland-Pfalz',
						extent: [6.12, 48.97, 8.5, 50.94],
					},
				},
				'93_1319': {
					key: '93_1319',
					data: {
						name: 'Saarland',
						extent: [6.36, 49.11, 7.4, 49.64],
					},
				},
				'93_1320': {
					key: '93_1320',
					data: {
						name: 'Sachsen',
						extent: [11.85, 50.17, 15.04, 51.68],
					},
				},
				'93_1321': {
					key: '93_1321',
					data: {
						name: 'Sachsen-Anhalt',
						extent: [10.57, 50.96, 13.17, 53.04],
					},
				},
				'93_1322': {
					key: '93_1322',
					data: {
						name: 'Schleswig-Holstein',
						extent: [7.87, 53.37, 11.32, 55.06],
					},
				},
				'93_1323': {
					key: '93_1323',
					data: {
						name: 'Thueringen',
						extent: [9.89, 50.22, 12.64, 51.65],
					},
				},
			},
		},
	},
	97: {
		key: 97,
		data: {
			name: 'Greece',
			extent: [19.37, 34.81, 29.65, 41.76],
			regionsByFeatureKey: {
				'97_1336': {
					key: '97_1336',
					data: {
						name: 'Anatoliki Makedonia Kai Thraki',
						extent: [23.62, 40.4, 26.64, 41.76],
					},
				},
				'97_1337': {
					key: '97_1337',
					data: {
						name: 'Attiki',
						extent: [22.89, 35.83, 24.09, 38.34],
					},
				},
				'97_1338': {
					key: '97_1338',
					data: {
						name: 'Dytiki Ellada',
						extent: [20.73, 37.37, 22.37, 39.15],
					},
				},
				'97_1339': {
					key: '97_1339',
					data: {
						name: 'Dytiki Makedonia',
						extent: [20.73, 39.8, 22.17, 40.94],
					},
				},
				'97_1340': {
					key: '97_1340',
					data: {
						name: 'Ionioi Nisoi',
						extent: [19.37, 37.24, 21.01, 39.91],
					},
				},
				'97_1341': {
					key: '97_1341',
					data: {
						name: 'Ipeiros',
						extent: [20.01, 38.95, 21.39, 40.33],
					},
				},
				'97_1342': {
					key: '97_1342',
					data: {
						name: 'Kentriki Makedonia',
						extent: [21.69, 39.91, 24.41, 41.41],
					},
				},
				'97_1343': {
					key: '97_1343',
					data: {
						name: 'Kriti',
						extent: [23.52, 34.81, 26.35, 35.69],
					},
				},
				'97_1345': {
					key: '97_1345',
					data: {
						name: 'Peloponnisos',
						extent: [21.54, 36.39, 23.44, 38.16],
					},
				},
				'97_1346': {
					key: '97_1346',
					data: {
						name: 'Sterea Ellada',
						extent: [21.36, 37.93, 24.68, 39.24],
					},
				},
				'97_1347': {
					key: '97_1347',
					data: {
						name: 'Thessalia',
						extent: [21.11, 38.96, 24.33, 40.17],
					},
				},
				'97_1348': {
					key: '97_1348',
					data: {
						name: 'Voreio Aigaio',
						extent: [24.97, 37.51, 27.07, 40.04],
					},
				},
				'97_40766': {
					key: '97_40766',
					data: {
						name: 'Notio Aigaio',
						extent: [24.1, 35.34, 29.65, 38.0],
					},
				},
			},
		},
	},
	113: {
		key: 113,
		data: {
			name: 'Hungary',
			extent: [16.11, 45.74, 22.91, 48.59],
			regionsByFeatureKey: {
				'113_1440': {
					key: '113_1440',
					data: {
						name: 'Bacs-kiskun',
						extent: [18.79, 45.91, 20.15, 47.14],
					},
				},
				'113_1441': {
					key: '113_1441',
					data: {
						name: 'Baranya',
						extent: [17.63, 45.74, 18.85, 46.4],
					},
				},
				'113_1442': {
					key: '113_1442',
					data: {
						name: 'Bekes',
						extent: [20.41, 46.25, 21.69, 47.22],
					},
				},
				'113_1443': {
					key: '113_1443',
					data: {
						name: 'Borsod-abauj-zemplen',
						extent: [20.06, 47.67, 22.16, 48.59],
					},
				},
				'113_1444': {
					key: '113_1444',
					data: {
						name: 'Budapest',
						extent: [18.94, 47.35, 19.33, 47.61],
					},
				},
				'113_1445': {
					key: '113_1445',
					data: {
						name: 'Csongrad',
						extent: [19.66, 46.12, 20.79, 46.81],
					},
				},
				'113_1446': {
					key: '113_1446',
					data: {
						name: 'Fejer',
						extent: [18.03, 46.72, 18.97, 47.6],
					},
				},
				'113_1447': {
					key: '113_1447',
					data: {
						name: 'Gyor-moson-sopron',
						extent: [16.43, 47.4, 17.94, 48.02],
					},
				},
				'113_1448': {
					key: '113_1448',
					data: {
						name: 'Hajdu-bihar',
						extent: [20.82, 46.93, 22.12, 47.94],
					},
				},
				'113_1449': {
					key: '113_1449',
					data: {
						name: 'Heves',
						extent: [19.58, 47.42, 20.77, 48.15],
					},
				},
				'113_1450': {
					key: '113_1450',
					data: {
						name: 'Jasz-nagykun-szolnok',
						extent: [19.65, 46.74, 21.02, 47.68],
					},
				},
				'113_1451': {
					key: '113_1451',
					data: {
						name: 'Komarom-esztergom',
						extent: [17.88, 47.33, 18.95, 47.82],
					},
				},
				'113_1452': {
					key: '113_1452',
					data: {
						name: 'Nograd',
						extent: [18.97, 47.71, 20.08, 48.24],
					},
				},
				'113_1453': {
					key: '113_1453',
					data: {
						name: 'Pest',
						extent: [18.71, 46.94, 20.12, 48.08],
					},
				},
				'113_1454': {
					key: '113_1454',
					data: {
						name: 'Somogy',
						extent: [16.89, 45.85, 18.21, 46.98],
					},
				},
				'113_1455': {
					key: '113_1455',
					data: {
						name: 'Szabolcs-szatmar-bereg',
						extent: [21.08, 47.58, 22.91, 48.42],
					},
				},
				'113_1456': {
					key: '113_1456',
					data: {
						name: 'Tolna',
						extent: [18.03, 46.11, 19.01, 46.85],
					},
				},
				'113_1457': {
					key: '113_1457',
					data: {
						name: 'Vas',
						extent: [16.11, 46.71, 17.3, 47.46],
					},
				},
				'113_1458': {
					key: '113_1458',
					data: {
						name: 'Veszprem',
						extent: [17.09, 46.75, 18.25, 47.5],
					},
				},
				'113_1459': {
					key: '113_1459',
					data: {
						name: 'Zala',
						extent: [16.37, 46.3, 17.44, 47.05],
					},
				},
			},
		},
	},
	114: {
		key: 114,
		data: {
			name: 'Iceland',
			extent: [-24.55, 63.29, -13.5, 66.56],
			regionsByFeatureKey: {
				'114_1460': {
					key: '114_1460',
					data: {
						name: 'Arnes',
						extent: [-21.92, 63.77, -18.33, 64.89],
					},
				},
				'114_1461': {
					key: '114_1461',
					data: {
						name: 'Austur-baroastrandar',
						extent: [-23.06, 65.35, -21.64, 65.73],
					},
				},
				'114_1462': {
					key: '114_1462',
					data: {
						name: 'Austur-hunavatns',
						extent: [-20.56, 64.74, -18.85, 66.13],
					},
				},
				'114_1463': {
					key: '114_1463',
					data: {
						name: 'Austur-skaftafells',
						extent: [-17.46, 63.78, -14.51, 64.69],
					},
				},
				'114_1464': {
					key: '114_1464',
					data: {
						name: 'Borgarfjaroar',
						extent: [-22.11, 64.3, -19.81, 64.94],
					},
				},
				'114_1465': {
					key: '114_1465',
					data: {
						name: 'Dala',
						extent: [-22.87, 64.87, -21.19, 65.51],
					},
				},
				'114_1466': {
					key: '114_1466',
					data: {
						name: 'Eyjafjaroar',
						extent: [-19.07, 65.12, -17.72, 66.2],
					},
				},
				'114_1467': {
					key: '114_1467',
					data: {
						name: 'Gullbringu',
						extent: [-22.77, 63.8, -21.83, 64.08],
					},
				},
				'114_1468': {
					key: '114_1468',
					data: {
						name: 'Kjosar',
						extent: [-22.13, 63.94, -21.16, 64.4],
					},
				},
				'114_1469': {
					key: '114_1469',
					data: {
						name: 'Myra',
						extent: [-22.53, 64.43, -20.33, 65.01],
					},
				},
				'114_1470': {
					key: '114_1470',
					data: {
						name: 'Nordhurland Eystra',
						extent: [-17.12, 66.28, -17.11, 66.28],
					},
				},
				'114_1471': {
					key: '114_1471',
					data: {
						name: 'Norour-isafjaroar',
						extent: [-23.54, 65.68, -21.97, 66.47],
					},
				},
				'114_1472': {
					key: '114_1472',
					data: {
						name: 'Norour-m\u00c3\u00b9la',
						extent: [-17.31, 64.46, -13.61, 66.17],
					},
				},
				'114_1473': {
					key: '114_1473',
					data: {
						name: 'Norour-pingeyjar',
						extent: [-17.08, 65.48, -14.54, 66.53],
					},
				},
				'114_1474': {
					key: '114_1474',
					data: {
						name: 'Rang\u00c3\u00a1rvalla',
						extent: [-20.79, 63.29, -17.31, 64.79],
					},
				},
				'114_1475': {
					key: '114_1475',
					data: {
						name: 'Skagafjaroar',
						extent: [-20.22, 64.77, -18.01, 66.15],
					},
				},
				'114_1476': {
					key: '114_1476',
					data: {
						name: 'Snaefellsnes- Og Hna',
						extent: [-24.06, 64.67, -21.9, 65.19],
					},
				},
				'114_1477': {
					key: '114_1477',
					data: {
						name: 'Stranda',
						extent: [-22.16, 64.96, -20.99, 66.27],
					},
				},
				'114_1478': {
					key: '114_1478',
					data: {
						name: 'Suour-m\u00c3\u00b9la',
						extent: [-15.15, 64.43, -13.5, 65.29],
					},
				},
				'114_1479': {
					key: '114_1479',
					data: {
						name: 'Suour-pingeyjar',
						extent: [-18.46, 64.49, -16.01, 66.56],
					},
				},
				'114_1480': {
					key: '114_1480',
					data: {
						name: 'Vestur-baroastrandar',
						extent: [-24.55, 65.4, -22.69, 65.8],
					},
				},
				'114_1481': {
					key: '114_1481',
					data: {
						name: 'Vestur-hunavatns',
						extent: [-21.12, 64.91, -19.94, 65.69],
					},
				},
				'114_1482': {
					key: '114_1482',
					data: {
						name: 'Vestur-isafjaroar',
						extent: [-23.88, 65.69, -22.97, 66.17],
					},
				},
				'114_1483': {
					key: '114_1483',
					data: {
						name: 'Vestur-skaftafells',
						extent: [-19.43, 63.39, -17.31, 64.49],
					},
				},
			},
		},
	},
	119: {
		key: 119,
		data: {
			name: 'Ireland',
			extent: [-10.62, 51.42, -6.0, 55.44],
			regionsByFeatureKey: {
				'119_1582': {
					key: '119_1582',
					data: {
						name: 'Carlow',
						extent: [-7.11, 52.46, -6.51, 52.91],
					},
				},
				'119_1583': {
					key: '119_1583',
					data: {
						name: 'Cavan',
						extent: [-8.06, 53.77, -6.77, 54.3],
					},
				},
				'119_1584': {
					key: '119_1584',
					data: {
						name: 'Clare',
						extent: [-9.94, 52.56, -8.29, 53.17],
					},
				},
				'119_1585': {
					key: '119_1585',
					data: {
						name: 'Cork',
						extent: [-10.24, 51.42, -7.85, 52.39],
					},
				},
				'119_1586': {
					key: '119_1586',
					data: {
						name: 'Donegal',
						extent: [-8.8, 54.47, -6.92, 55.44],
					},
				},
				'119_1587': {
					key: '119_1587',
					data: {
						name: 'Dublin',
						extent: [-6.55, 53.17, -6.0, 53.63],
					},
				},
				'119_1588': {
					key: '119_1588',
					data: {
						name: 'Galway',
						extent: [-10.3, 52.97, -7.97, 53.72],
					},
				},
				'119_1589': {
					key: '119_1589',
					data: {
						name: 'Kerry',
						extent: [-10.62, 51.69, -9.12, 52.59],
					},
				},
				'119_1590': {
					key: '119_1590',
					data: {
						name: 'Kildare',
						extent: [-7.17, 52.86, -6.47, 53.45],
					},
				},
				'119_1591': {
					key: '119_1591',
					data: {
						name: 'Kilkenny',
						extent: [-7.68, 52.24, -6.92, 52.89],
					},
				},
				'119_1592': {
					key: '119_1592',
					data: {
						name: 'Laois',
						extent: [-7.74, 52.78, -6.93, 53.21],
					},
				},
				'119_1593': {
					key: '119_1593',
					data: {
						name: 'Leitrim',
						extent: [-8.42, 53.81, -7.58, 54.48],
					},
				},
				'119_1594': {
					key: '119_1594',
					data: {
						name: 'Limerick',
						extent: [-9.35, 52.27, -8.16, 52.76],
					},
				},
				'119_1595': {
					key: '119_1595',
					data: {
						name: 'Longford',
						extent: [-8.03, 53.53, -7.38, 53.94],
					},
				},
				'119_1596': {
					key: '119_1596',
					data: {
						name: 'Louth',
						extent: [-6.7, 53.7, -6.11, 54.11],
					},
				},
				'119_1597': {
					key: '119_1597',
					data: {
						name: 'Mayo',
						extent: [-10.26, 53.47, -8.58, 54.35],
					},
				},
				'119_1598': {
					key: '119_1598',
					data: {
						name: 'Meath',
						extent: [-7.35, 53.38, -6.21, 53.92],
					},
				},
				'119_1599': {
					key: '119_1599',
					data: {
						name: 'Monaghan',
						extent: [-7.33, 53.9, -6.55, 54.42],
					},
				},
				'119_1600': {
					key: '119_1600',
					data: {
						name: 'Munster',
						extent: [-9.91, 51.81, -9.9, 51.81],
					},
				},
				'119_1601': {
					key: '119_1601',
					data: {
						name: 'Offaly',
						extent: [-8.09, 52.85, -6.99, 53.43],
					},
				},
				'119_1602': {
					key: '119_1602',
					data: {
						name: 'Roscommon',
						extent: [-8.82, 53.27, -7.88, 54.12],
					},
				},
				'119_1603': {
					key: '119_1603',
					data: {
						name: 'Sligo',
						extent: [-9.14, 53.91, -8.16, 54.48],
					},
				},
				'119_1604': {
					key: '119_1604',
					data: {
						name: 'Tipperary',
						extent: [-8.48, 52.21, -7.38, 53.17],
					},
				},
				'119_1605': {
					key: '119_1605',
					data: {
						name: 'Waterford',
						extent: [-8.16, 51.94, -6.95, 52.36],
					},
				},
				'119_1606': {
					key: '119_1606',
					data: {
						name: 'Westmeath',
						extent: [-7.97, 53.32, -6.96, 53.8],
					},
				},
				'119_1607': {
					key: '119_1607',
					data: {
						name: 'Wexford',
						extent: [-7.02, 52.11, -6.14, 52.8],
					},
				},
				'119_1608': {
					key: '119_1608',
					data: {
						name: 'Wicklow',
						extent: [-6.8, 52.68, -6.0, 53.23],
					},
				},
			},
		},
	},
	122: {
		key: 122,
		data: {
			name: 'Italy',
			extent: [6.61, 35.49, 18.51, 47.1],
			regionsByFeatureKey: {
				'122_1616': {
					key: '122_1616',
					data: {
						name: 'Abruzzi',
						extent: [13.02, 41.68, 14.78, 42.9],
					},
				},
				'122_1617': {
					key: '122_1617',
					data: {
						name: 'Basilicata',
						extent: [15.34, 39.9, 16.87, 41.14],
					},
				},
				'122_1618': {
					key: '122_1618',
					data: {
						name: 'Calabria',
						extent: [15.63, 37.91, 17.21, 40.14],
					},
				},
				'122_1619': {
					key: '122_1619',
					data: {
						name: 'Campania',
						extent: [13.76, 39.99, 15.81, 41.51],
					},
				},
				'122_1620': {
					key: '122_1620',
					data: {
						name: 'Emilia-romagna',
						extent: [9.2, 43.74, 12.75, 45.14],
					},
				},
				'122_1621': {
					key: '122_1621',
					data: {
						name: 'Friuli-venezia Giulia',
						extent: [12.32, 45.58, 13.91, 46.65],
					},
				},
				'122_1622': {
					key: '122_1622',
					data: {
						name: 'Lazio',
						extent: [11.45, 40.79, 14.03, 42.84],
					},
				},
				'122_1623': {
					key: '122_1623',
					data: {
						name: 'Liguria',
						extent: [7.49, 43.79, 10.07, 44.68],
					},
				},
				'122_1624': {
					key: '122_1624',
					data: {
						name: 'Lombardia',
						extent: [8.5, 44.68, 11.43, 46.64],
					},
				},
				'122_1625': {
					key: '122_1625',
					data: {
						name: 'Marche',
						extent: [12.1, 42.69, 13.91, 43.97],
					},
				},
				'122_1626': {
					key: '122_1626',
					data: {
						name: 'Molise',
						extent: [13.94, 41.36, 15.16, 42.07],
					},
				},
				'122_1627': {
					key: '122_1627',
					data: {
						name: 'Piemonte',
						extent: [6.61, 44.06, 9.22, 46.46],
					},
				},
				'122_1628': {
					key: '122_1628',
					data: {
						name: 'Puglia',
						extent: [14.93, 39.79, 18.51, 42.23],
					},
				},
				'122_1629': {
					key: '122_1629',
					data: {
						name: 'Sardegna',
						extent: [8.13, 38.87, 9.83, 41.31],
					},
				},
				'122_1630': {
					key: '122_1630',
					data: {
						name: 'Sicilia',
						extent: [11.93, 35.49, 15.66, 38.81],
					},
				},
				'122_1631': {
					key: '122_1631',
					data: {
						name: 'Toscana',
						extent: [9.69, 42.24, 12.37, 44.47],
					},
				},
				'122_1632': {
					key: '122_1632',
					data: {
						name: 'Trentino-alto Adige',
						extent: [10.38, 45.67, 12.48, 47.1],
					},
				},
				'122_1633': {
					key: '122_1633',
					data: {
						name: 'Umbria',
						extent: [11.89, 42.37, 13.27, 43.62],
					},
				},
				'122_1634': {
					key: '122_1634',
					data: {
						name: "Valle D'aosta",
						extent: [6.79, 45.46, 7.94, 46.0],
					},
				},
				'122_1635': {
					key: '122_1635',
					data: {
						name: 'Veneto',
						extent: [10.63, 44.8, 13.1, 46.69],
					},
				},
			},
		},
	},
	75008: {
		key: 75008,
		data: {
			name: 'Kosovo',
			extent: [19.97, 41.85, 21.79, 43.27],
			regionsByFeatureKey: {
				'75008_75108': {
					key: '75008_75108',
					data: {
						name: 'Kosovski',
						extent: [20.77, 42.09, 21.51, 43.12],
					},
				},
				'75008_75109': {
					key: '75008_75109',
					data: {
						name: 'Kosovsko-mitrovatski',
						extent: [20.46, 42.62, 21.13, 43.27],
					},
				},
				'75008_75110': {
					key: '75008_75110',
					data: {
						name: 'Kosovsko-pomoravski',
						extent: [21.25, 42.23, 21.79, 42.73],
					},
				},
				'75008_75111': {
					key: '75008_75111',
					data: {
						name: 'Pecki',
						extent: [19.97, 42.25, 20.82, 42.89],
					},
				},
				'75008_75112': {
					key: '75008_75112',
					data: {
						name: 'Prizremski',
						extent: [20.48, 41.85, 20.99, 42.52],
					},
				},
			},
		},
	},
	140: {
		key: 140,
		data: {
			name: 'Latvia',
			extent: [20.97, 55.67, 28.24, 58.08],
			regionsByFeatureKey: {
				'140_1771': {
					key: '140_1771',
					data: {
						name: 'Aizkraukles',
						extent: [24.63, 56.15, 25.95, 56.83],
					},
				},
				'140_1772': {
					key: '140_1772',
					data: {
						name: 'Aluksnes',
						extent: [26.27, 57.22, 27.69, 57.64],
					},
				},
				'140_1773': {
					key: '140_1773',
					data: {
						name: 'Balvu',
						extent: [26.85, 56.78, 27.87, 57.38],
					},
				},
				'140_1774': {
					key: '140_1774',
					data: {
						name: 'Bauskas',
						extent: [23.85, 56.26, 24.73, 56.72],
					},
				},
				'140_1775': {
					key: '140_1775',
					data: {
						name: 'Cesu',
						extent: [24.79, 56.93, 26.16, 57.5],
					},
				},
				'140_1776': {
					key: '140_1776',
					data: {
						name: 'Daugavpils',
						extent: [25.82, 55.67, 26.99, 56.21],
					},
				},
				'140_1777': {
					key: '140_1777',
					data: {
						name: 'Dobeles',
						extent: [22.59, 56.31, 23.54, 56.82],
					},
				},
				'140_1778': {
					key: '140_1778',
					data: {
						name: 'Gulbenes',
						extent: [26.0, 56.95, 27.14, 57.41],
					},
				},
				'140_1779': {
					key: '140_1779',
					data: {
						name: 'Jekabpils',
						extent: [25.28, 56.05, 26.62, 56.67],
					},
				},
				'140_1780': {
					key: '140_1780',
					data: {
						name: 'Jelgavas',
						extent: [23.41, 56.32, 24.12, 56.89],
					},
				},
				'140_1781': {
					key: '140_1781',
					data: {
						name: 'Kraslavas',
						extent: [26.91, 55.78, 28.09, 56.26],
					},
				},
				'140_1782': {
					key: '140_1782',
					data: {
						name: 'Kuldigas',
						extent: [21.4, 56.49, 22.52, 57.18],
					},
				},
				'140_1783': {
					key: '140_1783',
					data: {
						name: 'Liepajas',
						extent: [20.97, 56.06, 22.01, 56.98],
					},
				},
				'140_1784': {
					key: '140_1784',
					data: {
						name: 'Limbazu',
						extent: [24.34, 57.22, 25.09, 57.99],
					},
				},
				'140_1785': {
					key: '140_1785',
					data: {
						name: 'Ludzas',
						extent: [27.24, 56.13, 28.24, 56.88],
					},
				},
				'140_1786': {
					key: '140_1786',
					data: {
						name: 'Madonas',
						extent: [25.48, 56.54, 26.99, 57.1],
					},
				},
				'140_1787': {
					key: '140_1787',
					data: {
						name: 'Ogres',
						extent: [24.43, 56.54, 25.54, 57.0],
					},
				},
				'140_1788': {
					key: '140_1788',
					data: {
						name: 'Preilu',
						extent: [26.09, 56.06, 27.14, 56.56],
					},
				},
				'140_1789': {
					key: '140_1789',
					data: {
						name: 'Rezeknes',
						extent: [26.72, 56.17, 27.71, 56.83],
					},
				},
				'140_1790': {
					key: '140_1790',
					data: {
						name: 'Rigas',
						extent: [23.48, 56.66, 25.15, 57.35],
					},
				},
				'140_1791': {
					key: '140_1791',
					data: {
						name: 'Saldus',
						extent: [21.96, 56.36, 22.93, 56.93],
					},
				},
				'140_1792': {
					key: '140_1792',
					data: {
						name: 'Talsu',
						extent: [22.1, 57.0, 23.15, 57.76],
					},
				},
				'140_1793': {
					key: '140_1793',
					data: {
						name: 'Tukuma',
						extent: [22.44, 56.69, 23.57, 57.32],
					},
				},
				'140_1794': {
					key: '140_1794',
					data: {
						name: 'Valkas',
						extent: [25.48, 57.27, 26.37, 57.92],
					},
				},
				'140_1795': {
					key: '140_1795',
					data: {
						name: 'Valmieras',
						extent: [24.83, 57.39, 25.69, 58.08],
					},
				},
				'140_1796': {
					key: '140_1796',
					data: {
						name: 'Ventspils',
						extent: [21.35, 56.93, 22.31, 57.65],
					},
				},
			},
		},
	},
	146: {
		key: 146,
		data: {
			name: 'Liechtenstein',
			extent: [9.48, 47.06, 9.63, 47.27],
			regionsByFeatureKey: {
				'146_1854': {
					key: '146_1854',
					data: {
						name: 'Balzers',
						extent: [9.48, 47.06, 9.63, 47.17],
					},
				},
				'146_1855': {
					key: '146_1855',
					data: {
						name: 'Eschen',
						extent: [9.5, 47.19, 9.58, 47.24],
					},
				},
				'146_1856': {
					key: '146_1856',
					data: {
						name: 'Gamprin',
						extent: [9.5, 47.19, 9.57, 47.24],
					},
				},
				'146_1857': {
					key: '146_1857',
					data: {
						name: 'Mauren',
						extent: [9.53, 47.2, 9.58, 47.24],
					},
				},
				'146_1859': {
					key: '146_1859',
					data: {
						name: 'Planken',
						extent: [9.53, 47.16, 9.59, 47.2],
					},
				},
				'146_1860': {
					key: '146_1860',
					data: {
						name: 'Ruggell',
						extent: [9.52, 47.23, 9.55, 47.27],
					},
				},
				'146_1861': {
					key: '146_1861',
					data: {
						name: 'Schaan',
						extent: [9.49, 47.06, 9.63, 47.2],
					},
				},
				'146_1862': {
					key: '146_1862',
					data: {
						name: 'Schellenberg',
						extent: [9.53, 47.23, 9.56, 47.26],
					},
				},
				'146_1863': {
					key: '146_1863',
					data: {
						name: 'Triesen',
						extent: [9.51, 47.06, 9.6, 47.13],
					},
				},
				'146_1864': {
					key: '146_1864',
					data: {
						name: 'Triesenberg',
						extent: [9.53, 47.08, 9.63, 47.17],
					},
				},
				'146_1865': {
					key: '146_1865',
					data: {
						name: 'Vaduz',
						extent: [9.5, 47.09, 9.61, 47.2],
					},
				},
			},
		},
	},
	147: {
		key: 147,
		data: {
			name: 'Lithuania',
			extent: [20.94, 53.9, 26.87, 56.45],
			regionsByFeatureKey: {
				'147_1866': {
					key: '147_1866',
					data: {
						name: 'Alytaus',
						extent: [23.31, 53.9, 25.01, 54.56],
					},
				},
				'147_1867': {
					key: '147_1867',
					data: {
						name: 'Kauno',
						extent: [22.65, 54.45, 24.81, 55.57],
					},
				},
				'147_1868': {
					key: '147_1868',
					data: {
						name: 'Klaipedos',
						extent: [20.94, 55.03, 22.32, 56.38],
					},
				},
				'147_1869': {
					key: '147_1869',
					data: {
						name: 'Marijampoles',
						extent: [22.59, 54.24, 23.78, 55.1],
					},
				},
				'147_1870': {
					key: '147_1870',
					data: {
						name: 'Panevezio',
						extent: [23.9, 55.4, 26.06, 56.45],
					},
				},
				'147_1871': {
					key: '147_1871',
					data: {
						name: 'Siauliu',
						extent: [22.4, 55.47, 24.19, 56.41],
					},
				},
				'147_1872': {
					key: '147_1872',
					data: {
						name: 'Taurages',
						extent: [21.81, 55.05, 23.5, 55.69],
					},
				},
				'147_1873': {
					key: '147_1873',
					data: {
						name: 'Telsiu',
						extent: [21.52, 55.6, 22.75, 56.43],
					},
				},
				'147_1874': {
					key: '147_1874',
					data: {
						name: 'Utenos',
						extent: [24.65, 55.03, 26.87, 55.94],
					},
				},
				'147_1875': {
					key: '147_1875',
					data: {
						name: 'Vilniaus',
						extent: [24.39, 54.13, 26.74, 55.52],
					},
				},
			},
		},
	},
	148: {
		key: 148,
		data: {
			name: 'Luxembourg',
			extent: [5.73, 49.45, 6.53, 50.18],
			regionsByFeatureKey: {
				'148_1876': {
					key: '148_1876',
					data: {
						name: 'Diekirch',
						extent: [5.73, 49.71, 6.32, 50.18],
					},
				},
				'148_1877': {
					key: '148_1877',
					data: {
						name: 'Grevenmacher',
						extent: [6.17, 49.46, 6.53, 49.86],
					},
				},
				'148_1878': {
					key: '148_1878',
					data: {
						name: 'Luxembourg',
						extent: [5.82, 49.45, 6.31, 49.83],
					},
				},
			},
		},
	},
	156: {
		key: 156,
		data: {
			name: 'Malta',
			extent: [14.18, 35.79, 14.58, 36.08],
			regionsByFeatureKey: {
				'156_1935': {
					key: '156_1935',
					data: {
						name: 'Attard',
						extent: [14.41, 35.88, 14.46, 35.9],
					},
				},
				'156_1936': {
					key: '156_1936',
					data: {
						name: 'Balzan',
						extent: [14.45, 35.9, 14.46, 35.9],
					},
				},
				'156_1937': {
					key: '156_1937',
					data: {
						name: 'Birgu',
						extent: [14.52, 35.88, 14.53, 35.89],
					},
				},
				'156_1938': {
					key: '156_1938',
					data: {
						name: 'Birkirkara',
						extent: [14.46, 35.89, 14.48, 35.91],
					},
				},
				'156_1939': {
					key: '156_1939',
					data: {
						name: 'Birzebbugia',
						extent: [14.5, 35.81, 14.55, 35.84],
					},
				},
				'156_1940': {
					key: '156_1940',
					data: {
						name: 'Bormla',
						extent: [14.51, 35.88, 14.53, 35.89],
					},
				},
				'156_1941': {
					key: '156_1941',
					data: {
						name: 'Dingli',
						extent: [14.36, 35.84, 14.4, 35.87],
					},
				},
				'156_1942': {
					key: '156_1942',
					data: {
						name: 'Fgura',
						extent: [14.51, 35.87, 14.53, 35.88],
					},
				},
				'156_1943': {
					key: '156_1943',
					data: {
						name: 'Floriana',
						extent: [14.5, 35.89, 14.51, 35.9],
					},
				},
				'156_1944': {
					key: '156_1944',
					data: {
						name: 'Fontana',
						extent: [14.23, 36.04, 14.24, 36.04],
					},
				},
				'156_1945': {
					key: '156_1945',
					data: {
						name: 'Ghajnsielem',
						extent: [14.27, 36.0, 14.35, 36.04],
					},
				},
				'156_1946': {
					key: '156_1946',
					data: {
						name: 'Gharb',
						extent: [14.18, 36.06, 14.22, 36.08],
					},
				},
				'156_1947': {
					key: '156_1947',
					data: {
						name: 'Gharghur',
						extent: [14.45, 35.92, 14.47, 35.94],
					},
				},
				'156_1948': {
					key: '156_1948',
					data: {
						name: 'Ghasri',
						extent: [14.21, 36.05, 14.24, 36.08],
					},
				},
				'156_1949': {
					key: '156_1949',
					data: {
						name: 'Ghaxaq',
						extent: [14.51, 35.83, 14.53, 35.86],
					},
				},
				'156_1950': {
					key: '156_1950',
					data: {
						name: 'Gudja',
						extent: [14.5, 35.84, 14.51, 35.86],
					},
				},
				'156_1951': {
					key: '156_1951',
					data: {
						name: 'Gzira',
						extent: [14.49, 35.9, 14.51, 35.91],
					},
				},
				'156_1952': {
					key: '156_1952',
					data: {
						name: 'Hamrun',
						extent: [14.48, 35.88, 14.5, 35.89],
					},
				},
				'156_1953': {
					key: '156_1953',
					data: {
						name: 'Iklin',
						extent: [14.45, 35.9, 14.47, 35.92],
					},
				},
				'156_1954': {
					key: '156_1954',
					data: {
						name: 'Isla',
						extent: [14.52, 35.89, 14.52, 35.89],
					},
				},
				'156_1955': {
					key: '156_1955',
					data: {
						name: 'Kalkara',
						extent: [14.52, 35.89, 14.55, 35.9],
					},
				},
				'156_1956': {
					key: '156_1956',
					data: {
						name: 'Kercem',
						extent: [14.19, 36.03, 14.23, 36.06],
					},
				},
				'156_1957': {
					key: '156_1957',
					data: {
						name: 'Kirkop',
						extent: [14.48, 35.84, 14.5, 35.85],
					},
				},
				'156_1958': {
					key: '156_1958',
					data: {
						name: 'Lija',
						extent: [14.44, 35.9, 14.46, 35.91],
					},
				},
				'156_1959': {
					key: '156_1959',
					data: {
						name: 'Luqa',
						extent: [14.46, 35.83, 14.52, 35.88],
					},
				},
				'156_1960': {
					key: '156_1960',
					data: {
						name: 'Marsa',
						extent: [14.48, 35.87, 14.51, 35.89],
					},
				},
				'156_1961': {
					key: '156_1961',
					data: {
						name: 'Marsascala',
						extent: [14.55, 35.84, 14.58, 35.88],
					},
				},
				'156_1962': {
					key: '156_1962',
					data: {
						name: 'Marsaxlokk',
						extent: [14.53, 35.82, 14.57, 35.85],
					},
				},
				'156_1963': {
					key: '156_1963',
					data: {
						name: 'Mdina',
						extent: [14.4, 35.88, 14.42, 35.89],
					},
				},
				'156_1964': {
					key: '156_1964',
					data: {
						name: 'Mellieha',
						extent: [14.32, 35.93, 14.41, 36.0],
					},
				},
				'156_1965': {
					key: '156_1965',
					data: {
						name: 'Mgarr',
						extent: [14.33, 35.9, 14.41, 35.94],
					},
				},
				'156_1966': {
					key: '156_1966',
					data: {
						name: 'Mosta',
						extent: [14.41, 35.9, 14.44, 35.93],
					},
				},
				'156_1967': {
					key: '156_1967',
					data: {
						name: 'Mqabba',
						extent: [14.46, 35.84, 14.49, 35.85],
					},
				},
				'156_1968': {
					key: '156_1968',
					data: {
						name: 'Msida',
						extent: [14.48, 35.89, 14.5, 35.91],
					},
				},
				'156_1969': {
					key: '156_1969',
					data: {
						name: 'Munxar',
						extent: [14.21, 36.02, 14.24, 36.04],
					},
				},
				'156_1970': {
					key: '156_1970',
					data: {
						name: 'Nadur',
						extent: [14.28, 36.04, 14.31, 36.07],
					},
				},
				'156_1971': {
					key: '156_1971',
					data: {
						name: 'Naxxar',
						extent: [14.42, 35.91, 14.46, 35.95],
					},
				},
				'156_1972': {
					key: '156_1972',
					data: {
						name: 'Paola',
						extent: [14.5, 35.86, 14.52, 35.89],
					},
				},
				'156_1973': {
					key: '156_1973',
					data: {
						name: 'Pembroke',
						extent: [14.46, 35.93, 14.49, 35.94],
					},
				},
				'156_1974': {
					key: '156_1974',
					data: {
						name: 'Pieta',
						extent: [14.49, 35.89, 14.5, 35.9],
					},
				},
				'156_1975': {
					key: '156_1975',
					data: {
						name: 'Qala',
						extent: [14.3, 36.03, 14.34, 36.05],
					},
				},
				'156_1976': {
					key: '156_1976',
					data: {
						name: 'Qormi',
						extent: [14.45, 35.86, 14.49, 35.89],
					},
				},
				'156_1977': {
					key: '156_1977',
					data: {
						name: 'Qrendi',
						extent: [14.44, 35.82, 14.47, 35.84],
					},
				},
				'156_1978': {
					key: '156_1978',
					data: {
						name: 'Rabat (malta)',
						extent: [14.33, 35.86, 14.42, 35.91],
					},
				},
				'156_1979': {
					key: '156_1979',
					data: {
						name: 'Rabat (victoria)',
						extent: [14.23, 36.04, 14.25, 36.06],
					},
				},
				'156_1980': {
					key: '156_1980',
					data: {
						name: 'Safi',
						extent: [14.48, 35.83, 14.51, 35.84],
					},
				},
				'156_1981': {
					key: '156_1981',
					data: {
						name: 'San Giljan',
						extent: [14.48, 35.91, 14.5, 35.93],
					},
				},
				'156_1982': {
					key: '156_1982',
					data: {
						name: 'San Gwann',
						extent: [14.46, 35.9, 14.49, 35.92],
					},
				},
				'156_1983': {
					key: '156_1983',
					data: {
						name: 'San Lawrenz',
						extent: [14.19, 36.03, 14.21, 36.06],
					},
				},
				'156_1984': {
					key: '156_1984',
					data: {
						name: 'San Pawl Ii-bahar',
						extent: [14.37, 35.91, 14.43, 35.96],
					},
				},
				'156_1985': {
					key: '156_1985',
					data: {
						name: 'Sannat',
						extent: [14.23, 36.01, 14.27, 36.03],
					},
				},
				'156_1986': {
					key: '156_1986',
					data: {
						name: 'Santa Lucija',
						extent: [14.5, 35.86, 14.52, 35.87],
					},
				},
				'156_1987': {
					key: '156_1987',
					data: {
						name: 'Santa Venera',
						extent: [14.47, 35.89, 14.49, 35.9],
					},
				},
				'156_1988': {
					key: '156_1988',
					data: {
						name: 'Siggiewi',
						extent: [14.39, 35.79, 14.48, 35.87],
					},
				},
				'156_1989': {
					key: '156_1989',
					data: {
						name: 'Sliema',
						extent: [14.49, 35.91, 14.52, 35.92],
					},
				},
				'156_1990': {
					key: '156_1990',
					data: {
						name: 'Swieqi',
						extent: [14.46, 35.92, 14.49, 35.94],
					},
				},
				'156_1991': {
					key: '156_1991',
					data: {
						name: "Ta' Xbiex",
						extent: [14.49, 35.9, 14.5, 35.9],
					},
				},
				'156_1992': {
					key: '156_1992',
					data: {
						name: 'Tarxien',
						extent: [14.51, 35.86, 14.52, 35.87],
					},
				},
				'156_1993': {
					key: '156_1993',
					data: {
						name: 'Valletta',
						extent: [14.51, 35.89, 14.52, 35.9],
					},
				},
				'156_1994': {
					key: '156_1994',
					data: {
						name: 'Xaghra',
						extent: [14.25, 36.04, 14.28, 36.07],
					},
				},
				'156_1995': {
					key: '156_1995',
					data: {
						name: 'Xewkija',
						extent: [14.24, 36.02, 14.28, 36.05],
					},
				},
				'156_1996': {
					key: '156_1996',
					data: {
						name: 'Xghajra',
						extent: [14.54, 35.88, 14.55, 35.89],
					},
				},
				'156_1997': {
					key: '156_1997',
					data: {
						name: 'Zabbar',
						extent: [14.53, 35.86, 14.56, 35.89],
					},
				},
				'156_1998': {
					key: '156_1998',
					data: {
						name: 'Zebbug (gozo)',
						extent: [14.22, 36.05, 14.27, 36.08],
					},
				},
				'156_1999': {
					key: '156_1999',
					data: {
						name: 'Zebbug (malta)',
						extent: [14.42, 35.86, 14.47, 35.89],
					},
				},
				'156_2000': {
					key: '156_2000',
					data: {
						name: 'Zejtun',
						extent: [14.52, 35.85, 14.55, 35.87],
					},
				},
				'156_2001': {
					key: '156_2001',
					data: {
						name: 'Zurrieq',
						extent: [14.46, 35.81, 14.52, 35.84],
					},
				},
			},
		},
	},
	165: {
		key: 165,
		data: {
			name: 'Moldova',
			extent: [26.62, 45.47, 30.16, 48.49],
			regionsByFeatureKey: {
				'165_2062': {
					key: '165_2062',
					data: {
						name: 'Balti',
						extent: [27.17, 47.37, 28.37, 48.09],
					},
				},
				'165_2063': {
					key: '165_2063',
					data: {
						name: 'Cahul',
						extent: [28.07, 45.47, 28.79, 46.41],
					},
				},
				'165_2064': {
					key: '165_2064',
					data: {
						name: 'Chisinau',
						extent: [28.28, 46.73, 29.45, 47.31],
					},
				},
				'165_2066': {
					key: '165_2066',
					data: {
						name: 'Dubasari',
						extent: [28.41, 46.52, 29.98, 48.17],
					},
				},
				'165_2067': {
					key: '165_2067',
					data: {
						name: 'Edinet',
						extent: [26.62, 47.92, 28.0, 48.49],
					},
				},
				'165_2068': {
					key: '165_2068',
					data: {
						name: 'Gagauzia',
						extent: [28.39, 45.94, 29.06, 46.47],
					},
				},
				'165_2069': {
					key: '165_2069',
					data: {
						name: 'Lapusna',
						extent: [28.11, 46.14, 29.06, 47.03],
					},
				},
				'165_2070': {
					key: '165_2070',
					data: {
						name: 'Orhei',
						extent: [28.11, 47.17, 29.06, 47.85],
					},
				},
				'165_2071': {
					key: '165_2071',
					data: {
						name: 'Soroca',
						extent: [27.61, 47.67, 28.92, 48.33],
					},
				},
				'165_2072': {
					key: '165_2072',
					data: {
						name: 'Tighina',
						extent: [28.67, 46.36, 30.16, 46.8],
					},
				},
				'165_2073': {
					key: '165_2073',
					data: {
						name: 'Ungheni',
						extent: [27.6, 46.88, 28.54, 47.44],
					},
				},
			},
		},
	},
	2647: {
		key: 2647,
		data: {
			name: 'Montenegro',
			extent: [18.43, 41.85, 20.36, 43.56],
			regionsByFeatureKey: {
				'2647_25350': {
					key: '2647_25350',
					data: {
						name: 'Andrijevica',
						extent: [19.63, 42.59, 19.98, 42.83],
					},
				},
				'2647_25351': {
					key: '2647_25351',
					data: {
						name: 'Bar',
						extent: [18.9, 41.96, 19.4, 42.31],
					},
				},
				'2647_25352': {
					key: '2647_25352',
					data: {
						name: 'Berane',
						extent: [19.63, 42.71, 20.12, 42.99],
					},
				},
				'2647_25353': {
					key: '2647_25353',
					data: {
						name: 'Bijelo Polje',
						extent: [19.38, 42.89, 20.07, 43.25],
					},
				},
				'2647_25354': {
					key: '2647_25354',
					data: {
						name: 'Budva',
						extent: [18.75, 42.19, 18.96, 42.35],
					},
				},
				'2647_25355': {
					key: '2647_25355',
					data: {
						name: 'Cetinje',
						extent: [18.66, 42.25, 19.12, 42.71],
					},
				},
				'2647_25356': {
					key: '2647_25356',
					data: {
						name: 'Danilovgrad',
						extent: [18.92, 42.46, 19.27, 42.76],
					},
				},
				'2647_25357': {
					key: '2647_25357',
					data: {
						name: 'Herceg Novi',
						extent: [18.43, 42.42, 18.67, 42.59],
					},
				},
				'2647_25358': {
					key: '2647_25358',
					data: {
						name: 'Kolasin',
						extent: [19.2, 42.66, 19.67, 42.94],
					},
				},
				'2647_25359': {
					key: '2647_25359',
					data: {
						name: 'Kotor',
						extent: [18.54, 42.41, 18.76, 42.63],
					},
				},
				'2647_25360': {
					key: '2647_25360',
					data: {
						name: 'Mojkovac',
						extent: [19.32, 42.89, 19.64, 43.08],
					},
				},
				'2647_25361': {
					key: '2647_25361',
					data: {
						name: 'Niksic',
						extent: [18.46, 42.6, 19.25, 43.13],
					},
				},
				'2647_25362': {
					key: '2647_25362',
					data: {
						name: 'Plav',
						extent: [19.74, 42.47, 20.11, 42.71],
					},
				},
				'2647_25363': {
					key: '2647_25363',
					data: {
						name: 'Pljevlja',
						extent: [18.92, 43.08, 19.54, 43.56],
					},
				},
				'2647_25364': {
					key: '2647_25364',
					data: {
						name: 'Pluzine',
						extent: [18.65, 42.94, 19.03, 43.36],
					},
				},
				'2647_25365': {
					key: '2647_25365',
					data: {
						name: 'Podgorica',
						extent: [18.91, 42.18, 19.65, 42.71],
					},
				},
				'2647_25366': {
					key: '2647_25366',
					data: {
						name: 'Rozaj',
						extent: [20.03, 42.75, 20.36, 42.98],
					},
				},
				'2647_25367': {
					key: '2647_25367',
					data: {
						name: 'Savnik',
						extent: [18.93, 42.86, 19.37, 43.13],
					},
				},
				'2647_25368': {
					key: '2647_25368',
					data: {
						name: 'Tivat',
						extent: [18.55, 42.29, 18.78, 42.45],
					},
				},
				'2647_25369': {
					key: '2647_25369',
					data: {
						name: 'Ulcinj',
						extent: [19.15, 41.85, 19.39, 42.07],
					},
				},
				'2647_25370': {
					key: '2647_25370',
					data: {
						name: 'Zabljak',
						extent: [18.98, 43.01, 19.38, 43.26],
					},
				},
			},
		},
	},
	177: {
		key: 177,
		data: {
			name: 'Netherlands',
			extent: [3.36, 50.75, 7.23, 53.56],
			regionsByFeatureKey: {
				'177_2158': {
					key: '177_2158',
					data: {
						name: 'Drenthe',
						extent: [6.13, 52.61, 7.09, 53.2],
					},
				},
				'177_2159': {
					key: '177_2159',
					data: {
						name: 'Flevoland',
						extent: [5.13, 52.26, 5.98, 52.84],
					},
				},
				'177_2160': {
					key: '177_2160',
					data: {
						name: 'Friesland',
						extent: [4.85, 52.8, 6.44, 53.51],
					},
				},
				'177_2161': {
					key: '177_2161',
					data: {
						name: 'Gelderland',
						extent: [5.0, 51.73, 6.84, 52.52],
					},
				},
				'177_2162': {
					key: '177_2162',
					data: {
						name: 'Groningen',
						extent: [6.17, 52.84, 7.23, 53.56],
					},
				},
				'177_2163': {
					key: '177_2163',
					data: {
						name: 'Limburg',
						extent: [5.57, 50.75, 6.23, 51.78],
					},
				},
				'177_2164': {
					key: '177_2164',
					data: {
						name: 'Noord-brabant',
						extent: [3.86, 51.22, 6.05, 51.85],
					},
				},
				'177_2165': {
					key: '177_2165',
					data: {
						name: 'Noord-holland',
						extent: [4.5, 52.18, 5.35, 53.18],
					},
				},
				'177_2166': {
					key: '177_2166',
					data: {
						name: 'Overijssel',
						extent: [5.78, 52.11, 7.08, 52.85],
					},
				},
				'177_2167': {
					key: '177_2167',
					data: {
						name: 'Utrecht',
						extent: [4.77, 51.94, 5.62, 52.29],
					},
				},
				'177_2168': {
					key: '177_2168',
					data: {
						name: 'Zeeland',
						extent: [3.36, 51.21, 4.28, 51.77],
					},
				},
				'177_2169': {
					key: '177_2169',
					data: {
						name: 'Zuid-holland',
						extent: [4.01, 51.7, 5.18, 52.33],
					},
				},
			},
		},
	},
	186: {
		key: 186,
		data: {
			name: 'Norway',
			extent: [4.65, 57.97, 31.14, 71.19],
			regionsByFeatureKey: {
				'186_2244': {
					key: '186_2244',
					data: {
						name: 'Akershus',
						extent: [10.31, 59.48, 11.93, 60.59],
					},
				},
				'186_2245': {
					key: '186_2245',
					data: {
						name: 'Aust-agder',
						extent: [6.81, 58.11, 9.37, 59.67],
					},
				},
				'186_2246': {
					key: '186_2246',
					data: {
						name: 'Buskerud',
						extent: [7.46, 59.41, 10.63, 61.09],
					},
				},
				'186_2247': {
					key: '186_2247',
					data: {
						name: 'Finnmark',
						extent: [21.17, 68.56, 31.14, 71.19],
					},
				},
				'186_2248': {
					key: '186_2248',
					data: {
						name: 'Hedmark',
						extent: [9.57, 59.84, 12.87, 62.7],
					},
				},
				'186_2249': {
					key: '186_2249',
					data: {
						name: 'Hordaland',
						extent: [4.7, 59.49, 7.7, 61.03],
					},
				},
				'186_2250': {
					key: '186_2250',
					data: {
						name: 'Moere Og Romsdal',
						extent: [5.35, 61.95, 9.58, 63.48],
					},
				},
				'186_2251': {
					key: '186_2251',
					data: {
						name: 'Nord-troendelag',
						extent: [10.12, 63.18, 14.33, 65.15],
					},
				},
				'186_2252': {
					key: '186_2252',
					data: {
						name: 'Nordland',
						extent: [11.71, 64.95, 18.16, 69.33],
					},
				},
				'186_2253': {
					key: '186_2253',
					data: {
						name: 'Oestfold',
						extent: [10.59, 58.88, 11.95, 59.8],
					},
				},
				'186_2254': {
					key: '186_2254',
					data: {
						name: 'Oppland',
						extent: [7.33, 60.13, 11.15, 62.38],
					},
				},
				'186_2255': {
					key: '186_2255',
					data: {
						name: 'Oslo',
						extent: [10.49, 59.81, 10.93, 60.13],
					},
				},
				'186_2256': {
					key: '186_2256',
					data: {
						name: 'Rogaland',
						extent: [4.86, 58.27, 7.17, 59.84],
					},
				},
				'186_2257': {
					key: '186_2257',
					data: {
						name: 'Soer-Troendelag',
						extent: [8.24, 62.26, 12.26, 64.43],
					},
				},
				'186_2258': {
					key: '186_2258',
					data: {
						name: 'Sogn og Fjordane',
						extent: [4.65, 60.67, 8.32, 62.21],
					},
				},
				'186_2259': {
					key: '186_2259',
					data: {
						name: 'Telemark',
						extent: [7.08, 58.77, 9.86, 60.2],
					},
				},
				'186_2260': {
					key: '186_2260',
					data: {
						name: 'Troms',
						extent: [15.59, 68.35, 22.9, 70.31],
					},
				},
				'186_2261': {
					key: '186_2261',
					data: {
						name: 'Vest-Agder',
						extent: [6.39, 57.97, 8.2, 59.19],
					},
				},
				'186_2262': {
					key: '186_2262',
					data: {
						name: 'Vestfold',
						extent: [9.74, 58.96, 10.58, 59.7],
					},
				},
			},
		},
	},
	198: {
		key: 198,
		data: {
			name: 'Poland',
			extent: [14.12, 49.01, 24.15, 54.84],
			regionsByFeatureKey: {
				'198_2370': {
					key: '198_2370',
					data: {
						name: 'Dolnoslaskie',
						extent: [14.82, 50.1, 17.8, 51.8],
					},
				},
				'198_2371': {
					key: '198_2371',
					data: {
						name: 'Kujawsko-Pomorskie',
						extent: [17.25, 52.33, 19.76, 53.78],
					},
				},
				'198_2372': {
					key: '198_2372',
					data: {
						name: 'Lodzkie',
						extent: [18.08, 50.85, 20.66, 52.4],
					},
				},
				'198_2373': {
					key: '198_2373',
					data: {
						name: 'Lubeiskie',
						extent: [21.62, 50.25, 24.15, 52.29],
					},
				},
				'198_2374': {
					key: '198_2374',
					data: {
						name: 'Lubuskie',
						extent: [14.53, 51.36, 16.42, 53.12],
					},
				},
				'198_2375': {
					key: '198_2375',
					data: {
						name: 'Malopolske',
						extent: [19.08, 49.18, 21.42, 50.52],
					},
				},
				'198_2376': {
					key: '198_2376',
					data: {
						name: 'Mazowieckie',
						extent: [19.26, 51.01, 23.13, 53.48],
					},
				},
				'198_2377': {
					key: '198_2377',
					data: {
						name: 'Opolskie',
						extent: [16.91, 49.97, 18.7, 51.19],
					},
				},
				'198_2378': {
					key: '198_2378',
					data: {
						name: 'Podkarpackie',
						extent: [21.14, 49.01, 23.54, 50.82],
					},
				},
				'198_2379': {
					key: '198_2379',
					data: {
						name: 'Podlaskie',
						extent: [21.59, 52.28, 23.96, 54.43],
					},
				},
				'198_2380': {
					key: '198_2380',
					data: {
						name: 'Pomorskie',
						extent: [16.71, 53.49, 19.66, 54.84],
					},
				},
				'198_2381': {
					key: '198_2381',
					data: {
						name: 'Slaskie',
						extent: [18.02, 49.4, 19.97, 51.1],
					},
				},
				'198_2382': {
					key: '198_2382',
					data: {
						name: 'Swietokrzyskie',
						extent: [19.71, 50.27, 21.87, 51.35],
					},
				},
				'198_2383': {
					key: '198_2383',
					data: {
						name: 'Warminsko-Mazurskie',
						extent: [19.14, 53.14, 22.81, 54.44],
					},
				},
				'198_2384': {
					key: '198_2384',
					data: {
						name: 'Wielkopolskie',
						extent: [15.79, 51.1, 19.11, 53.66],
					},
				},
				'198_2385': {
					key: '198_2385',
					data: {
						name: 'Zachodnio-Pomorskie',
						extent: [14.12, 52.61, 16.98, 54.57],
					},
				},
			},
		},
	},
	199: {
		key: 199,
		data: {
			name: 'Portugal',
			extent: [-9.5, 36.96, -6.18, 42.15],
			regionsByFeatureKey: {
				'199_2386': {
					key: '199_2386',
					data: {
						name: 'Aveiro',
						extent: [-8.78, 40.28, -8.08, 41.08],
					},
				},
				'199_2387': {
					key: '199_2387',
					data: {
						name: 'Beja',
						extent: [-8.81, 37.32, -6.93, 38.33],
					},
				},
				'199_2388': {
					key: '199_2388',
					data: {
						name: 'Braga',
						extent: [-8.81, 41.32, -7.8, 41.82],
					},
				},
				'199_2389': {
					key: '199_2389',
					data: {
						name: 'Braganca',
						extent: [-7.42, 41.02, -6.18, 41.99],
					},
				},
				'199_2390': {
					key: '199_2390',
					data: {
						name: 'Castelo Branco',
						extent: [-8.29, 39.54, -6.86, 40.42],
					},
				},
				'199_2391': {
					key: '199_2391',
					data: {
						name: 'Coimbra',
						extent: [-8.91, 39.93, -7.73, 40.52],
					},
				},
				'199_2392': {
					key: '199_2392',
					data: {
						name: 'Evora',
						extent: [-8.65, 38.17, -7.1, 39.03],
					},
				},
				'199_2393': {
					key: '199_2393',
					data: {
						name: 'Faro',
						extent: [-8.99, 36.96, -7.4, 37.53],
					},
				},
				'199_2394': {
					key: '199_2394',
					data: {
						name: 'Guarda',
						extent: [-7.85, 40.23, -6.78, 41.18],
					},
				},
				'199_2405': {
					key: '199_2405',
					data: {
						name: 'Leiria',
						extent: [-9.41, 39.21, -8.1, 40.09],
					},
				},
				'199_2406': {
					key: '199_2406',
					data: {
						name: 'Lisboa',
						extent: [-9.5, 38.67, -8.78, 39.32],
					},
				},
				'199_2407': {
					key: '199_2407',
					data: {
						name: 'Portalegre',
						extent: [-8.34, 38.75, -6.95, 39.66],
					},
				},
				'199_2408': {
					key: '199_2408',
					data: {
						name: 'Porto',
						extent: [-8.78, 41.01, -7.87, 41.47],
					},
				},
				'199_2409': {
					key: '199_2409',
					data: {
						name: 'Santarem',
						extent: [-9.0, 38.74, -7.81, 39.84],
					},
				},
				'199_2410': {
					key: '199_2410',
					data: {
						name: 'Setubal',
						extent: [-9.27, 37.75, -8.12, 38.85],
					},
				},
				'199_2411': {
					key: '199_2411',
					data: {
						name: 'Viana Do Castelo',
						extent: [-8.87, 41.6, -8.08, 42.15],
					},
				},
				'199_2412': {
					key: '199_2412',
					data: {
						name: 'Vila Real',
						extent: [-8.11, 41.13, -7.16, 41.93],
					},
				},
				'199_2413': {
					key: '199_2413',
					data: {
						name: 'Viseu',
						extent: [-8.35, 40.33, -7.3, 41.22],
					},
				},
			},
		},
	},
	203: {
		key: 203,
		data: {
			name: 'Romania',
			extent: [20.26, 43.63, 29.69, 48.27],
			regionsByFeatureKey: {
				'203_2446': {
					key: '203_2446',
					data: {
						name: 'Alba',
						extent: [22.72, 45.51, 24.27, 46.65],
					},
				},
				'203_2447': {
					key: '203_2447',
					data: {
						name: 'Arad',
						extent: [20.72, 45.92, 22.8, 46.69],
					},
				},
				'203_2448': {
					key: '203_2448',
					data: {
						name: 'Arges',
						extent: [24.46, 44.42, 25.34, 45.65],
					},
				},
				'203_2449': {
					key: '203_2449',
					data: {
						name: 'Bacau',
						extent: [25.98, 46.05, 27.51, 46.84],
					},
				},
				'203_2450': {
					key: '203_2450',
					data: {
						name: 'Bihor',
						extent: [21.49, 46.39, 22.88, 47.6],
					},
				},
				'203_2451': {
					key: '203_2451',
					data: {
						name: 'Bistrita-nasaud',
						extent: [23.94, 46.76, 25.09, 47.6],
					},
				},
				'203_2452': {
					key: '203_2452',
					data: {
						name: 'Botosani',
						extent: [26.1, 47.47, 27.37, 48.27],
					},
				},
				'203_2453': {
					key: '203_2453',
					data: {
						name: 'Braila',
						extent: [27.0, 44.75, 28.18, 45.51],
					},
				},
				'203_2454': {
					key: '203_2454',
					data: {
						name: 'Brasov',
						extent: [24.69, 45.43, 26.11, 46.21],
					},
				},
				'203_2455': {
					key: '203_2455',
					data: {
						name: 'Bucuresti',
						extent: [25.9, 44.31, 26.45, 44.76],
					},
				},
				'203_2456': {
					key: '203_2456',
					data: {
						name: 'Buzau',
						extent: [26.09, 44.76, 27.38, 45.83],
					},
				},
				'203_2457': {
					key: '203_2457',
					data: {
						name: 'Calarasi',
						extent: [26.28, 44.05, 28.01, 44.58],
					},
				},
				'203_2458': {
					key: '203_2458',
					data: {
						name: 'Caras-severin',
						extent: [21.35, 44.57, 22.78, 45.71],
					},
				},
				'203_2459': {
					key: '203_2459',
					data: {
						name: 'Cluj',
						extent: [22.7, 46.43, 24.21, 47.37],
					},
				},
				'203_2460': {
					key: '203_2460',
					data: {
						name: 'Constanta',
						extent: [27.28, 43.74, 28.99, 44.8],
					},
				},
				'203_2461': {
					key: '203_2461',
					data: {
						name: 'Covasna',
						extent: [25.4, 45.6, 26.44, 46.3],
					},
				},
				'203_2462': {
					key: '203_2462',
					data: {
						name: 'Dimbovita',
						extent: [25.12, 44.5, 25.96, 45.49],
					},
				},
				'203_2463': {
					key: '203_2463',
					data: {
						name: 'Dolj',
						extent: [22.85, 43.71, 24.29, 44.75],
					},
				},
				'203_2464': {
					key: '203_2464',
					data: {
						name: 'Galati',
						extent: [27.22, 45.39, 28.22, 46.16],
					},
				},
				'203_2465': {
					key: '203_2465',
					data: {
						name: 'Giurgiu',
						extent: [25.45, 43.7, 26.49, 44.6],
					},
				},
				'203_2466': {
					key: '203_2466',
					data: {
						name: 'Gorj',
						extent: [22.65, 44.59, 23.93, 45.4],
					},
				},
				'203_2467': {
					key: '203_2467',
					data: {
						name: 'Harghita',
						extent: [24.9, 46.12, 26.25, 47.2],
					},
				},
				'203_2468': {
					key: '203_2468',
					data: {
						name: 'Hunedoara',
						extent: [22.38, 45.28, 23.7, 46.36],
					},
				},
				'203_2469': {
					key: '203_2469',
					data: {
						name: 'Ialomita',
						extent: [26.25, 44.34, 28.11, 44.87],
					},
				},
				'203_2470': {
					key: '203_2470',
					data: {
						name: 'Iasi',
						extent: [26.55, 46.81, 28.13, 47.62],
					},
				},
				'203_2471': {
					key: '203_2471',
					data: {
						name: 'Maramures',
						extent: [23.02, 47.34, 25.03, 48.0],
					},
				},
				'203_2472': {
					key: '203_2472',
					data: {
						name: 'Mehedinti',
						extent: [22.03, 44.1, 23.48, 45.15],
					},
				},
				'203_2473': {
					key: '203_2473',
					data: {
						name: 'Mures',
						extent: [23.97, 46.13, 25.34, 47.16],
					},
				},
				'203_2474': {
					key: '203_2474',
					data: {
						name: 'Neamt',
						extent: [25.68, 46.61, 27.2, 47.36],
					},
				},
				'203_2475': {
					key: '203_2475',
					data: {
						name: 'Olt',
						extent: [23.89, 43.69, 24.93, 44.95],
					},
				},
				'203_2476': {
					key: '203_2476',
					data: {
						name: 'Prahova',
						extent: [25.48, 44.74, 26.62, 45.55],
					},
				},
				'203_2477': {
					key: '203_2477',
					data: {
						name: 'Salaj',
						extent: [22.58, 46.91, 23.88, 47.44],
					},
				},
				'203_2478': {
					key: '203_2478',
					data: {
						name: 'Satu Mare',
						extent: [22.19, 47.33, 23.66, 48.12],
					},
				},
				'203_2479': {
					key: '203_2479',
					data: {
						name: 'Sibiu',
						extent: [23.69, 45.5, 24.98, 46.32],
					},
				},
				'203_2480': {
					key: '203_2480',
					data: {
						name: 'Suceava',
						extent: [24.98, 47.1, 26.72, 47.99],
					},
				},
				'203_2481': {
					key: '203_2481',
					data: {
						name: 'Teleorman',
						extent: [24.6, 43.63, 25.71, 44.56],
					},
				},
				'203_2482': {
					key: '203_2482',
					data: {
						name: 'Timis',
						extent: [20.26, 45.19, 22.61, 46.19],
					},
				},
				'203_2483': {
					key: '203_2483',
					data: {
						name: 'Tulcea',
						extent: [27.99, 44.64, 29.69, 45.47],
					},
				},
				'203_2484': {
					key: '203_2484',
					data: {
						name: 'Vaslui',
						extent: [27.17, 46.01, 28.26, 46.98],
					},
				},
				'203_2485': {
					key: '203_2485',
					data: {
						name: 'Vilcea',
						extent: [23.68, 44.54, 24.56, 45.62],
					},
				},
				'203_2486': {
					key: '203_2486',
					data: {
						name: 'Vrancea',
						extent: [26.39, 45.4, 27.55, 46.19],
					},
				},
			},
		},
	},
	2648: {
		key: 2648,
		data: {
			name: 'Serbia',
			extent: [18.85, 42.23, 23.01, 46.19],
			regionsByFeatureKey: {
				'2648_25371': {
					key: '2648_25371',
					data: {
						name: 'Borski',
						extent: [21.79, 43.91, 22.76, 44.71],
					},
				},
				'2648_25372': {
					key: '2648_25372',
					data: {
						name: 'Branicevski',
						extent: [21.03, 44.08, 22.02, 44.82],
					},
				},
				'2648_25373': {
					key: '2648_25373',
					data: {
						name: 'Grad Beograd',
						extent: [19.98, 44.28, 20.85, 45.1],
					},
				},
				'2648_25374': {
					key: '2648_25374',
					data: {
						name: 'Jablanicki',
						extent: [21.4, 42.67, 22.52, 43.17],
					},
				},
				'2648_25375': {
					key: '2648_25375',
					data: {
						name: 'Juzno-backi',
						extent: [18.97, 45.14, 20.32, 45.77],
					},
				},
				'2648_25376': {
					key: '2648_25376',
					data: {
						name: 'Juzno-banatski',
						extent: [20.42, 44.66, 21.56, 45.33],
					},
				},
				'2648_25377': {
					key: '2648_25377',
					data: {
						name: 'Kolubarski',
						extent: [19.43, 44.07, 20.43, 44.59],
					},
				},
				'2648_25381': {
					key: '2648_25381',
					data: {
						name: 'Macvanski',
						extent: [19.1, 44.1, 20.01, 44.95],
					},
				},
				'2648_25382': {
					key: '2648_25382',
					data: {
						name: 'Moravicki',
						extent: [19.9, 43.27, 20.66, 44.29],
					},
				},
				'2648_25383': {
					key: '2648_25383',
					data: {
						name: 'Nisavski',
						extent: [21.48, 43.05, 22.35, 43.81],
					},
				},
				'2648_25384': {
					key: '2648_25384',
					data: {
						name: 'Pcinjski',
						extent: [21.53, 42.23, 22.56, 42.82],
					},
				},
				'2648_25386': {
					key: '2648_25386',
					data: {
						name: 'Pirotski',
						extent: [22.13, 42.86, 23.01, 43.4],
					},
				},
				'2648_25387': {
					key: '2648_25387',
					data: {
						name: 'Podunavski',
						extent: [20.76, 44.21, 21.17, 44.72],
					},
				},
				'2648_25388': {
					key: '2648_25388',
					data: {
						name: 'Pomoravski',
						extent: [20.93, 43.72, 21.81, 44.31],
					},
				},
				'2648_25390': {
					key: '2648_25390',
					data: {
						name: 'Rasinski',
						extent: [20.77, 43.15, 21.58, 43.84],
					},
				},
				'2648_25391': {
					key: '2648_25391',
					data: {
						name: 'Raski',
						extent: [20.04, 42.83, 20.96, 43.88],
					},
				},
				'2648_25392': {
					key: '2648_25392',
					data: {
						name: 'Severno-backi',
						extent: [19.28, 45.61, 19.9, 46.19],
					},
				},
				'2648_25393': {
					key: '2648_25393',
					data: {
						name: 'Severno-banatski',
						extent: [19.8, 45.59, 20.68, 46.18],
					},
				},
				'2648_25394': {
					key: '2648_25394',
					data: {
						name: 'Srednje-banatski',
						extent: [20.06, 45.09, 21.06, 45.8],
					},
				},
				'2648_25395': {
					key: '2648_25395',
					data: {
						name: 'Sremski',
						extent: [18.99, 44.63, 20.36, 45.21],
					},
				},
				'2648_25396': {
					key: '2648_25396',
					data: {
						name: 'Sumadijski',
						extent: [20.4, 43.81, 21.19, 44.45],
					},
				},
				'2648_25397': {
					key: '2648_25397',
					data: {
						name: 'Toplicki',
						extent: [20.92, 42.86, 21.83, 43.4],
					},
				},
				'2648_25398': {
					key: '2648_25398',
					data: {
						name: 'Zajecarski',
						extent: [21.67, 43.31, 22.68, 44.18],
					},
				},
				'2648_25399': {
					key: '2648_25399',
					data: {
						name: 'Zapadno-backi',
						extent: [18.85, 45.42, 19.61, 46.04],
					},
				},
				'2648_25400': {
					key: '2648_25400',
					data: {
						name: 'Zlatiborski',
						extent: [19.23, 42.99, 20.3, 44.17],
					},
				},
			},
		},
	},
	223: {
		key: 223,
		data: {
			name: 'Slovak Republic',
			extent: [16.85, 47.73, 22.57, 49.6],
			regionsByFeatureKey: {
				'223_2667': {
					key: '223_2667',
					data: {
						name: 'Banska Bystrica',
						extent: [18.48, 48.06, 20.47, 48.93],
					},
				},
				'223_2668': {
					key: '223_2668',
					data: {
						name: 'Bratislava',
						extent: [16.85, 48.01, 17.52, 48.65],
					},
				},
				'223_2669': {
					key: '223_2669',
					data: {
						name: 'Kosice',
						extent: [20.18, 48.34, 22.39, 49.0],
					},
				},
				'223_2670': {
					key: '223_2670',
					data: {
						name: 'Nitra',
						extent: [17.7, 47.73, 19.07, 48.71],
					},
				},
				'223_2671': {
					key: '223_2671',
					data: {
						name: 'Presov',
						extent: [19.87, 48.77, 22.57, 49.46],
					},
				},
				'223_2672': {
					key: '223_2672',
					data: {
						name: 'Trencin',
						extent: [17.35, 48.49, 18.82, 49.32],
					},
				},
				'223_2673': {
					key: '223_2673',
					data: {
						name: 'Trnava',
						extent: [16.94, 47.77, 17.98, 48.87],
					},
				},
				'223_2674': {
					key: '223_2674',
					data: {
						name: 'Zilna',
						extent: [18.36, 48.74, 20.06, 49.6],
					},
				},
			},
		},
	},
	224: {
		key: 224,
		data: {
			name: 'Slovenia',
			extent: [13.38, 45.42, 16.6, 46.88],
			regionsByFeatureKey: {
				'224_2675': {
					key: '224_2675',
					data: {
						name: 'Gorenjska',
						extent: [13.68, 46.0, 14.58, 46.52],
					},
				},
				'224_2676': {
					key: '224_2676',
					data: {
						name: 'Goriska',
						extent: [13.38, 45.76, 14.14, 46.45],
					},
				},
				'224_2677': {
					key: '224_2677',
					data: {
						name: 'Jugovzodna Slovenija',
						extent: [14.49, 45.42, 15.43, 46.01],
					},
				},
				'224_2678': {
					key: '224_2678',
					data: {
						name: 'Koroska',
						extent: [14.68, 46.39, 15.45, 46.66],
					},
				},
				'224_2679': {
					key: '224_2679',
					data: {
						name: 'Notranjsko-kraska',
						extent: [14.04, 45.48, 14.58, 45.89],
					},
				},
				'224_2680': {
					key: '224_2680',
					data: {
						name: 'Obalno-kraska',
						extent: [13.57, 45.44, 14.12, 45.87],
					},
				},
				'224_2681': {
					key: '224_2681',
					data: {
						name: 'Osrednjeslovenska',
						extent: [14.1, 45.79, 15.12, 46.37],
					},
				},
				'224_2682': {
					key: '224_2682',
					data: {
						name: 'Podravska',
						extent: [15.31, 46.25, 16.31, 46.71],
					},
				},
				'224_2683': {
					key: '224_2683',
					data: {
						name: 'Pomurska',
						extent: [15.79, 46.46, 16.6, 46.88],
					},
				},
				'224_2684': {
					key: '224_2684',
					data: {
						name: 'Savinjska',
						extent: [14.57, 45.99, 15.82, 46.48],
					},
				},
				'224_2685': {
					key: '224_2685',
					data: {
						name: 'Spodnjeposavska',
						extent: [15.09, 45.77, 15.73, 46.09],
					},
				},
				'224_2686': {
					key: '224_2686',
					data: {
						name: 'Zasavska',
						extent: [14.81, 46.05, 15.19, 46.21],
					},
				},
			},
		},
	},
	229: {
		key: 229,
		data: {
			name: 'Spain',
			extent: [-18.17, 27.64, 4.32, 43.79],
			regionsByFeatureKey: {
				'229_2716': {
					key: '229_2716',
					data: {
						name: 'Andaluc\u00eda',
						extent: [-7.52, 35.94, -1.63, 38.73],
					},
				},
				'229_2717': {
					key: '229_2717',
					data: {
						name: 'Arag\u00f3n',
						extent: [-2.17, 39.85, 0.77, 42.92],
					},
				},
				'229_2718': {
					key: '229_2718',
					data: {
						name: 'Principado de Asturias',
						extent: [-7.18, 42.88, -4.51, 43.66],
					},
				},
				'229_2719': {
					key: '229_2719',
					data: {
						name: 'Illes Balears',
						extent: [1.19, 38.64, 4.32, 40.1],
					},
				},
				'229_2720': {
					key: '229_2720',
					data: {
						name: 'Canarias',
						extent: [-18.17, 27.64, -13.42, 29.41],
					},
				},
				'229_2721': {
					key: '229_2721',
					data: {
						name: 'Cantabria',
						extent: [-4.85, 42.76, -3.15, 43.51],
					},
				},
				'229_2722': {
					key: '229_2722',
					data: {
						name: 'Castilla y Le\u00f3n',
						extent: [-7.08, 40.08, -1.78, 43.24],
					},
				},
				'229_2723': {
					key: '229_2723',
					data: {
						name: 'Castilla-La Mancha',
						extent: [-5.41, 38.02, -0.92, 41.33],
					},
				},
				'229_2724': {
					key: '229_2724',
					data: {
						name: 'Catalu\u00f1a/Catalunya',
						extent: [0.16, 40.52, 3.32, 42.87],
					},
				},
				'229_2725': {
					key: '229_2725',
					data: {
						name: 'Ciudad Aut\u00f3noma de Ceuta',
						extent: [-5.38, 35.88, -5.28, 35.92],
					},
				},
				'229_2726': {
					key: '229_2726',
					data: {
						name: 'Comunitat Valenciana',
						extent: [-1.53, 37.84, 0.51, 40.79],
					},
				},
				'229_2727': {
					key: '229_2727',
					data: {
						name: 'Extremadura',
						extent: [-7.53, 37.94, -4.65, 40.49],
					},
				},
				'229_2728': {
					key: '229_2728',
					data: {
						name: 'Galicia',
						extent: [-9.29, 41.8, -6.73, 43.79],
					},
				},
				'229_2729': {
					key: '229_2729',
					data: {
						name: 'Comunidad de Madrid',
						extent: [-4.58, 39.88, -3.05, 41.17],
					},
				},
				'229_2730': {
					key: '229_2730',
					data: {
						name: 'Ciudad Aut\u00f3noma de Melilla',
						extent: [-2.98, 35.19, -2.42, 35.34],
					},
				},
				'229_2731': {
					key: '229_2731',
					data: {
						name: 'Regi\u00f3n de Murcia',
						extent: [-2.34, 37.37, -0.69, 38.76],
					},
				},
				'229_2732': {
					key: '229_2732',
					data: {
						name: 'Comunidad Foral de Navarra',
						extent: [-2.5, 41.91, -0.73, 43.31],
					},
				},
				'229_2733': {
					key: '229_2733',
					data: {
						name: 'Pa\u00ed\u00ads Vasco/Euskadi',
						extent: [-3.45, 42.47, -1.73, 43.45],
					},
				},
				'229_2734': {
					key: '229_2734',
					data: {
						name: 'La Rioja',
						extent: [-3.13, 41.92, -1.68, 42.64],
					},
				},
			},
		},
	},
	236: {
		key: 236,
		data: {
			name: 'Sweden',
			extent: [10.99, 55.34, 24.16, 69.06],
			regionsByFeatureKey: {
				'236_2786': {
					key: '236_2786',
					data: {
						name: 'Blekinge Laen',
						extent: [14.39, 55.99, 16.07, 56.49],
					},
				},
				'236_2787': {
					key: '236_2787',
					data: {
						name: 'Dalarnas Laen',
						extent: [12.13, 59.85, 16.7, 62.27],
					},
				},
				'236_2788': {
					key: '236_2788',
					data: {
						name: 'Gaevleborgs Laen',
						extent: [14.45, 60.18, 17.56, 62.34],
					},
				},
				'236_2789': {
					key: '236_2789',
					data: {
						name: 'Gotlands Laen',
						extent: [17.96, 56.9, 19.36, 58.39],
					},
				},
				'236_2790': {
					key: '236_2790',
					data: {
						name: 'Hallands Laen',
						extent: [11.91, 56.32, 13.69, 57.59],
					},
				},
				'236_2791': {
					key: '236_2791',
					data: {
						name: 'Jaemtlands Laen',
						extent: [11.98, 61.56, 16.99, 65.12],
					},
				},
				'236_2792': {
					key: '236_2792',
					data: {
						name: 'Joenkoepings Laen',
						extent: [13.07, 56.87, 15.66, 58.18],
					},
				},
				'236_2793': {
					key: '236_2793',
					data: {
						name: 'Kalmar Laen',
						extent: [15.34, 56.19, 17.15, 58.13],
					},
				},
				'236_2794': {
					key: '236_2794',
					data: {
						name: 'Kronobergs Laen',
						extent: [13.29, 56.35, 15.84, 57.23],
					},
				},
				'236_2795': {
					key: '236_2795',
					data: {
						name: 'Norrbottens Laen',
						extent: [15.38, 65.09, 24.16, 69.06],
					},
				},
				'236_2796': {
					key: '236_2796',
					data: {
						name: 'Oerebro Laen',
						extent: [14.28, 58.64, 15.8, 60.1],
					},
				},
				'236_2797': {
					key: '236_2797',
					data: {
						name: 'Oestergoetlands Laen',
						extent: [14.42, 57.69, 17.03, 59.01],
					},
				},
				'236_2798': {
					key: '236_2798',
					data: {
						name: 'Skaane Laen',
						extent: [12.45, 55.34, 14.58, 56.52],
					},
				},
				'236_2799': {
					key: '236_2799',
					data: {
						name: 'Soedermanlands Laen',
						extent: [15.63, 58.61, 17.72, 59.53],
					},
				},
				'236_2800': {
					key: '236_2800',
					data: {
						name: 'Stockholms Laen',
						extent: [17.25, 58.74, 19.21, 60.22],
					},
				},
				'236_2801': {
					key: '236_2801',
					data: {
						name: 'Uppsala Laen',
						extent: [16.73, 59.38, 18.7, 60.66],
					},
				},
				'236_2802': {
					key: '236_2802',
					data: {
						name: 'Vaermlands Laen',
						extent: [11.7, 58.72, 14.47, 61.06],
					},
				},
				'236_2803': {
					key: '236_2803',
					data: {
						name: 'Vaesterbottens Laen',
						extent: [14.34, 63.42, 21.62, 66.35],
					},
				},
				'236_2804': {
					key: '236_2804',
					data: {
						name: 'Vaesternorrlands Laen',
						extent: [14.79, 62.14, 19.34, 64.02],
					},
				},
				'236_2805': {
					key: '236_2805',
					data: {
						name: 'Vaestmanlands Laen',
						extent: [15.43, 59.19, 17.38, 60.29],
					},
				},
				'236_2806': {
					key: '236_2806',
					data: {
						name: 'Vaestra Goetalands Laen',
						extent: [10.99, 57.13, 14.77, 59.26],
					},
				},
			},
		},
	},
	237: {
		key: 237,
		data: {
			name: 'Switzerland',
			extent: [5.96, 45.83, 10.49, 47.81],
			regionsByFeatureKey: {
				'237_2807': {
					key: '237_2807',
					data: {
						name: 'Aargau',
						extent: [7.7, 47.13, 8.44, 47.63],
					},
				},
				'237_2808': {
					key: '237_2808',
					data: {
						name: 'Appenzell Ausser-rhoden',
						extent: [9.19, 47.24, 9.61, 47.46],
					},
				},
				'237_2809': {
					key: '237_2809',
					data: {
						name: 'Appenzell Inner-rhoden',
						extent: [9.29, 47.24, 9.6, 47.44],
					},
				},
				'237_2810': {
					key: '237_2810',
					data: {
						name: 'Basel-landschaft',
						extent: [7.5, 47.33, 7.93, 47.58],
					},
				},
				'237_2811': {
					key: '237_2811',
					data: {
						name: 'Basel-stadt',
						extent: [7.54, 47.52, 7.67, 47.61],
					},
				},
				'237_2812': {
					key: '237_2812',
					data: {
						name: 'Bern',
						extent: [6.85, 46.32, 8.44, 47.48],
					},
				},
				'237_2813': {
					key: '237_2813',
					data: {
						name: 'Fribourg',
						extent: [6.75, 46.44, 7.37, 47.01],
					},
				},
				'237_2814': {
					key: '237_2814',
					data: {
						name: 'Geneve',
						extent: [5.96, 46.15, 6.3, 46.39],
					},
				},
				'237_2815': {
					key: '237_2815',
					data: {
						name: 'Glarus',
						extent: [8.87, 46.79, 9.25, 47.18],
					},
				},
				'237_2816': {
					key: '237_2816',
					data: {
						name: 'Graubunden',
						extent: [8.65, 46.16, 10.49, 47.07],
					},
				},
				'237_2817': {
					key: '237_2817',
					data: {
						name: 'Jura',
						extent: [6.84, 47.16, 7.52, 47.51],
					},
				},
				'237_2818': {
					key: '237_2818',
					data: {
						name: 'Luzern',
						extent: [7.82, 46.78, 8.51, 47.28],
					},
				},
				'237_2819': {
					key: '237_2819',
					data: {
						name: 'Neuchatel',
						extent: [6.43, 46.84, 7.08, 47.17],
					},
				},
				'237_2820': {
					key: '237_2820',
					data: {
						name: 'Nidwalden',
						extent: [8.21, 46.76, 8.57, 47.02],
					},
				},
				'237_2821': {
					key: '237_2821',
					data: {
						name: 'Obwalden',
						extent: [8.04, 46.75, 8.49, 46.97],
					},
				},
				'237_2822': {
					key: '237_2822',
					data: {
						name: 'Sankt Gallen',
						extent: [8.77, 46.89, 9.67, 47.57],
					},
				},
				'237_2823': {
					key: '237_2823',
					data: {
						name: 'Schaffhausen',
						extent: [8.41, 47.56, 8.89, 47.81],
					},
				},
				'237_2824': {
					key: '237_2824',
					data: {
						name: 'Schwyz',
						extent: [8.36, 46.88, 9.01, 47.23],
					},
				},
				'237_2825': {
					key: '237_2825',
					data: {
						name: 'Solothurn',
						extent: [7.33, 47.07, 8.02, 47.5],
					},
				},
				'237_2826': {
					key: '237_2826',
					data: {
						name: 'Thurgau',
						extent: [8.68, 47.38, 9.48, 47.7],
					},
				},
				'237_2827': {
					key: '237_2827',
					data: {
						name: 'Ticino',
						extent: [8.36, 45.83, 9.15, 46.63],
					},
				},
				'237_2828': {
					key: '237_2828',
					data: {
						name: 'Uri',
						extent: [8.39, 46.52, 8.95, 46.98],
					},
				},
				'237_2829': {
					key: '237_2829',
					data: {
						name: 'Valais',
						extent: [6.76, 45.87, 8.45, 46.65],
					},
				},
				'237_2830': {
					key: '237_2830',
					data: {
						name: 'Vaud',
						extent: [6.06, 46.19, 7.25, 47.0],
					},
				},
				'237_2831': {
					key: '237_2831',
					data: {
						name: 'Zug',
						extent: [8.38, 47.09, 8.68, 47.22],
					},
				},
				'237_2832': {
					key: '237_2832',
					data: {
						name: 'Zurich',
						extent: [8.35, 47.15, 8.97, 47.7],
					},
				},
			},
		},
	},
	249: {
		key: 249,
		data: {
			name: 'Turkey',
			extent: [25.67, 35.82, 44.83, 42.11],
			regionsByFeatureKey: {
				'249_3017': {
					key: '249_3017',
					data: {
						name: 'Adana',
						extent: [34.78, 36.54, 36.43, 38.39],
					},
				},
				'249_3018': {
					key: '249_3018',
					data: {
						name: 'Adiyaman',
						extent: [37.42, 37.41, 39.25, 38.21],
					},
				},
				'249_3019': {
					key: '249_3019',
					data: {
						name: 'Afyon',
						extent: [29.67, 37.77, 31.72, 39.29],
					},
				},
				'249_3020': {
					key: '249_3020',
					data: {
						name: 'Agri',
						extent: [42.24, 38.97, 44.62, 40.03],
					},
				},
				'249_3021': {
					key: '249_3021',
					data: {
						name: 'Aksaray',
						extent: [33.18, 37.97, 34.45, 39.05],
					},
				},
				'249_3022': {
					key: '249_3022',
					data: {
						name: 'Amasya',
						extent: [35.0, 40.23, 36.49, 41.09],
					},
				},
				'249_3023': {
					key: '249_3023',
					data: {
						name: 'Ankara',
						extent: [30.85, 38.67, 33.89, 40.69],
					},
				},
				'249_3024': {
					key: '249_3024',
					data: {
						name: 'Antalya',
						extent: [29.26, 36.09, 32.61, 37.45],
					},
				},
				'249_3025': {
					key: '249_3025',
					data: {
						name: 'Ardahan',
						extent: [42.33, 40.63, 43.48, 41.6],
					},
				},
				'249_3026': {
					key: '249_3026',
					data: {
						name: 'Artvin',
						extent: [41.16, 40.56, 42.59, 41.53],
					},
				},
				'249_3027': {
					key: '249_3027',
					data: {
						name: 'Aydin',
						extent: [27.01, 37.34, 28.86, 38.13],
					},
				},
				'249_3028': {
					key: '249_3028',
					data: {
						name: 'Balikesir',
						extent: [26.58, 39.05, 29.0, 40.68],
					},
				},
				'249_3029': {
					key: '249_3029',
					data: {
						name: 'Bartin',
						extent: [32.05, 41.29, 32.9, 41.86],
					},
				},
				'249_3030': {
					key: '249_3030',
					data: {
						name: 'Batman',
						extent: [40.99, 37.5, 41.73, 38.57],
					},
				},
				'249_3031': {
					key: '249_3031',
					data: {
						name: 'Bayburt',
						extent: [39.66, 39.92, 40.75, 40.57],
					},
				},
				'249_3032': {
					key: '249_3032',
					data: {
						name: 'Bilecik',
						extent: [29.71, 39.65, 30.66, 40.52],
					},
				},
				'249_3033': {
					key: '249_3033',
					data: {
						name: 'Bingol',
						extent: [39.91, 38.46, 41.31, 39.54],
					},
				},
				'249_3034': {
					key: '249_3034',
					data: {
						name: 'Bitlis',
						extent: [41.52, 37.98, 43.23, 39.02],
					},
				},
				'249_3035': {
					key: '249_3035',
					data: {
						name: 'Bolu',
						extent: [30.51, 40.11, 32.61, 41.12],
					},
				},
				'249_3036': {
					key: '249_3036',
					data: {
						name: 'Burdur',
						extent: [29.38, 36.87, 30.9, 37.83],
					},
				},
				'249_3037': {
					key: '249_3037',
					data: {
						name: 'Bursa',
						extent: [28.1, 39.6, 29.97, 40.62],
					},
				},
				'249_3038': {
					key: '249_3038',
					data: {
						name: 'Canakkale',
						extent: [25.67, 39.46, 27.53, 40.74],
					},
				},
				'249_3039': {
					key: '249_3039',
					data: {
						name: 'Cankiri',
						extent: [32.57, 40.27, 34.16, 41.08],
					},
				},
				'249_3040': {
					key: '249_3040',
					data: {
						name: 'Corum',
						extent: [33.9, 39.91, 35.52, 41.33],
					},
				},
				'249_3041': {
					key: '249_3041',
					data: {
						name: 'Denizli',
						extent: [28.58, 36.86, 30.07, 38.49],
					},
				},
				'249_3042': {
					key: '249_3042',
					data: {
						name: 'Diyarbakir',
						extent: [39.09, 37.5, 41.39, 38.72],
					},
				},
				'249_3043': {
					key: '249_3043',
					data: {
						name: 'Edirne',
						extent: [26.04, 40.6, 26.99, 42.0],
					},
				},
				'249_3044': {
					key: '249_3044',
					data: {
						name: 'Elazig',
						extent: [38.36, 38.3, 40.34, 39.18],
					},
				},
				'249_3045': {
					key: '249_3045',
					data: {
						name: 'Erzincan',
						extent: [38.26, 39.02, 40.77, 40.08],
					},
				},
				'249_3046': {
					key: '249_3046',
					data: {
						name: 'Erzurum',
						extent: [40.25, 39.15, 42.59, 40.95],
					},
				},
				'249_3047': {
					key: '249_3047',
					data: {
						name: 'Eskisehir',
						extent: [30.0, 39.08, 32.04, 40.15],
					},
				},
				'249_3048': {
					key: '249_3048',
					data: {
						name: 'Gaziantep',
						extent: [36.47, 36.7, 38.04, 37.53],
					},
				},
				'249_3049': {
					key: '249_3049',
					data: {
						name: 'Giresun',
						extent: [37.95, 40.03, 39.18, 41.08],
					},
				},
				'249_3050': {
					key: '249_3050',
					data: {
						name: 'Gumushane',
						extent: [38.78, 39.8, 40.15, 40.83],
					},
				},
				'249_3051': {
					key: '249_3051',
					data: {
						name: 'Hakkari',
						extent: [43.31, 36.96, 44.83, 37.81],
					},
				},
				'249_3052': {
					key: '249_3052',
					data: {
						name: 'Hatay',
						extent: [35.79, 35.82, 36.71, 37.01],
					},
				},
				'249_3053': {
					key: '249_3053',
					data: {
						name: 'Icel',
						extent: [32.56, 36.02, 35.17, 37.44],
					},
				},
				'249_3054': {
					key: '249_3054',
					data: {
						name: 'Igdir',
						extent: [43.28, 39.63, 44.82, 40.18],
					},
				},
				'249_3055': {
					key: '249_3055',
					data: {
						name: 'Isparta',
						extent: [30.04, 37.3, 31.58, 38.52],
					},
				},
				'249_3056': {
					key: '249_3056',
					data: {
						name: 'Istanbul',
						extent: [28.01, 40.81, 29.93, 41.53],
					},
				},
				'249_3057': {
					key: '249_3057',
					data: {
						name: 'Izmir',
						extent: [26.23, 37.86, 28.48, 39.41],
					},
				},
				'249_3058': {
					key: '249_3058',
					data: {
						name: 'K.maras',
						extent: [36.17, 37.17, 37.75, 38.58],
					},
				},
				'249_3059': {
					key: '249_3059',
					data: {
						name: 'Karabuk',
						extent: [32.11, 40.82, 33.15, 41.59],
					},
				},
				'249_3060': {
					key: '249_3060',
					data: {
						name: 'Karaman',
						extent: [32.43, 36.37, 34.25, 37.62],
					},
				},
				'249_3061': {
					key: '249_3061',
					data: {
						name: 'Kars',
						extent: [42.18, 39.92, 43.75, 41.02],
					},
				},
				'249_3062': {
					key: '249_3062',
					data: {
						name: 'Kastamonu',
						extent: [32.73, 40.83, 34.61, 42.03],
					},
				},
				'249_3063': {
					key: '249_3063',
					data: {
						name: 'Kayseri',
						extent: [34.89, 37.74, 36.99, 39.3],
					},
				},
				'249_3064': {
					key: '249_3064',
					data: {
						name: 'Kilis',
						extent: [36.73, 36.62, 37.57, 37.03],
					},
				},
				'249_3065': {
					key: '249_3065',
					data: {
						name: 'Kirikkale',
						extent: [33.24, 39.39, 34.16, 40.35],
					},
				},
				'249_3066': {
					key: '249_3066',
					data: {
						name: 'Kirklareli',
						extent: [26.88, 41.22, 28.24, 42.11],
					},
				},
				'249_3067': {
					key: '249_3067',
					data: {
						name: 'Kirsehir',
						extent: [33.41, 38.8, 34.7, 39.8],
					},
				},
				'249_3068': {
					key: '249_3068',
					data: {
						name: 'Kocaeli',
						extent: [29.34, 40.53, 30.37, 41.22],
					},
				},
				'249_3069': {
					key: '249_3069',
					data: {
						name: 'Konya',
						extent: [31.24, 36.76, 34.45, 39.27],
					},
				},
				'249_3070': {
					key: '249_3070',
					data: {
						name: 'Kutahya',
						extent: [28.65, 38.77, 30.43, 39.87],
					},
				},
				'249_3071': {
					key: '249_3071',
					data: {
						name: 'Malatya',
						extent: [37.24, 37.9, 39.14, 39.14],
					},
				},
				'249_3072': {
					key: '249_3072',
					data: {
						name: 'Manisa',
						extent: [27.13, 38.07, 29.09, 39.41],
					},
				},
				'249_3073': {
					key: '249_3073',
					data: {
						name: 'Mardin',
						extent: [39.92, 36.89, 41.9, 37.74],
					},
				},
				'249_3074': {
					key: '249_3074',
					data: {
						name: 'Mugla',
						extent: [27.22, 36.31, 29.79, 37.56],
					},
				},
				'249_3075': {
					key: '249_3075',
					data: {
						name: 'Mus',
						extent: [41.11, 38.48, 42.77, 39.5],
					},
				},
				'249_3076': {
					key: '249_3076',
					data: {
						name: 'Nevsehir',
						extent: [34.18, 38.32, 35.08, 39.42],
					},
				},
				'249_3077': {
					key: '249_3077',
					data: {
						name: 'Nigde',
						extent: [34.07, 37.32, 35.32, 38.4],
					},
				},
				'249_3078': {
					key: '249_3078',
					data: {
						name: 'Ordu',
						extent: [36.69, 40.31, 38.19, 41.15],
					},
				},
				'249_3079': {
					key: '249_3079',
					data: {
						name: 'Osmaniye',
						extent: [35.88, 36.91, 36.7, 37.72],
					},
				},
				'249_3080': {
					key: '249_3080',
					data: {
						name: 'Rize',
						extent: [40.33, 40.51, 41.44, 41.34],
					},
				},
				'249_3081': {
					key: '249_3081',
					data: {
						name: 'Sakarya',
						extent: [29.93, 40.31, 31.0, 41.19],
					},
				},
				'249_3082': {
					key: '249_3082',
					data: {
						name: 'Samsun',
						extent: [34.85, 40.83, 37.17, 41.74],
					},
				},
				'249_3083': {
					key: '249_3083',
					data: {
						name: 'Sanliurfa',
						extent: [37.83, 36.66, 40.2, 38.03],
					},
				},
				'249_3084': {
					key: '249_3084',
					data: {
						name: 'Siirt',
						extent: [41.38, 37.57, 42.9, 38.25],
					},
				},
				'249_3085': {
					key: '249_3085',
					data: {
						name: 'Sinop',
						extent: [34.22, 41.21, 35.47, 42.1],
					},
				},
				'249_3086': {
					key: '249_3086',
					data: {
						name: 'Sirnak',
						extent: [41.56, 37.1, 43.5, 37.78],
					},
				},
				'249_3087': {
					key: '249_3087',
					data: {
						name: 'Sivas',
						extent: [35.85, 38.54, 38.77, 40.5],
					},
				},
				'249_3088': {
					key: '249_3088',
					data: {
						name: 'Tekirdag',
						extent: [26.67, 40.56, 28.2, 41.55],
					},
				},
				'249_3089': {
					key: '249_3089',
					data: {
						name: 'Tokat',
						extent: [35.45, 39.94, 37.64, 40.91],
					},
				},
				'249_3090': {
					key: '249_3090',
					data: {
						name: 'Trabzon',
						extent: [39.13, 40.52, 40.49, 41.11],
					},
				},
				'249_3091': {
					key: '249_3091',
					data: {
						name: 'Tunceli',
						extent: [38.73, 38.77, 40.43, 39.6],
					},
				},
				'249_3092': {
					key: '249_3092',
					data: {
						name: 'Usak',
						extent: [28.78, 38.24, 30.04, 38.95],
					},
				},
				'249_3093': {
					key: '249_3093',
					data: {
						name: 'Van',
						extent: [42.65, 37.72, 44.51, 39.4],
					},
				},
				'249_3094': {
					key: '249_3094',
					data: {
						name: 'Yalova',
						extent: [28.78, 40.46, 29.59, 40.75],
					},
				},
				'249_3095': {
					key: '249_3095',
					data: {
						name: 'Yozgat',
						extent: [34.04, 38.96, 36.16, 40.25],
					},
				},
				'249_3096': {
					key: '249_3096',
					data: {
						name: 'Zonguldak',
						extent: [31.3, 41.01, 32.33, 41.58],
					},
				},
			},
		},
	},
	254: {
		key: 254,
		data: {
			name: 'Ukraine',
			extent: [22.13, 44.39, 40.2, 52.38],
			regionsByFeatureKey: {
				'254_3148': {
					key: '254_3148',
					data: {
						name: "Cherkas'ka",
						extent: [29.59, 48.45, 32.86, 50.25],
					},
				},
				'254_3149': {
					key: '254_3149',
					data: {
						name: "Chernihivs'ka",
						extent: [30.51, 50.34, 33.49, 52.38],
					},
				},
				'254_3150': {
					key: '254_3150',
					data: {
						name: "Chernivets'ka",
						extent: [24.93, 47.73, 27.55, 48.68],
					},
				},
				'254_3151': {
					key: '254_3151',
					data: {
						name: "Dnipropetrovs'ka",
						extent: [32.98, 47.47, 36.93, 49.18],
					},
				},
				'254_3152': {
					key: '254_3152',
					data: {
						name: "Donets'ka",
						extent: [36.53, 46.86, 39.06, 49.23],
					},
				},
				'254_3153': {
					key: '254_3153',
					data: {
						name: "Ivano-frankivs'ka",
						extent: [23.53, 47.72, 25.65, 49.52],
					},
				},
				'254_3154': {
					key: '254_3154',
					data: {
						name: "Kharkivs'ka",
						extent: [34.84, 48.52, 38.08, 50.46],
					},
				},
				'254_3155': {
					key: '254_3155',
					data: {
						name: "Khersons'ka",
						extent: [31.51, 45.74, 35.28, 47.58],
					},
				},
				'254_3156': {
					key: '254_3156',
					data: {
						name: "Khmel'nyts'ka",
						extent: [26.15, 48.45, 27.89, 50.59],
					},
				},
				'254_3157': {
					key: '254_3157',
					data: {
						name: "Kirovohrads'ka",
						extent: [29.74, 47.75, 33.92, 49.26],
					},
				},
				'254_3158': {
					key: '254_3158',
					data: {
						name: 'Krym',
						extent: [32.48, 44.39, 36.65, 46.24],
					},
				},
				'254_3159': {
					key: '254_3159',
					data: {
						name: "Kyyivs'ka",
						extent: [29.27, 49.17, 32.14, 51.52],
					},
				},
				'254_3160': {
					key: '254_3160',
					data: {
						name: "L'vivs'ka",
						extent: [22.67, 48.72, 25.43, 50.64],
					},
				},
				'254_3161': {
					key: '254_3161',
					data: {
						name: "Luhans'ka",
						extent: [37.85, 47.83, 40.2, 50.09],
					},
				},
				'254_3162': {
					key: '254_3162',
					data: {
						name: "Mykolayivs'ka",
						extent: [30.2, 46.44, 33.14, 48.22],
					},
				},
				'254_3163': {
					key: '254_3163',
					data: {
						name: "Odes'ka",
						extent: [28.22, 45.22, 31.31, 48.22],
					},
				},
				'254_3164': {
					key: '254_3164',
					data: {
						name: "Poltavs'ka",
						extent: [32.07, 48.74, 35.48, 50.55],
					},
				},
				'254_3165': {
					key: '254_3165',
					data: {
						name: "Rivnens'ka",
						extent: [25.06, 50.01, 27.74, 51.95],
					},
				},
				'254_3166': {
					key: '254_3166',
					data: {
						name: "Sums'ka",
						extent: [32.94, 50.1, 35.71, 52.36],
					},
				},
				'254_3167': {
					key: '254_3167',
					data: {
						name: "Ternopil's'ka",
						extent: [24.71, 48.5, 26.39, 50.27],
					},
				},
				'254_3168': {
					key: '254_3168',
					data: {
						name: "Vinnyts'ka",
						extent: [27.37, 48.01, 30.08, 49.88],
					},
				},
				'254_3169': {
					key: '254_3169',
					data: {
						name: "Volyns'ka",
						extent: [23.63, 50.29, 26.1, 51.98],
					},
				},
				'254_3170': {
					key: '254_3170',
					data: {
						name: "Zakarpats'ka",
						extent: [22.13, 47.91, 24.64, 49.1],
					},
				},
				'254_3171': {
					key: '254_3171',
					data: {
						name: "Zaporiz'ka",
						extent: [34.2, 46.25, 37.22, 48.13],
					},
				},
				'254_3172': {
					key: '254_3172',
					data: {
						name: "Zhytomyrs'ka",
						extent: [27.19, 49.58, 29.74, 51.69],
					},
				},
			},
		},
	},
	256: {
		key: 256,
		data: {
			name: 'United Kingdom',
			extent: [-8.65, 49.89, 1.76, 60.85],
			regionsByFeatureKey: {
				'256_3182': {
					key: '256_3182',
					data: {
						name: 'England',
						extent: [-6.37, 49.89, 1.76, 55.8],
					},
				},
				'256_3183': {
					key: '256_3183',
					data: {
						name: 'Northern Ireland',
						extent: [-8.18, 54.02, -5.43, 55.31],
					},
				},
				'256_3184': {
					key: '256_3184',
					data: {
						name: 'Scotland',
						extent: [-8.65, 54.63, -0.76, 60.85],
					},
				},
				'256_3186': {
					key: '256_3186',
					data: {
						name: 'Wales',
						extent: [-5.36, 51.38, -2.65, 53.43],
					},
				},
			},
		},
	},
};

export default countriesByFeatureKey;
