import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';
import './style.scss';
import {leftMapKey, selectionColors} from '../../../../constants/app';
import {useState} from 'react';

const Label = ({
	className,
	icon,
	rightIcon,
	onClick,
	legendStripe,
	title,
	subtitle,
	selectionMark,
	mapKey,
	children,
}) => {
	const [isOpen, setOpen] = useState(false);
	const topClasses = classnames('visatSdg-Label-top', {}, className);
	const style = {};
	if (selectionMark) {
		style.borderLeft = `.2rem solid ${
			mapKey === leftMapKey ? selectionColors[0] : selectionColors[1]
		}`;
		style.paddingLeft = '.3rem';
	}

	const bodyClasses = classnames('visatSdg-Label-body', {
		'is-open': isOpen,
	});

	const onLabelClick = onClick ? onClick : () => setOpen(!isOpen);

	return (
		<div className="visatSdg-Label">
			<div
				className={topClasses}
				tabIndex={0}
				onClick={onLabelClick}
				style={style}
			>
				{icon ? <Icon icon={icon} /> : null}
				<div className="visatSdg-Label-header">
					<div className="visatSdg-Label-title">{title}</div>
					<div className="visatSdg-Label-subtitle">{subtitle}</div>
				</div>
				{legendStripe}
				{rightIcon ? (
					<Icon className="visatSdg-Label-rightIcon" icon={rightIcon} />
				) : null}
			</div>
			{children ? <div className={bodyClasses}>{children}</div> : null}
		</div>
	);
};

Label.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	icon: PropTypes.string,
	rightIcon: PropTypes.string,
	legendStripe: PropTypes.node,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	selectionMark: PropTypes.bool,
	mapKey: PropTypes.string,
	children: PropTypes.node,
};

export default Label;
