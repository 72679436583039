// import ActionTypes from '../../../constants/ActionTypes';

import ActionTypes from '../../../constants/ActionTypes';

const INITIAL_STATE = {
	byFeatureKey: {},
};

const add = (state, dataset, featureKey, data) => {
	if (dataset && featureKey && data) {
		return {
			...state,
			byFeatureKey: {
				...state.byFeatureKey,
				[featureKey]: state.byFeatureKey[featureKey]
					? {
							...state.byFeatureKey[featureKey],
							[dataset]: data,
					  }
					: {
							[dataset]: data,
					  },
			},
		};
	} else {
		return state;
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.SDG.STATISTICS.ADD:
			return add(state, action.dataset, action.featureKey, action.data);
		default:
			return state;
	}
};
