import PropTypes from 'prop-types';
import {createElement, useState} from 'react';
import './style.scss';
import ModalWindow from '../atoms/ModalWindow';
import FeatureSelectForm from './FeatureSelectForm';

const FeatureSelector = ({Component, ...props}) => {
	const [isWindowOpen, setWindowOpen] = useState(false);

	return (
		<>
			{createElement(Component, {
				...props,
				onMenuOpen: () => setWindowOpen(true),
			})}
			<ModalWindow isOpen={isWindowOpen} onClose={() => setWindowOpen(false)}>
				<FeatureSelectForm
					{...props}
					closeWindow={() => setWindowOpen(false)}
				/>
			</ModalWindow>
		</>
	);
};

FeatureSelector.propTypes = {
	Component: PropTypes.func,
};

export default FeatureSelector;
