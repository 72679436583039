import PropTypes from 'prop-types';
import AttributeSet from './AttributeSet';
import './style.scss';

const AttributeSets = ({
	sets,
	activeKey,
	dataSourceKeys,
	featureKey,
	datasetKey,
}) => {
	return (
		<div className="visatSdg-AttributeSets">
			{sets?.map(setKey => (
				<AttributeSet
					key={setKey}
					active={setKey === activeKey}
					attributeSetKey={setKey}
					dataSourceKeys={dataSourceKeys}
					featureKey={featureKey}
					datasetKey={datasetKey}
				/>
			))}
		</div>
	);
};

AttributeSets.propTypes = {
	activeKey: PropTypes.string,
	sets: PropTypes.array,
	dataSourceKeys: PropTypes.object,
	featureKey: PropTypes.string,
	datasetKey: PropTypes.string,
};

export default AttributeSets;
