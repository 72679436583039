import {leftMapKey, rightMapKey} from '../../constants/app';

const maps = [
	{
		key: leftMapKey,
		data: {
			layers: [],
		},
	},
	{
		key: rightMapKey,
		data: {
			layers: [],
		},
	},
];

const mapSet = {
	key: 'mapSet',
	data: {
		backgroundLayer: {
			key: 'cartoDB_DarkMatter',
			thumbnail: 'cartoDB_DarkMatter',
			name: 'Dark',
			type: 'wmts',
			options: {
				url: 'https://{s}.basemaps.cartocdn.com/rastertiles/dark_nolabels/{z}/{x}/{y}{r}.png',
			},
		},
		// view: {
		// 	boxRange: 200000,
		// 	center: {lat: 50.5, lon: 15.5},
		// },
		viewLimits: {
			boxRange: [5000, 1500000],
		},
	},
	maps: [leftMapKey, rightMapKey],
	sync: {
		roll: true,
		tilt: true,
		range: true,
		center: true,
		heading: true,
		boxRange: true,
	},
	activeMapKey: leftMapKey,
};

export default {
	maps,
	mapSet,
};
