import {commonActionTypes} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';

const specificActionTypes = utils.deepKeyMirror({
	SDG: {
		STATISTICS: {
			ADD: null,
		},
		LAYERS: {
			SET_LAYER_PROPERTY: null,
		},
	},
});

export default {
	...commonActionTypes,
	...specificActionTypes,
};
