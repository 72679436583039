import PropTypes from 'prop-types';
import Header, {HeaderNav, HeaderTools, Logo} from '../atoms/Header';
import Share from './Share';
import AppSelect from './AppSelect';
import CaseSelect from './CaseSelect';
import DatasetSelect from './DatasetSelect';
import FeatureSelector from '../FeatureSelector';
import FeatureHeaderSelect from './FeatureHeaderSelect';
import {leftMapKey} from '../../constants/app';
import './style.scss';

const AppHeader = ({isDatasetCompareActive, url}) => {
	return (
		<Header>
			<HeaderNav>
				<Logo url={url} />
				<AppSelect />
				<CaseSelect />
				{isDatasetCompareActive ? (
					<FeatureSelector
						Component={FeatureHeaderSelect}
						mapKey={leftMapKey}
					/>
				) : (
					<DatasetSelect />
				)}
			</HeaderNav>
			<HeaderTools>
				<Share />
			</HeaderTools>
		</Header>
	);
};

AppHeader.propTypes = {
	isDatasetCompareActive: PropTypes.bool,
	url: PropTypes.string,
};

export default AppHeader;
