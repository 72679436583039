import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		layerTemplate: Select.sdg.getActiveLayerTemplate(state, ownProps.mapKey),
		style: Select.sdg.getStyleForActiveLayerTemplate(state, ownProps.mapKey),
		isDatasetCompareActive: Select.sdg.isDatasetCompareActive(state),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
