import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import {leftMapKey, rightMapKey} from '../../../constants/app';

const mapStateToProps = (state, ownProps) => {
	const leftMapFeatureKey = Select.sdg.getActiveFeatureKey(state, leftMapKey);
	const rightMapFeatureKey = Select.sdg.getActiveFeatureKey(state, rightMapKey);

	return {
		progressChartData: Select.sdg.getProgressChartData(
			state,
			ownProps.indicatorKey,
			ownProps.chartKey,
			leftMapFeatureKey,
			rightMapFeatureKey
		),
		summaryChartData: Select.sdg.getSummaryChartData(
			state,
			ownProps.indicatorKey,
			ownProps.chartKey,
			leftMapFeatureKey,
			rightMapFeatureKey
		),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
