import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import Checkbox from '../../atoms/Checkbox';
import NivoChartHoverWrapper from '../../atoms/NivoChartHover/NivoChartHoverWrapper';
import TimeRangeChart from '../../atoms/TimeRangeChart';
import LineChart from '../../atoms/LineChart';
import moment from 'moment';

import './style.scss';

const theme = {
	axis: {
		domain: {
			line: {
				stroke: 'var(--base30)',
				strokeWidth: 2,
			},
		},
		ticks: {
			line: {
				stroke: 'var(--base30)',
			},
			text: {
				fill: 'var(--base50)',
				fontSize: 11,
			},
		},
		legend: {
			text: {
				fontWeight: 'bold',
				fill: 'var(--base50)',
			},
		},
	},
	grid: {
		line: {
			stroke: 'var(--base25)',
		},
	},
	crosshair: {
		line: {
			stroke: 'var(--base50)',
		},
	},
};

const overviewChartMetadata = {
	settings: {
		theme,
		colors: ({color}) => color,
		margin: {top: 5, right: 0, bottom: 5, left: 0},
		xScale: {
			type: 'time',
			format: '%Y-%m-%dT%H:%M:%S%Z',
		},
		// xFormat: 'time:%Y-%m-%dT%H:%M:%S%Z',
		yScale: {
			type: 'linear',
			min: 'auto',
		},
		axisTop: null,
		axisRight: null,
		axisLeft: null,
		axisBottom: null,
		enableGridX: false,
		enableGridY: false,
		pointSize: 0,
		useMesh: false,
		animate: false,
	},
};

export const Node = ({point, hovered}) => {
	const samePointAsHovered = point.datum.pointId
		? point.datum.pointId === hovered?.[0]?.point?.data?.pointId
		: true &&
		  point.datum.xFormatted === hovered?.[0]?.point?.data?.xFormatted &&
		  point.datum.yFormatted === hovered?.[0]?.point?.data?.yFormatted;

	return (
		<circle
			r={samePointAsHovered ? '4' : '0'} // it should depend on points count in chart/density
			fill={`${samePointAsHovered ? '#eeeeee' : point.color}`}
			stroke={point.color}
			strokeWidth="2"
			style={{pointerEvents: 'none'}}
		></circle>
	);
};

Node.propTypes = {
	point: PropTypes.object,
	hovered: PropTypes.array,
};

const getTooltipContent = (chartType, data) => {
	if (data?.points.length > 0) {
		return (
			<div className="visatSdg-nivo-tooltip">
				<div className="visatSdg-nivo-tooltip-content">
					<h4>{moment(data.points[0]?.data?.x).format('YYYY')}</h4>

					{data?.points.map(point => {
						return (
							<div
								className="visatSdg-nivo-tooltip-item-alternative"
								key={point?.serieId}
							>
								<span
									className="visatSdg-nivo-tooltip-color-alternative"
									style={{
										background: point?.color,
									}}
								/>
								<span className="visatSdg-nivo-tooltip-label">
									{point?.serieId.replace(`${point?.color}_`, '')}:{' '}
								</span>
								<span className="visatSdg-nivo-tooltip-value">
									{point?.data?.y?.toFixed(2)}
								</span>
								<span className="visatSdg-nivo-tooltip-unit">{`${
									chartType === 'rel' ? '%' : 'sqkm'
								}`}</span>
							</div>
						);
					})}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

const ProgressChart = ({
	chartType,
	onChartTypeChange,
	showParentAreas,
	onShowParentAreasChange,
	data,
	activeCountries,
}) => {
	const [dateRange, setDateRange] = useState(null);
	useEffect(() => {
		onChartTypeChange('rel');
	}, []);

	const activeCountryNames = activeCountries.map(item => {
		return item?.data?.name;
	});

	const metadata = {
		extendLines: true,
		settings: {
			theme,
			colors: ({color}) => color,
			defs: [
				{
					colors: [
						{
							color: 'transparent',
							offset: 0, // required by default
						},
					],
					id: 'transparent',
					type: 'linearGradient', // required by default
				},
			],
			fill: [
				{
					match: d =>
						d.id !== activeCountryNames.find(country => country === d.id) &&
						d.id !== 'GHSL dataset (' + activeCountryNames[0] + ')' &&
						d.id !== 'WSF Evolution (' + activeCountryNames[0] + ')',
					id: 'transparent',
				},
			],
			enableArea: true,
			areaOpacity: 0.4,
			areaBaselineValue: 0,
			margin: {
				top: 10,
				right: 10,
				bottom: 35,
				left: 45,
			},
			xScale: {
				type: 'time',
				format: '%Y-%m-%dT%H:%M:%S%Z',
			},
			// xFormat: 'time:%Y-%m-%dT%H:%M:%S%Z',
			yScale: {
				type: 'linear',
				min: 0,
			},
			axisTop: null,
			axisRight: null,
			axisLeft: {
				format: value => value.toLocaleString(),
				tickValues: 6,
			},
			enableGridX: false,
			enableGridY: true,
			gridYValues: 6,
			gridXValues: 6,
			pointSize: 0,
			useMesh: true,
			animate: false,
			motionConfig: 'default',
			markers: [
				{
					axis: 'y',
					value: 0,
					lineStyle: {stroke: 'var(--base30)', strokeWidth: 2},
				},
			],
			legends: [],
			enableSlices: 'x',
		},
	};

	return (
		<div className="visatSdg-ProgressChart">
			<div className="visatSdg-ProgressChart-tools">
				<div data-html2canvas-ignore={true}>
					<Checkbox
						key="TODO"
						onChange={() => onShowParentAreasChange(!showParentAreas)}
						checked={showParentAreas}
						name="Show parent areas"
					/>
				</div>
				<div>
					<ButtonSwitch
						className="visatSdg-ProgressChartTypeSelector"
						small
						onClick={onChartTypeChange}
					>
						<ButtonSwitchOption
							key="abs"
							value="abs"
							active={chartType === 'abs'}
						>
							<>
								<span
									data-html2canvas-ignore={chartType === 'abs' ? null : true}
								>
									Absolute <em>[sqkm]</em>
								</span>
							</>
						</ButtonSwitchOption>
						<ButtonSwitchOption
							key="rel"
							value="rel"
							active={chartType === 'rel'}
						>
							<>
								<span
									data-html2canvas-ignore={chartType === 'rel' ? null : true}
								>
									Share on total unit area <em>[%]</em>
								</span>
							</>
						</ButtonSwitchOption>
					</ButtonSwitch>
				</div>
			</div>
			<div className="visatSdg-ProgressChart-chart">
				{data ? (
					<NivoChartHoverWrapper
						chartType={'activeChartKey'}
						getHoverContent={data => getTooltipContent(chartType, data)}
					>
						<TimeRangeChart
							ChartComponent={LineChart}
							data={data}
							metadata={metadata}
							overviewChartMetadata={overviewChartMetadata}
							onHover={() => {}}
							dateRange={dateRange}
							onDateRangeChange={setDateRange}
						/>
					</NivoChartHoverWrapper>
				) : null}
			</div>
		</div>
	);
};

ProgressChart.propTypes = {
	data: PropTypes.array,
	chartType: PropTypes.string,
	onChartTypeChange: PropTypes.func,
	showParentAreas: PropTypes.bool,
	onShowParentAreasChange: PropTypes.func,
	activeCountries: PropTypes.array,
};

export default ProgressChart;
