// import ActionTypes from '../../../constants/ActionTypes';
import countriesByFeatureKey from './countriesByFeatureKey';

const INITIAL_STATE = {
	countriesByFeatureKey,
};
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default:
			return state;
	}
};
