import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		showAreaLabel: !Select.sdg.isDatasetCompareActive(state),
		activeFeatureKey: Select.sdg.getActiveFeatureKey(state, ownProps.mapKey),
		activeDataset: Select.sdg.getActiveLayerTemplateKey(state, ownProps.mapKey),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onFeatureChange: (featureKey, activeDataset) => {
			dispatch(Action.sdg.statistics.use(featureKey, activeDataset));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
