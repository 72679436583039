import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../state/Select';

const mapStateToProps = state => {
	return {
		indicators: Select.sdg.indicators.getAll(state),
		activeIndicatorKey: Select.sdg.getActiveIndicatorKey(state),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
