// import ActionTypes from '../../../constants/ActionTypes';
import {leftMapKey, rightMapKey} from '../../../constants/app';
import wmsLayers from '../../../data/maps/wmsLayers';
import ActionTypes from '../../../constants/ActionTypes';

const INITIAL_STATE = {
	byMapKey: {
		[leftMapKey]: {
			wsfEvoLayer_left: {
				key: 'wsfEvoLayer_left',
				data: {
					...wmsLayers.wsfEvo,
					layerTemplateKey: 'wsfEvo',
				},
			},
			ghslLayer_left: {
				key: 'ghslLayer_left',
				data: {
					...wmsLayers.ghsl,
					layerTemplateKey: 'ghsl',
				},
			},
			statistics: {
				key: 'statistics',
				data: {
					opacity: 0.7,
					layerTemplateKey: 'statistics',
				},
			},
		},
		[rightMapKey]: {
			wsfEvoLayer_right: {
				key: 'wsfEvoLayer_right',
				data: {
					...wmsLayers.wsfEvo,
					layerTemplateKey: 'wsfEvo',
				},
			},
			ghslLayer_right: {
				key: 'ghslLayer_right',
				data: {
					...wmsLayers.ghsl,
					layerTemplateKey: 'ghsl',
				},
			},
			statistics: {
				key: 'statistics',
				data: {
					opacity: 0.7,
					layerTemplateKey: 'statistics',
				},
			},
		},
	},
};

const setLayerProperty = (state, mapKey, layerKey, property, value) => {
	if (mapKey && layerKey && property) {
		return {
			...state,
			byMapKey: {
				...state.byMapKey,
				[mapKey]: {
					...state.byMapKey[mapKey],
					[layerKey]: {
						...state.byMapKey[mapKey][layerKey],
						data: {
							...state.byMapKey[mapKey][layerKey].data,
							[property]: value,
						},
					},
				},
			},
		};
	} else {
		return state;
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.SDG.LAYERS.SET_LAYER_PROPERTY:
			return setLayerProperty(
				state,
				action.mapKey,
				action.layerKey,
				action.property,
				action.value
			);
		default:
			return state;
	}
};
