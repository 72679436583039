import PropTypes from 'prop-types';
import StatisticLayerTooltip from './StatisticLayerTooltip';

const VectorMapTooltip = ({layer}) => {
	if (
		layer?.layer?.props?.key?.includes('leftMapStatisticLayer') ||
		layer?.layer?.props?.key?.includes('rightMapStatisticLayer')
	) {
		const {ADM0_CODE, ADM1_CODE, ...rest} = layer.object.properties;
		return (
			<StatisticLayerTooltip
				featureKey={ADM1_CODE ? `${ADM0_CODE}_${ADM1_CODE}` : ADM0_CODE}
				{...rest}
			/>
		);
	} else {
		return null;
	}
};

VectorMapTooltip.propTypes = {
	layer: PropTypes.object,
};

export default VectorMapTooltip;
