import PropTypes from 'prop-types';
import Indicator from './Indicator';
import './style.scss';

const Indicators = ({featureKey, mapKey, indicators, activeIndicatorKey}) => {
	return (
		<div className="visatSdg-Indicators">
			{indicators?.map(indicator => (
				<Indicator
					{...indicator.data}
					key={indicator?.key}
					indicatorKey={indicator?.key}
					active={activeIndicatorKey === indicator?.key}
					featureKey={featureKey}
					mapKey={mapKey}
				/>
			))}
		</div>
	);
};

Indicators.propTypes = {
	featureKey: PropTypes.string,
	indicators: PropTypes.array,
	activeIndicatorKey: PropTypes.string,
	mapKey: PropTypes.string,
};

export default Indicators;
