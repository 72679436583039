import PropTypes from 'prop-types';
import HeaderSelect from '../../atoms/HeaderSelect';
import './style.scss';

const options = [
	{
		value: 'sdgApp',
		label: 'SDG 11.3 Explorer',
	},
];

const value = options[0];

const AppSelect = () => {
	return (
		<HeaderSelect
			icon="ri-applications"
			options={options}
			value={value}
			menuPosition="fixed"
			menuWidth="15rem"
			highlighted
			disabledSelection
			onChange={() => {}}
		/>
	);
};

AppSelect.propTypes = {
	cases: PropTypes.array,
	activeCaseKey: PropTypes.string,
	onActiveCaseChange: PropTypes.func,
};

export default AppSelect;
