import createCachedSelector from 're-reselect';

const getCountriesAsObject = state => state.sdg.features.countriesByFeatureKey;

const getActiveCountry = createCachedSelector(
	[getCountriesAsObject, (state, featureKey) => featureKey],
	(countries, featureKey) => {
		if (featureKey) {
			const featureKeyParts = `${featureKey}`.split('_');
			const countryKey = featureKeyParts[0];
			return countries?.[countryKey] || countryKey;
		} else {
			return null;
		}
	}
)((state, featureKey) => featureKey);

const getActiveRegion = createCachedSelector(
	[getCountriesAsObject, (state, featureKey) => featureKey],
	(countries, featureKey) => {
		if (featureKey) {
			const featureKeyParts = `${featureKey}`.split('_');
			const countryKey = featureKeyParts[0];
			const regionKey = featureKeyParts[1] ? featureKey : null;

			if (regionKey) {
				return (
					countries?.[countryKey]?.data?.regionsByFeatureKey?.[regionKey] ||
					featureKey
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
)((state, featureKey) => featureKey);

const getActive = createCachedSelector(
	[getActiveRegion, getActiveCountry],
	(region, country) => {
		if (region) {
			return region;
		} else {
			return country;
		}
	}
)((state, featureKey) => featureKey);

export default {
	getCountriesAsObject,
	getActiveCountry,
	getActiveRegion,
	getActive,
};
