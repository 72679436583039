import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';
import Presentation from './presentation';
import {mapSetMode} from '../../../constants/app';

const mapStateToProps = state => {
	const activeCaseKey = Select.sdg.getActiveCaseKey(state);
	const cases = Select.cases.getAll(state);

	return {
		cases,
		activeCaseKey,
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			onActiveCaseChange: key => {
				dispatch(Action.sdg.switchToCase(key));
				dispatch(Action.sdg.switchToMapMode(mapSetMode));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
