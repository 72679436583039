import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const featureKey = Select.sdg.getActiveFeatureKey(state, ownProps.mapKey); // TODO remove dependency on mapKey

	return {
		featureKey,
		activeCountry: Select.sdg.features.getActiveCountry(state, featureKey),
		activeRegion: Select.sdg.features.getActiveRegion(state, featureKey),
		countries: Select.sdg.features.getCountriesAsObject(state),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
