export default [
	{
		key: 'wsfEvo',
		data: {
			nameDisplay: 'WSF Evolution',
			description: '1985 - 2015',
			styleKey: 'wsfEvolution',
		},
	},
	{
		key: 'ghsl',
		data: {
			nameDisplay: 'GHSL dataset',
			description: '1976 - 2015',
			styleKey: 'ghsl',
		},
	},
];
