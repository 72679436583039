import {config as getConfig} from '@gisatcz/ptr-core';
import {Action as CommonAction} from '@gisatcz/ptr-state';
import {appKey, auSelectionKey} from '../constants/app';
import routerActions from './router/actions';

import sdg from './sdg/actions'; // eslint-disable-line
import sdgSelectors from './sdg/selectors';
import layerTemplates from '../data/metadata/layerTemplates';
import cases from '../data/metadata/cases';

import auSelection from '../data/selections/auSelection';

require('dotenv').config();

const getAppEnvConfig = () => {
	if (process?.env) {
		const apiBackendProtocol = process.env?.REACT_APP_apiBackendProtocol;
		const apiBackendHost = process.env?.REACT_APP_apiBackendHost;
		const apiBackendPath = process.env?.REACT_APP_apiBackendPath;
		const requestPageSize = process.env?.REACT_APP_requestPageSize;

		return {
			...(apiBackendProtocol ? {apiBackendProtocol} : {}),
			...(apiBackendHost ? {apiBackendHost} : {}),
			...(apiBackendPath ? {apiBackendPath} : {}),
			...(requestPageSize ? {requestPageSize} : {}),
		};
	} else {
		return {};
	}
};

function setDefaultUrl() {
	return (dispatch, getState) => {
		const state = getState();

		//get current router settings
		const attributeSetKey = sdgSelectors.getActiveAttributeSetKey(state);
		const caseKey = sdgSelectors.getActiveCaseKey(state);
		const layerTemplateKeys = sdgSelectors.getActiveLayerTemplateKeys(state);
		const featureKeys = sdgSelectors.getActiveFeatureKeys(state);

		// TODO - page from current page? from selector
		const page = 'home';
		dispatch(
			routerActions.updateAppUrl(page, {
				attributeSet: attributeSetKey,
				case: caseKey,
				layerTemplate: layerTemplateKeys,
				feature: featureKeys,
			})
		);
	};
}

function init(path) {
	return dispatch => {
		dispatch(CommonAction.app.setBaseUrl(path));

		const config = getConfig(getAppEnvConfig());
		dispatch(CommonAction.app.updateLocalConfiguration(config));
		dispatch(CommonAction.app.setKey(appKey));

		dispatch(CommonAction.selections.add(auSelection));
		dispatch(CommonAction.selections.setActiveKey(auSelectionKey));

		dispatch(CommonAction.app.loadConfiguration()).then(() => {
			//
			// Continue by settings app secific initialisation like setting active view
			//

			dispatch(CommonAction.layerTemplates.add(layerTemplates));
			dispatch(CommonAction.cases.add(cases));

			// Setup url
			dispatch(setDefaultUrl());

			// const viewKey = Select.app.getConfiguration(getState(), 'initialViewKey');
			// if (viewKey) {
			// 	dispatch(CommonAction.views.useKeys([viewKey])).then(() => {
			// 		dispatch(CommonAction.views.applyAndSetActive(viewKey, CommonAction));
			// 	});
			// }

			//
			// TODO - set url defaults
			//
		});
	};
}

export default {
	...CommonAction,
	init,
	router: {...routerActions},
	sdg: {
		...sdg,
	},
};
