import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../state/Action';
import Select from '../../../state/Select';
import {leftMapKey, rightMapKey} from '../../../constants/app';

const mapStateToProps = (state, ownProps) => {
	const leftMapFeatureKey = Select.sdg.getActiveFeatureKey(state, leftMapKey);
	const rightMapFeatureKey = Select.sdg.getActiveFeatureKey(state, rightMapKey);

	return {
		activeCountries: [
			Select.sdg.features.getActiveCountry(state, leftMapFeatureKey),
			Select.sdg.features.getActiveCountry(state, rightMapFeatureKey),
		],
		chartType: Select.components.get(state, ownProps.chartKey, 'type'),
		data: Select.sdg.getProgressChartData(
			state,
			ownProps.indicatorKey,
			ownProps.chartKey,
			leftMapFeatureKey,
			rightMapFeatureKey
		),
		showParentAreas: Select.components.get(
			state,
			ownProps.chartKey,
			'showParentAreas'
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChartTypeChange: type => {
			dispatch(Action.components.set(ownProps.chartKey, 'type', type));
		},
		onShowParentAreasChange: show => {
			dispatch(
				Action.components.set(ownProps.chartKey, 'showParentAreas', show)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
