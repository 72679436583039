import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import {createElement} from 'react';
import './style.scss';
import scssVar from '../../../styles/_variables.scss';
import {Icon} from '@gisatcz/ptr-atoms';

const CustomValue = ({icon, children}) => {
	return (
		<div className="visatSdg-HeaderSelect-CustomValue">
			{icon ? <Icon icon={icon} /> : null}
			<span>{children}</span>
		</div>
	);
};

CustomValue.propTypes = {
	icon: PropTypes.string,
	children: PropTypes.node,
};

const HeaderSelect = ({
	options,
	value,
	menuPosition,
	menuWidth,
	hideMenu,
	onChange,
	onMenuOpen,
	icon,
	disabledSelection,
}) => {
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: state.isFocused ? 'var(--accent10)' : 'transparent',
			minHeight: '1.5rem',
			padding: '3px',
			marginTop: '1px',
			borderWidth: '0',
			borderRadius: '.25rem',
			boxShadow: '0',
			cursor: 'pointer',
			color: state.isFocused
				? scssVar.textColorAccented
				: scssVar.textColorPrimary,
			'&:hover': {
				color: scssVar.textColorAccented,
			},
		}),
		container: provided => ({
			...provided,
			fontSize: '1rem',
			borderRadius: '0',
		}),
		indicatorSeparator: provided => ({
			...provided,
			display: 'none',
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			padding: '0',
			cursor: 'pointer',
			color: state.isDisabled ? scssVar.iconColorDisabled : 'inherit',
			transition: 0,
			':hover': {
				color: 'inherit',
			},
		}),
		input: provided => ({
			...provided,
			padding: '0',
			margin: 0,
			cursor: 'pointer',
		}),
		singleValue: provided => ({
			...provided,
			fontWeight: '600',
			fontSize: '1rem',
			fontFamily: 'Roboto, sans-serif',
			color: 'inherit',
			cursor: 'pointer',
		}),
		valueContainer: provided => ({
			...provided,
			color: 'inherit',
			padding: '0px 3px',
			cursor: 'pointer',
			display: 'flex',
		}),
		menu: provided => ({
			...provided,
			zIndex: 1000,
			minWidth: menuWidth || '10rem',
			display: hideMenu && 'none',
			border: '1px solid var(--base30)',
		}),
		option: provided => ({
			...provided,
			cursor: 'pointer',
			fontSize: scssVar.a1,
		}),
	};

	return (
		<ReactSelect
			className="visatSdg-HeaderSelect"
			styles={customStyles}
			theme={theme => ({
				...theme,
				colors: {
					...theme.colors,
					primary25: 'var(--accent20)',
					primary50: 'var(--accent30)',
					primary: 'var(--accent50)',
					neutral0: 'var(--base10)',
					neutral20: 'var(--base40)',
					neutral80: 'var(--base80)',
					neutral90: 'var(--base90)',
				},
			})}
			isClearable={false}
			isSearchable={false}
			options={options}
			value={value}
			isDisabled={disabledSelection}
			onChange={onChange}
			onMenuOpen={onMenuOpen || null}
			menuPosition={menuPosition}
			components={{
				SingleValue: props => createElement(CustomValue, {...props, icon}),
			}}
		/>
	);
};

HeaderSelect.propTypes = {
	options: PropTypes.array,
	value: PropTypes.object,
	small: PropTypes.bool,
	menuPosition: PropTypes.string,
	menuWidth: PropTypes.string,
	hideMenu: PropTypes.bool,
	onChange: PropTypes.func,
	onMenuOpen: PropTypes.func,
	icon: PropTypes.string,
	disabledSelection: PropTypes.bool,
};

export default HeaderSelect;
