import PropTypes from 'prop-types';
import Label from '../Label';
import MapLegendStripe from '../../../atoms/MapLegendStripe';
import LayerVisibilityControl from '../../tools/LayerVisibilityControl';
import LayerOpacityControl from '../../tools/LayerOpacityControl';
import './style.scss';
import Legend from '../../tools/Legend';

const ChoroplethLabel = ({attributeSet, indicator, style, mapKey}) => {
	return (
		<Label
			className="visatSdg-ChoroplethLabel"
			icon="ri-bar-chart"
			legendStripe={
				<MapLegendStripe classes={style?.data?.[1]?.attributeClasses} />
			}
			title={attributeSet?.data?.name}
			subtitle={indicator?.data?.name}
			rightIcon="ri-chevron-down"
		>
			<LayerOpacityControl layerTemplateKey="statistics" mapKey={mapKey} />
			<LayerVisibilityControl layerTemplateKey="statistics" mapKey={mapKey} />
			<Legend style={style?.data?.[1]} unit="%" />
		</Label>
	);
};

ChoroplethLabel.propTypes = {
	attributeSet: PropTypes.object,
	indicator: PropTypes.object,
	style: PropTypes.object,
	mapKey: PropTypes.string,
};

export default ChoroplethLabel;
