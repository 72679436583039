import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';
import Presentation from './presentation';
import {leftMapKey} from '../../../constants/app';

const mapStateToProps = state => {
	const activeDatasetKey = Select.sdg.getActiveLayerTemplateKey(state);
	const datasets = Select.layerTemplates.getAll(state);

	return {
		datasets,
		activeDatasetKey,
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			onActiveDatasetChange: key => {
				dispatch(Action.sdg.switchToLayerTemplate([key]), leftMapKey); // TODO remove dependency on map
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
