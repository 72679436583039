import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';
import Attribute from './Attribute';
import AbsoluteAttribute from './Attribute/AbsoluteAttribute';
import RelativeAttribute from './Attribute/RelativeAttribute';

import './style.scss';
import {useState} from 'react';

const AttributeSetYear = ({value}) => {
	return <span>({value})</span>;
};

AttributeSetYear.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const AttributeSet = ({
	model,
	active,
	onAttributeSetSelect,
	dataSourceKeys,
	featureKey,
	datasetKey,
}) => {
	const [absAttrIsLoading, setAbsAttrIsLoading] = useState(true);
	const [relAttrIsLoading, setRelAttrIsLoading] = useState(true);

	const classes = classnames('visatSdg-AttributeSet', {
		'is-active': active,
	});

	if (model) {
		const {name, relAttribute, absAttribute, yearAttribute} = model.data;
		return (
			<div className={classes} onClick={onAttributeSetSelect}>
				<div className="visatSdg-AttributeSet-data">
					<div className="visatSdg-AttributeSet-name">
						{name}{' '}
						{yearAttribute ? (
							<Attribute
								Component={AttributeSetYear}
								dataSourceKey={dataSourceKeys.abs}
								attributeKey={yearAttribute}
								featureKey={featureKey}
								datasetKey={datasetKey}
							/>
						) : null}
					</div>
					<div className="visatSdg-AttributeSet-values">
						{absAttrIsLoading || relAttrIsLoading ? (
							<div className="visatSdg-AttributeSet-loader"></div>
						) : null}
						<Attribute
							Component={AbsoluteAttribute}
							dataSourceKey={dataSourceKeys.abs}
							attributeKey={absAttribute}
							featureKey={featureKey}
							datasetKey={datasetKey}
							setIsLoading={setAbsAttrIsLoading}
							isLoading={absAttrIsLoading || relAttrIsLoading}
						/>
						<Attribute
							Component={RelativeAttribute}
							dataSourceKey={dataSourceKeys.rel}
							attributeKey={relAttribute}
							featureKey={featureKey}
							datasetKey={datasetKey}
							setIsLoading={setRelAttrIsLoading}
							isLoading={absAttrIsLoading || relAttrIsLoading}
						/>
					</div>
				</div>
				<div className="visatSdg-AttributeSet-icon">
					<Icon icon="ri-check" />
				</div>
			</div>
		);
	} else {
		return null;
	}
};

AttributeSet.propTypes = {
	active: PropTypes.bool,
	model: PropTypes.object,
	onAttributeSetSelect: PropTypes.func,
	dataSourceKeys: PropTypes.object,
	featureKey: PropTypes.string,
	datasetKey: PropTypes.string,
};

export default AttributeSet;
