import {MapScale} from '@gisatcz/ptr-maps';
import './style.scss';

const Scale = props => {
	return <MapScale className="ptr-Scale" maxWidth={100} {...props} />;
};

Scale.propTypes = {};

export default Scale;
