import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../state/Select';

const mapStateToProps = state => {
	return {
		isDatasetCompareActive: Select.sdg.isDatasetCompareActive(state),
		url: Select.getLinks()?.visat
			? `${Select.getLinks()?.visat}applications`
			: '',
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
