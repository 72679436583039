import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';
import PanelControlButton from '../atoms/PanelControlButton';
import Charts from '../Charts';

const BottomPanel = ({isOpen, setOpen, isExpanded, setExpand}) => {
	const classes = classnames('visatSdg-BottomPanel', {
		'is-open': isOpen,
		'is-expanded': isExpanded,
	});

	return (
		<div className={classes}>
			<PanelControlButton
				isOpen={!isOpen}
				setOpen={() => setOpen(!isOpen)}
				isExpanded={!isExpanded}
				setExpand={() => setExpand(!isExpanded)}
			/>
			<div className="visatSdg-BottomPanel-contentWrapper">
				<div className="visatSdg-BottomPanel-content">
					<Charts />
				</div>
			</div>
		</div>
	);
};

BottomPanel.propTypes = {
	isOpen: PropTypes.bool,
	setOpen: PropTypes.func,
	isExpanded: PropTypes.bool,
	setExpand: PropTypes.func,
};

export default BottomPanel;
