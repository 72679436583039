import PropTypes from 'prop-types';
import HeaderSelect from '../../atoms/HeaderSelect';
import './style.scss';

const DatasetSelect = ({datasets, activeDatasetKey, onActiveDatasetChange}) => {
	const options = datasets?.map(dataset => {
		return {value: dataset.key, label: dataset.data.nameDisplay};
	});
	const value = options?.find(option => option.value === activeDatasetKey);

	const onActiveOptionChange = appCase => {
		onActiveDatasetChange(appCase.value);
	};

	return (
		<HeaderSelect
			icon="ri-database"
			options={options}
			value={value}
			menuPosition="fixed"
			menuWidth="15rem"
			highlighted
			onChange={onActiveOptionChange}
		/>
	);
};

DatasetSelect.propTypes = {
	datasets: PropTypes.array,
	activeDatasetKey: PropTypes.string,
	onActiveDatasetChange: PropTypes.func,
};

export default DatasetSelect;
