import PropTypes from 'prop-types';
import classnames from 'classnames';
import {IconTool} from '@gisatcz/ptr-atoms';
import './style.scss';

const PanelControlButton = ({isOpen, setOpen, isExpanded, setExpand}) => {
	const handleKeyDown = event => {
		if (event.key === 'Enter') {
			setOpen(!isOpen);
		}
	};

	return (
		<>
			<div
				className={classnames('ptr-PanelControlButton', {
					'is-expanded': !isExpanded && !isOpen,
				})}
				tabIndex={0}
				onKeyDown={handleKeyDown}
				data-tip="Collapse timeline"
				data-for="timeline-collapse"
			>
				<IconTool
					className={classnames('ptr-PanelControlButton-open', {
						'is-collapsed': isOpen,
					})}
					icon="ri-chevron-down"
					small
					onClick={() => {
						setOpen(!isOpen);
					}}
					disabled={!isExpanded ? true : false}
				/>
				<IconTool
					className={classnames('ptr-PanelControlButton-expand', {
						'is-expanded': isExpanded,
					})}
					icon={!isExpanded ? 'ri-fullscreen-exit' : 'ri-fullscreen'}
					small
					onClick={() => {
						setExpand(!isExpanded);
					}}
					disabled={isOpen ? true : false}
				/>
				<span>Charts</span>
			</div>
		</>
	);
};

PanelControlButton.propTypes = {
	isOpen: PropTypes.bool,
	setOpen: PropTypes.func,
	isExpanded: PropTypes.bool,
	setExpand: PropTypes.func,
};

export default PanelControlButton;
