import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
	ReactCompareSlider,
	ReactCompareSliderHandle,
} from 'react-compare-slider';
import {DeckGlMap, PresentationMap} from '@gisatcz/ptr-maps';
import MapWrapper from './MapWrapper';
import {leftMapKey, rightMapKey} from '../../constants/app';
import MapComponentsGroup from './MapComponentsGroup';
import CompareMapsControl from './CompareMapsControl';
import BackgroundLayersControl from './BackgroundLayersControl';
import ZoomControls from './ZoomControls';
import MapAttribution from './MapAttribution';
import Scale from './Scale';
import maps from '../../data/maps/maps';

const MapsCompare = ({
	isBottomPanelOpen,
	backgroundLayer,
	mapSetView,
	onMapSetViewChange,
	rightMapLayers,
	leftMapLayers,
	Tooltip,
	onLayerClick,
}) => {
	const mapSetKey = maps.mapSet.key;
	const mapSetToolsClasses = classnames('visatSdg-MapSetTools', {
		'is-open': isBottomPanelOpen,
	});

	return (
		<ReactCompareSlider
			onlyHandleDraggable
			handle={
				<ReactCompareSliderHandle
					buttonStyle={{position: 'absolute', top: 'calc(40% - 10px)'}}
				/>
			}
			className="visatSdg-CompareSlider"
			itemOne={
				<PresentationMap
					wrapper={MapWrapper}
					wrapperProps={{
						isBottomPanelOpen,
					}}
					mapComponent={DeckGlMap}
					mapKey={leftMapKey}
					backgroundLayer={backgroundLayer}
					layers={leftMapLayers}
					view={mapSetView}
					onViewChange={onMapSetViewChange}
					Tooltip={Tooltip}
					onLayerClick={onLayerClick}
				/>
			}
			itemTwo={
				<PresentationMap
					onLayerClick={onLayerClick}
					wrapper={MapWrapper}
					wrapperProps={{
						isBottomPanelOpen,
					}}
					mapComponent={DeckGlMap}
					mapKey={rightMapKey}
					backgroundLayer={backgroundLayer}
					layers={rightMapLayers}
					view={mapSetView}
					onViewChange={onMapSetViewChange}
					Tooltip={Tooltip}
				>
					<MapComponentsGroup
						className={mapSetToolsClasses}
						key={'MapComponentsGroup_1'}
					>
						<MapComponentsGroup
							className="visatSdg-MapSetControls"
							key={'MapComponentsGroup_2'}
						>
							<CompareMapsControl
								mapSetKey={mapSetKey}
								key={'CompareMapsControl'}
							/>
							<BackgroundLayersControl
								mapSetKey={mapSetKey}
								key={'BackgroundLayersControl'}
							/>
							<ZoomControls key={'ZoomControls'} />
						</MapComponentsGroup>
						<MapComponentsGroup
							className="visatSdg-MapInfoElements"
							key={'MapComponentsGroup_3'}
						>
							<MapComponentsGroup
								className="visatSdg-AttributionScaleContainer"
								key={'MapComponentsGroup_4'}
							>
								<MapAttribution mapSetKey={mapSetKey} key={'MapAttribution'} />
								<Scale key={'Scale'} />
							</MapComponentsGroup>
						</MapComponentsGroup>
					</MapComponentsGroup>
				</PresentationMap>
			}
		/>
	);
};

MapsCompare.propTypes = {
	isBottomPanelOpen: PropTypes.bool,
	backgroundLayer: PropTypes.object,
	mapSetView: PropTypes.object,
	onMapSetViewChange: PropTypes.func,
	leftMapLayers: PropTypes.array,
	rightMapLayers: PropTypes.array,
	Tooltip: PropTypes.func,
	onLayerClick: PropTypes.func,
};

export default MapsCompare;
