import config from '../../../config';
import request from '../../../utils/request';
import ActionTypes from '../../../constants/ActionTypes';
import Select from '../../Select';

const use = (featureKey, activeDataset) => {
	return dispatch => {
		dispatch(ensureDataForFeatureKey(featureKey, activeDataset));
		dispatch(useParent(featureKey, activeDataset));
	};
};

const useParent = (featureKey, activeDataset) => {
	return (dispatch, getState) => {
		const state = getState();
		const region = Select.sdg.features.getActiveRegion(state, featureKey);
		const country = Select.sdg.features.getActiveCountry(state, featureKey);
		if (region) {
			dispatch(ensureDataForFeatureKey(country?.key, activeDataset));
		}
	};
};

const ensureDataForFeatureKey = (featureKey, activeDataset) => {
	return dispatch => {
		let url = `${config.statisticsDataRepository}/${activeDataset}/${featureKey}.json`;

		return request(url, 'GET', null, null)
			.then(data => {
				if (data) {
					dispatch(actionAdd(featureKey, activeDataset, data));
				} else {
					console.warn(`No data for feature key ${featureKey}!`);
				}
			})
			.catch(err => new Error(err));
	};
};

const actionAdd = (featureKey, dataset, data) => {
	return {
		type: ActionTypes.SDG.STATISTICS.ADD,
		dataset,
		featureKey,
		data,
	};
};

export default {
	use,
};
