import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {
	mapCompareMode,
	auSelectionKey,
	selectionColors,
} from '../../constants/app';

import MapTooltip from './MapTooltip';
import MapsCompare from './MapsCompare';
import SyncedMapSet from './SyncedMapSet';
import UnsyncedMaps from './UnsyncedMaps';

import './style.scss';
const Tooltip = props => {
	return <MapTooltip {...props}></MapTooltip>;
};

Tooltip.propTypes = {
	mapKey: PropTypes.string,
	componentsByLayer: PropTypes.array,
};

const MapComponent = ({
	leftMapStatisticLayer,
	rightMapStatisticLayer,
	leftSelectedFeatureKey,
	rightSelectedFeatureKey,
	onLayerClick,
	mapsMode,
	isBottomPanelOpen,
	isDatasetCompareActive,
	backgroundLayer,
	onMount,
	initMapSetView,
	initLeftMapView,
	initRightMapView,
	rightMapRasterLayer,
	leftMapRasterLayer,
}) => {
	const [mapSetView, setMapSetView] = useState(initMapSetView);

	const isDatasetCompareActiveRef = useRef(isDatasetCompareActive);

	useEffect(() => {
		if (!mapSetView && initMapSetView) {
			setMapSetView(initMapSetView);
		}
	}, [initMapSetView]);

	useEffect(() => {
		onMount();
	}, []);

	useEffect(() => {
		isDatasetCompareActiveRef.current = isDatasetCompareActive;
	}, [isDatasetCompareActive]);
	const leftMapLayers = [
		leftMapRasterLayer,
		leftMapStatisticLayer
			? {
					...leftMapStatisticLayer,
					options: {
						...leftMapStatisticLayer.options,
						selected: {
							[auSelectionKey]: {
								keys: [leftSelectedFeatureKey],
								style: {
									outlineWidth: 5,
									outlineColor: selectionColors[0],
								},
							},
						},
					},
			  }
			: {},
	];

	const rightMapLayers = [
		rightMapRasterLayer,
		rightMapStatisticLayer
			? {
					...rightMapStatisticLayer,
					options: {
						...rightMapStatisticLayer.options,
						selected: {
							[auSelectionKey]: {
								keys: [rightSelectedFeatureKey],
								style: {
									outlineWidth: 5,
									outlineColor: selectionColors[1],
								},
							},
						},
					},
			  }
			: null,
	];
	return (
		<>
			{initMapSetView || initLeftMapView || initRightMapView ? (
				<div className="ptr-MapSetContainer">
					{isDatasetCompareActive ? (
						mapsMode === mapCompareMode ? (
							<MapsCompare
								isBottomPanelOpen={isBottomPanelOpen}
								backgroundLayer={backgroundLayer}
								mapSetView={mapSetView}
								onMapSetViewChange={setMapSetView}
								leftMapLayers={leftMapLayers}
								rightMapLayers={rightMapLayers}
								Tooltip={Tooltip}
								getFeatureKeyFromLayerClickEvent={onLayerClick}
							/>
						) : (
							<SyncedMapSet
								isBottomPanelOpen={isBottomPanelOpen}
								backgroundLayer={backgroundLayer}
								mapSetView={mapSetView}
								leftMapLayers={leftMapLayers}
								rightMapLayers={rightMapLayers}
								Tooltip={Tooltip}
								onLayerClick={onLayerClick}
							/>
						)
					) : (
						<UnsyncedMaps
							isBottomPanelOpen={isBottomPanelOpen}
							backgroundLayer={backgroundLayer}
							leftMapLayers={leftMapLayers}
							rightMapLayers={rightMapLayers}
							initLeftMapView={initLeftMapView}
							initRightMapView={initRightMapView}
							Tooltip={Tooltip}
							onLayerClick={onLayerClick}
						/>
					)}
				</div>
			) : null}
		</>
	);
};

MapComponent.propTypes = {
	onLayerClick: PropTypes.func,
	leftMapStatisticLayer: PropTypes.object,
	rightMapStatisticLayer: PropTypes.object,
	leftSelectedFeatureKey: PropTypes.string,
	rightSelectedFeatureKey: PropTypes.string,
	mapsMode: PropTypes.string,
	isBottomPanelOpen: PropTypes.bool,
	isDatasetCompareActive: PropTypes.bool,
	backgroundLayer: PropTypes.object,
	onMount: PropTypes.func,
	initMapSetView: PropTypes.object,
	initLeftMapView: PropTypes.object,
	initRightMapView: PropTypes.object,
	rightMapStyle: PropTypes.object,
	leftMapStyle: PropTypes.object,
	rightMapRasterLayer: PropTypes.object,
	leftMapRasterLayer: PropTypes.object,
};

export default MapComponent;
