import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		datasetKey: Select.sdg.getActiveLayerTemplateKey(state, ownProps.mapKey),
		open: Select.components.get(state, ownProps.indicatorKey, 'open'),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setIndicatorIsOpen: open =>
			dispatch(Action.components.set(ownProps.indicatorKey, 'open', open)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
