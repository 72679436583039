import PropTypes from 'prop-types';
import {Toggle} from '@gisatcz/ptr-atoms';
import LayerLabelTool from '../../../atoms/LayerLabelTool';
import './style.scss';

const LayerVisibilityControl = ({hidden, onChange}) => {
	return (
		<LayerLabelTool
			title="Hide layer"
			key="hide_layer"
			onClick={() => onChange(!hidden)}
		>
			<Toggle notInteractive on={hidden} />
		</LayerLabelTool>
	);
};

LayerVisibilityControl.propTypes = {
	hidden: PropTypes.bool,
	onChange: PropTypes.func,
};

export default LayerVisibilityControl;
