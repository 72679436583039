// App specific actions
import Select from '../Select';

import {getRouter} from '../../router';

//TODO separate to router logic
const updateAppUrl = (name, update) => {
	return (dispatch, getState) => {
		const currentParams = Select.router.getCurrent(getState());

		const newParams = {
			...currentParams?.params?.path,
			...currentParams?.params?.parsedQueryString,
			...update,
		};

		// clear properties params with null or undefined value
		for (const property of Object.keys(newParams)) {
			if (!newParams[property]) {
				delete newParams[property];
			}
		}

		const router = getRouter();
		const url = router.pathFor(name, newParams);
		router.nav(url);
	};
};

export default {
	updateAppUrl,
};
