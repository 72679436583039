import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../state/Action';

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onActiveFeatureChange: featureKey => {
			dispatch(Action.sdg.switchToFeature(featureKey, ownProps.mapKey));
			setTimeout(() => {
				dispatch(Action.sdg.setMapExtentFromSelectedFeatures());
			}, 100);
			//zoom to feature
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
