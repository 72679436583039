import {commonSelectors} from '@gisatcz/ptr-state';

const getSubstate = state => state.sdg.statistics;
const getAllAsObject = state => state.sdg.statistics.byFeatureKey;
const getAll = commonSelectors.getAll(getSubstate);

export default {
	getAllAsObject,
	getAll,
};
