import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useState} from 'react';
import MapLegendContent from './components/MapLegendContent';
import MapLegendAttributeClasses from './components/MapLegendAttributeClasses';
import MapLegendAttributeValues from './components/MapLegendAttributeValues';
import './style.scss';

const MapLegendType = ({isExpanded, attributeStyle, unit}) => {
	if (attributeStyle?.attributeClasses) {
		return (
			<MapLegendAttributeClasses
				isExpanded={isExpanded}
				intervals={attributeStyle?.attributeClasses}
				unit={unit}
			/>
		);
	} else if (attributeStyle?.attributeValues) {
		return (
			<MapLegendAttributeValues
				isExpanded={isExpanded}
				values={attributeStyle?.attributeValues}
			/>
		);
	} else {
		return null;
	}
};

MapLegendType.propTypes = {
	isExpanded: PropTypes.bool,
	attributeStyle: PropTypes.object,
	unit: PropTypes.string,
};

const Legend = ({style, expandable = true, unit}) => {
	const [isExpanded, setExpanded] = useState(false);
	const classes = classNames('visatSdg-Legend', {
		'is-expanded': isExpanded,
		expandable: expandable,
	});

	return (
		<div
			data-tour="map-legend"
			className={classes}
			onClick={() => (expandable ? setExpanded(!isExpanded) : null)}
		>
			<MapLegendContent isExpanded={isExpanded}>
				<MapLegendType
					isExpanded={isExpanded}
					attributeStyle={style}
					unit={unit}
				/>
			</MapLegendContent>
		</div>
	);
};

Legend.propTypes = {
	style: PropTypes.object,
	expandable: PropTypes.bool,
	unit: PropTypes.string,
};

export default Legend;
